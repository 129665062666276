// import npm packge
import React, {  } from 'react';
import { ToastContainer } from 'react-toastify';
// import service api
//
// import core class
//
// import com page
import Access from '../../components/Access';
// import modal tool
//
// import style media
import "./default.css";
import 'react-toastify/dist/ReactToastify.css';
// coding
export class Paccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //
        }
    };
    componentWillMount() {
        //
    };
    componentDidMount() {
        //
    };
    /*etc https://viblo.asia/p/vong-doi-cua-component-trong-react-gGJ59XaJlX2*/
    render() {
        return (
            <section className="p-4 text-center mb-4 w-100">
                <div className="container d-flex flex-column">
                    <Access />
                </div>
                <ToastContainer/>
            </section>
        );
    };
}