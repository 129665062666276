// import npm packge
import React, { useState } from "react";
import { Card, CardHeader, CardBody, CardText, Button, ListGroup, InputGroup, Input, //
        UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Form } from 'reactstrap';
import TextareaAutosize from 'react-autosize-textarea';
import { LightgalleryProvider, LightgalleryItem, ItemTitle, LinesEllipsis } from "react-lightgallery";
import _ from 'lodash';
// import service api
//
// import core class
import Util from '../../core/util';
// import com page
//
// import modal tool
//
// import style media
import { FaRegFaceSmile, FaRegFaceSurprise, FaGoogleDrive, FaDropbox } from "react-icons/fa6";
import { FcBusinessman, FcBusinesswoman, FcLike, FcCheckmark, FcCloseUpMode } from "react-icons/fc";
import { MdOutlineMoreHoriz, MdOutlineCloudUpload, MdOutlineDeleteSweep } from "react-icons/md";
import { GrOnedrive, GrDriveCage } from "react-icons/gr";
import { PiHardDrivesFill } from "react-icons/pi";
import attachOther from '../../media/img/folder_movie-link.png';
// coding
function renderItem(data, elementid) {
    if(data.thumbnail && (data.minetype == "youtube" || data.minetype == "image")){
        return (
            <LightgalleryItem group={"group_n"}  src={data.path} thumb={data.thumbnail}>
                <img className={`p-1`} src={data.thumbnail} style={{ maxWidth: 'var(--pix-wi-150)', minHeight: 'var(--pix-hi-90)', maxHeight: 'var(--pix-hi-120)', objectFit: 'cover' }} />
            </LightgalleryItem>
        )
    }
    else if(data.thumbnail && data.minetype == "other"){
        return (
            <a href={data.path} target="_blank">
                <img className={`p-1`} src={attachOther} style={{ maxWidth: 'var(--pix-wi-150)', minHeight: 'var(--pix-hi-90)', maxHeight: 'var(--pix-hi-120)', objectFit: 'cover' }} />
            </a>
        )
    }
}
function AttachmentCom(props) {
	return (
        <Card className={`border-0 rounded-0 mt-3 attachment-task`}>
            <CardHeader
                className={`border-0 rounded-0 fs-6 d-flex justify-content-start py-1 text-primary gap-1`}
            >
                <div className={`me-auto d-flex align-self-center`}>{props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.attachments : 'Attachments'} ({Util.renderNumberPlus(props.Tinfo.attachments ? props.Tinfo.attachments.length : 0)})</div>
                <MultiUpload wms_class={props.wms_class} savePathFile={props.savePathFile}/>
                <label htmlFor="file-upload-attachment" className="file-upload-attachment">
                    <div className="fileUploadButton">
                        <MdOutlineCloudUpload size="27" className={`bg-light hover-effect-border rounded-circle p-1 text-muted`} title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.upload : 'Upload'}/>
                        <Input type="file" multiple style={{display: 'none'}} id="file-upload-attachment" onChange={(event) => props.handleAttachFile(event)} />
                    </div>
                </label>
            </CardHeader>
            <CardBody
                className={`px-1 py-1`}
            >
                <LightgalleryProvider
                    lightgallerySettings={
                        {
                            // settings: https://sachinchoolur.github.io/lightgallery.js/docs/api.html
                            addClass: 'bg-dark',
                        }
                    }
                    galleryClassName="my_custom_classname"
                >
                    <div className={`d-flex gap-1 flex-wrap`}>
                        {props.Tinfo && props.Tinfo.attachments && props.Tinfo.attachments.map((p, idx) => (
                            <div key={idx} 
                            className={`position-relative d-flex justify-content-center align-self-center cursor-pointer`}>
                                <MdOutlineDeleteSweep size="27" onClick={() => props.onDeleteAttach(p.id)}
                                    style={{ zIndex: 10}}
                                    className={`bg-light opacity-25 rounded-circle p-1 text-danger position-absolute end-0 hover-opacity-unset`} title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.deleteattach : 'Delete Attachment'}/>
                                <div className={`hover-opacity-5per cursor-pointer`}>
                                    {navigator.onLine && !p.minetype && props.wms_class.getThumbnail(props, p.path, idx)}
                                    {renderItem(p, idx)}
                                </div>
                            </div>
                        ))}
                    </div>
                </LightgalleryProvider>
            </CardBody>
        </Card>
    );
};
function MultiUpload(props) {
    const [pathfile, setPathfile] = useState('');
    let _self = props.wms_class;
    let Trans = props.wms_class.comStage.state.Trans;
    const handleSubmitPath = (e) => {
        e.preventDefault();
        const _pathfile = pathfile;
        setPathfile('');
        props.savePathFile(pathfile, function(status){
            if (!status) {
                setPathfile(_pathfile);
            }
        });
    }
    return (
        <UncontrolledDropdown direction="up">
            <DropdownToggle caret color="light" className={`btn-sm p-0 border-0 rounded-circle force-stage-unset element-unset`}>
                <MdOutlineMoreHoriz size="27" className={`bg-light hover-effect-border rounded-circle p-1 text-muted`} 
                title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.more : 'More...'}/>
            </DropdownToggle>
            <DropdownMenu end flip className={`border-0 mt-2`}
                style={{width: 'var(--pix-wi-240)'}}
            >
                <DropdownItem  header>
                    {Trans ? Trans.task.moreoption : 'More Attachments'}
                </DropdownItem >
                <DropdownItem header tag="div" className={``}>
                    <Form onSubmit={handleSubmitPath} autoComplete={'off'}>
                        <InputGroup size="sm">
                            <Input pattern="https?://.*" type="url" placeholder="........." required="" value={pathfile} onChange={(e) => setPathfile(e.target.value)} />
                            <Button type="submit" color="light" className={`border border-gray hover-effect-border text-black-50 fw-bold text-capitalize`} onClick={(e) => { e.stopPropagation(); }}>{Trans ? Trans.task.savelink : 'Save'}</Button>
                        </InputGroup>
                    </Form>
                </DropdownItem >
                <DropdownItem className={`d-flex align-items-center hover-effect-border`} onClick={() => alert('Pending') }>
                    <GrDriveCage className={`me-2`} /> Amazone Cloud
                </DropdownItem >
                <DropdownItem className={`d-flex align-items-center hover-effect-border`} onClick={() => alert('Pending') }>
                    <FaGoogleDrive className={`me-2`} /> Google Drive
                </DropdownItem >
                <DropdownItem className={`d-flex align-items-center hover-effect-border`} onClick={() => alert('Pending') }>
                    <GrOnedrive className={`me-2`} /> OneDrive
                </DropdownItem >
                <DropdownItem className={`d-flex align-items-center hover-effect-border`} onClick={() => alert('Pending') }>
                    <FaDropbox className={`me-2`} /> Dropbox
                </DropdownItem >
            </DropdownMenu >
        </UncontrolledDropdown>
    );
};
export { AttachmentCom, MultiUpload };