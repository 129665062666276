// import npm packge
import React from "react";
import { ListGroup, ListGroupItem, CardImg } from 'reactstrap';
// import service api
//
// import core class
import Util from '../../core/util';
// import com page
import { AvatarCom } from '../Widget/AvatarCom';
import {MainFilter} from './AdvanceFilter';
// import modal tool
import {ManageMember} from '../Modal/memberModal';
// import style media
import { TbProgressHelp } from "react-icons/tb"; 
import { GrAddCircle } from "react-icons/gr";
import "./default.css";
// coding
class RightBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assign: '',
        }
    };
    componentDidUpdate(prevProps, prevState) {
        if (this.props.wms_class && this.props.wms_class.comStage && this.props.wms_class.comStage.state && this.props.wms_class.comStage.state.objData && this.props.wms_class.comStage.state.objData.filter.assign != this.state.assign) {
            this.setState({assign: this.props.wms_class.comStage.state.objData.filter.assign});
        }
    };
    onFilterTinfo = (uid) => {
        let _self = this;
        let wms_class = _self.props.wms_class;
        if (!wms_class.onsubmit) {
            Util.toast(this.props.wms_class.comStage.state.Trans.toast.isLoading, 'warning');
            return;
        }
        wms_class.prevent_continuity = true;
        wms_class.onsubmit = false;
        wms_class.trackInprocess();
        _self.setState({assign: uid});
        let filter = {..._self.props.wms_class.comStage.state.objData.filter};
        filter.assign = filter.assign != uid ? uid : '';
        const wStage = {
            ..._self.props.wms_class.comStage.state,
            objData: {
                ..._self.props.wms_class.comStage.state.objData,
                filter: filter
            }
        }
        _self.props.wms_class.comStage.setState(wStage);
        let comStage = _self.props.wms_class.react.state.wms_class.workflowman.comStage;
        let _filter = {...comStage.state.objData.filter};
        _filter.assign = _filter.assign != uid ? uid : '';
        const _wStage = {
            ...comStage.state,
            objData: {
                ...comStage.state.objData,
                filter: _filter
            }
        }
        comStage.setState(_wStage);
        setTimeout(async function(){
            await _self.props.wms_class.onSearchTinfos(function(){
                wms_class.prevent_continuity = false;
                wms_class.onsubmit = true;
                wms_class.trackInprocess();
            });
        }, 0);
    }
    render() {
        let _self = this.props;
        return (
            [
                <ManageMember key="index-memeberaccount" wms_class={_self.wms_class} react={_self.react}/>,
                <div key="index-rightbar" className="right-container d-flex flex-column justify-content-start position-absolute end-0 h-100 text-white bg-dark " >
                    <ListGroup tag="div" flush className={`work-scroller right-color-scroller`}>
                        <div className={`profile-item hover-opacity-5per ${this.state.assign == -1 ? 'bg-gray-800' : ''}`} onClick={() => this.onFilterTinfo(-1)}>
                            <ListGroupItem
                                action
                                tag="button"
                                className={`shadow-none bg-transparent btn-light d-flex justify-content-center border-0 mb-0`}
                            >
                                <TbProgressHelp size="34" className={`rounded-circle ${this.state.assign == -1 ? 'border border-info border-3' : ''} bg-light `} />
                            </ListGroupItem>
                            <h6 style={{fontSize: `var(--fs-9)`}} className={`text-center mb-0`}>Unassign...</h6>
                            <h6 style={{fontSize: `var(--fs-11)`}} className={`text-center text-secondary mb-0`}>{_self.wms_class.comStage && _self.wms_class.comStage.state && _self.wms_class.comStage.state.objData ? Util.renderNumberPlus(_self.wms_class.comStage.state.objData.unassign.complete) : 0}/{_self.wms_class.comStage && _self.wms_class.comStage.state && _self.wms_class.comStage.state.objData ? Util.renderNumberPlus(_self.wms_class.comStage.state.objData.unassign.failure) : 0}/{_self.wms_class.comStage && _self.wms_class.comStage.state && _self.wms_class.comStage.state.objData ? Util.renderNumberPlus(_self.wms_class.comStage.state.objData.unassign.total) : 0}</h6>
                            <h6 style={{fontSize: `var(--fs-12)`}} className={`text-center text-secondary`}>({_self.wms_class.comStage && _self.wms_class.comStage.state && _self.wms_class.comStage.state.objData ? Util.renderNumberPlus(_self.wms_class.comStage.state.objData.unassign.total_task) : 0})</h6>
                        </div>
                        <div className={`profile-item hover-opacity-5per`} onClick={() => this.props.react.toggleModalUsers()}>
                            <ListGroupItem
                                action
                                tag="button"
                                className={`shadow-none bg-transparent btn-transparent d-flex justify-content-center border-0 mb-0`}
                            >
                                <GrAddCircle size="36" className={`rounded-circle `} style={{background: `var(--bs-cyan)`}}/>
                            </ListGroupItem>
                        </div>
                        <hr style={{fontSize: `var(--fs-9)`}} className={`text-center my-0`} />
                        {this.props.wms_class.comStage && this.props.wms_class.comStage.state.lstMembers && this.props.wms_class.comStage.state.lstMembers.length > 0 && this.props.wms_class.comStage.state.lstMembers.map((member, i) => {
                            return member.isMember && member.uid == this.props.wms_class.comStage.state.objData.profiles.uid ? 
                                <div key={i} className={`profile-item hover-opacity-5per ${this.state.assign == member.uid ? 'bg-gray-800' : ''}`} onClick={() => this.onFilterTinfo(member.uid)}>
                                    <ListGroupItem
                                        action
                                        tag="button"
                                        className={`shadow-none bg-transparent btn-light d-flex justify-content-center border-0 mb-0`}
                                    >
                                        <AvatarCom member={member} width={'32'} height={'32'} unsetborder={this.state.assign != member.uid}/>
                                    </ListGroupItem>
                                    <h6 style={{fontSize: `var(--fs-9)`}} className={`text-center mb-0`}>{member.fullname}</h6>
                                    <h6 style={{fontSize: `var(--fs-11)`}} className={`text-center mb-0 ${this.state.assign == member.uid ? 'text-primary' : 'text-secondary'}`}>{Util.renderNumberPlus(member.tasks.complete)}/{Util.renderNumberPlus(member.tasks.failure)}/{Util.renderNumberPlus(member.tasks.total)}</h6>
                                    <h6 style={{fontSize: `var(--fs-12)`}} className={`text-center ${this.state.assign == member.uid ? 'text-primary' : 'text-secondary'}`}>({Util.renderNumberPlus(member.tasks.total_task)})</h6>
                                </div>
                            : ''
                        }
                        )}
                        {this.props.wms_class.comStage && this.props.wms_class.comStage.state.lstMembers && this.props.wms_class.comStage.state.lstMembers.length > 0 && this.props.wms_class.comStage.state.lstMembers.map((member, i) => {
                            return member.isMember && member.uid != this.props.wms_class.comStage.state.objData.profiles.uid ? 
                                <div key={i} className={`profile-item hover-opacity-5per ${this.state.assign == member.uid ? 'bg-gray-800' : ''}`} onClick={() => this.onFilterTinfo(member.uid)}>
                                    <ListGroupItem
                                        action
                                        tag="button"
                                        className={`shadow-none bg-transparent btn-light d-flex justify-content-center border-0 mb-0`}
                                    >
                                        <AvatarCom member={member} width={'32'} height={'32'} unsetborder={this.state.assign != member.uid}/>
                                    </ListGroupItem>
                                    <h6 style={{fontSize: `var(--fs-9)`}} className={`text-center mb-0`}>{member.fullname}</h6>
                                    <h6 style={{fontSize: `var(--fs-11)`}} className={`text-center mb-0 ${this.state.assign == member.uid ? 'text-primary' : 'text-secondary'}`}>{Util.renderNumberPlus(member.tasks.complete)}/{Util.renderNumberPlus(member.tasks.failure)}/{Util.renderNumberPlus(member.tasks.total)}</h6>
                                    <h6 style={{fontSize: `var(--fs-12)`}} className={`text-center ${this.state.assign == member.uid ? 'text-primary' : 'text-secondary'}`}>({Util.renderNumberPlus(member.tasks.total_task)})</h6>
                                </div>
                            : ''
                        }
                        )}
                    </ListGroup>
                    <MainFilter wms_class={_self.wms_class} react={_self.react} />
                </div>
            ]
        );
    }
}
export default RightBar;