import axios from 'axios';
import Util from '../core/util';

export default class ServiceWinfo {
    static instance = ServiceWinfo.instance || new ServiceWinfo();

    pagingWinfo = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/pagingWinfo`, data, Util.getHeaders());
    }

    showWinfo = (id) =>{
        return axios.get(`${Util.getAPIPrefix()}/showWinfo/${id}`, Util.getHeaders());
    }
    
    storeWinfo = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/storeWinfo`, data, Util.getHeaders());
    }

    updateWinfo = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/updateWinfo/${id}`, data, Util.getHeaders());
    }

    deleteWinfo = (id) =>{
        return axios.delete(`${Util.getAPIPrefix()}/deleteWinfo/${id}`, Util.getHeaders());
    }
}