import axios from 'axios';
import Util from '../core/util';

export default class ServiceConfig {
    static instance = ServiceConfig.instance || new ServiceConfig();

    listColor = () =>{
        return axios.get(`${Util.getAPIPrefix()}/listColor`, Util.getHeaders());
    }

    hexcodeById = (id) =>{
        return axios.get(`${Util.getAPIPrefix()}/hexcodeById/${id}`, Util.getHeaders());
    }

    listIcon = () =>{
        return axios.get(`${Util.getAPIPrefix()}/listIcon`, Util.getHeaders());
    }

    listIconByCate = () =>{
        return axios.get(`${Util.getAPIPrefix()}/listIconByCate`, Util.getHeaders());
    }

    wconstants = () =>{
        return axios.get(`${Util.getAPIPrefix()}/wconstants`, Util.getHeaders());
    }

    wreferences = () =>{
        return axios.get(`${Util.getAPIPrefix()}/wreferences`, Util.getHeaders());
    }

    widgetpacks = () =>{
        return axios.get(`${Util.getAPIPrefix()}/widgetpacks`, Util.getHeaders());
    }
}