import axios from 'axios';
import Util from '../core/util';

export default class ServiceWidgets {
    static instance = ServiceWidgets.instance || new ServiceWidgets();

    pagingWidgets = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/pagingWidgets`, data, Util.getHeaders());
    }
    
    storeWidgets = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/storeWidgets`, data, Util.getHeaders());
    }

    updateWidgets = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/updateWidgets/${id}`, data, Util.getHeaders());
    }

    deleteWidgets = (id) =>{
        return axios.delete(`${Util.getAPIPrefix()}/deleteWidgets/${id}`, Util.getHeaders());
    }

    pagingWidgetExtends = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/pagingWidgetExtends`, data, Util.getHeaders());
    }
    
    storeWidgetExtends = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/storeWidgetExtends`, data, Util.getHeaders());
    }

    updateWidgetExtends = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/updateWidgetExtends/${id}`, data, Util.getHeaders());
    }

    deleteWidgetExtends = (id) =>{
        return axios.delete(`${Util.getAPIPrefix()}/deleteWidgetExtends/${id}`, Util.getHeaders());
    }

    pagingAutoAssigns = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/pagingAutoAssigns`, data, Util.getHeaders());
    }
    
    storeAutoAssigns = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/storeAutoAssigns`, data, Util.getHeaders());
    }

    updateAutoAssigns = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/updateAutoAssigns/${id}`, data, Util.getHeaders());
    }

    deleteAutoAssigns = (id) =>{
        return axios.delete(`${Util.getAPIPrefix()}/deleteAutoAssigns/${id}`, Util.getHeaders());
    }
}