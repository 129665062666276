// import npm packge
import React, { } from 'react';
import _ from 'lodash';
import {
    MDBContainer, MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBBtn, MDBIcon, MDBInput, MDBCheckbox
}
from 'mdb-react-ui-kit';
import { ButtonGroup, Button } from 'reactstrap';
import { Input, Form} from 'reactstrap';
import axios from 'axios';
// import service api
import ServiceAuth  from '../../services/ServiceAuth';
// import core class
import Util from '../../core/util';
import { API_ROOT, PREFIX_LOCAL } from '../../core/constant';
// import com page
//
// import modal tool
//
// import style media
import "./default.css";
// coding
export default class Access extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            workspaces: [],
            justifyActive: 'signin',
            Trans: {},
            remember: false,
            is_owner: false,
            langcode: 'vn',
            languages: [{identifier: 'vn', name: 'Vietnam'}, {identifier: 'en', name: 'English'}],
        };
    }
    componentWillMount() {
        //
    };
    componentWillUnmount() {
        // this.props.onUnload();
    };
    componentDidMount() {
        axios.get(API_ROOT+`/workspaces`)
        .then(res => {
            this.setState({ workspaces: res.data });
            axios.get(API_ROOT + `/getTrans/` + this.state.langcode)
            .then(res => {
                this.setState({ Trans: res.data });
            })
        })
    };
    componentDidUpdate(prevProps, prevState) {
        //
    };
    handleJustifyClick = (value) => {
        if (value === this.state.justifyActive) {
            return;
        }
        this.setState({ justifyActive: value });
    };
    handleChange = (e) => {
        let _self = this;
        const { name, value } = e.target;
        this.setState({ [name]: value });
        switch(name) {
        case 'workspace_id':
            let _find = _.find(this.state.workspaces, {id: parseInt(value)});
            let _langcode = this.state.langcode;
            if (_find) {
                localStorage.setItem(PREFIX_LOCAL + 'fe_domain', JSON.stringify(_find.fe_domain));
                _self.setState({ langcode: _find.langcode });
                _langcode = _find.langcode;
            }
            axios.get(API_ROOT + `/getTrans/` + _langcode)
            .then(res => {
                _self.setState({ Trans: res.data });
            })
            break;
        case 'langcode':
            axios.get(API_ROOT + `/getTrans/` + value)
            .then(res => {
                _self.setState({ Trans: res.data });
            })
            break;
        }
    };
    handleCheckbox = (e) => {
        const { name, checked } = e.target;
        this.setState({ [name]: checked });
    };
    handleLogin = (e) => {
        e.preventDefault();
        const { workspace_id, username, password, langcode, remember, is_owner} = this.state;
        const credentials = {
            workspace_id,
            username,
            password,
            langcode,
            remember,
            is_owner
        };
        ServiceAuth.instance.login(credentials)
        .then(({ data }) => {
            if (data && data.status && data.status == true) {
                localStorage.setItem(PREFIX_LOCAL + 'access_token', JSON.stringify(data.token));
                localStorage.setItem(PREFIX_LOCAL + 'verify_code', JSON.stringify(data.workspace.verify_code));
                localStorage.setItem(PREFIX_LOCAL + 'fe_domain', JSON.stringify(data.workspace.fe_domain));
                localStorage.setItem(PREFIX_LOCAL + 'username', JSON.stringify(data.username));
                localStorage.setItem(PREFIX_LOCAL + 'hashtoken', JSON.stringify(data.hashtoken));
                localStorage.setItem(PREFIX_LOCAL + 'langcode', JSON.stringify(data.langcode));
                localStorage.setItem(PREFIX_LOCAL + 'user_id', JSON.stringify(data.user_id));
                localStorage.setItem(PREFIX_LOCAL + 'is_owner', JSON.stringify(is_owner ? 1 : 0));
                window.location.assign('/');
            } else {
                Util.toast(data.error, 'error');
            }
        })
        .catch((e) => {
            console.log(e)
        });
    };
    render() {
        return (
            <MDBContainer className="p-3 my-5 d-flex flex-column access-login">
                {this.state.Trans.login_form && this.state.Trans.login_form.login && (
                <MDBTabs pills justify className='mb-3 d-flex flex-row justify-content-between'>
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => this.handleJustifyClick('signin')} active={this.state.justifyActive === 'signin'} className={`w-auto`}>
                            {this.state.Trans.login_form.login}
                        </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => alert('Pending')} active={this.state.justifyActive === 'signup'} className={`w-auto`}>
                            {this.state.Trans.login_form.register}
                        </MDBTabsLink>
                    </MDBTabsItem>
                </MDBTabs>
                )}
                {this.state.Trans.login_form && this.state.Trans.login_form.login && (
                <MDBTabsContent>
                    <MDBTabsPane show={this.state.justifyActive === 'signin'}>
                        <Form onSubmit={this.handleLogin} className={`mb-0`} autoComplete={'off'}>
                            {/*<div className="text-center mb-3">
                                <p>Sign in with:</p>
                                <div className='d-flex justify-content-between mx-auto' style={{width: '40%'}}>
                                    <MDBBtn tag='button' color='none' className='m-1 p-2 btn btn-light rounded-circle hover-opacity-5per d-flex justify-content-center btn-social'>
                                        <MDBIcon fab icon='facebook-f' size="sm" className={`mt-1`}/>
                                    </MDBBtn>
                                    <MDBBtn tag='button' color='none' className='m-1 p-2 btn btn-light rounded-circle hover-opacity-5per d-flex justify-content-center btn-social'>
                                        <MDBIcon fab icon='twitter' size="sm" className={`mt-1`}/>
                                    </MDBBtn>
                                    <MDBBtn tag='button' color='none' className='m-1 p-2 btn btn-light rounded-circle hover-opacity-5per d-flex justify-content-center btn-social'>
                                        <MDBIcon fab icon='google' size="sm" className={`mt-1`}/>
                                    </MDBBtn>
                                    <MDBBtn tag='button' color='none' className='m-1 p-2 btn btn-light rounded-circle hover-opacity-5per d-flex justify-content-center btn-social'>
                                        <MDBIcon fab icon='github' size="sm" className={`mt-1`}/>
                                    </MDBBtn>
                                </div>
                                <p className="text-center mt-3">or:</p>
                            </div>*/}
                            <Input type="select" bsSize="xl" id="language-select" value={this.state.langcode} name="langcode" className={`mb-4`} style={{'cursor': 'pointer'}} onChange={this.handleChange} required={true}>
                                {this.state.languages.map((p, idx) => (
                                <option key={idx} value={p.identifier}>
                                    {p.name}
                                </option>   
                                ))}
                            </Input>
                            <Input type="select" bsSize="xl" id="branch-select" value={this.state.workspace_id} name="workspace_id" className={`mb-4`} style={{'cursor': 'pointer'}} onChange={this.handleChange} required={true}>
                                <option value="">
                                    {this.state.Trans.login_form.select_workspace}
                                </option>
                                {this.state.workspaces.map((p, idx) => (
                                <option key={idx} value={p.id}>
                                    {p.work_name}
                                </option>   
                                ))}
                            </Input>
                            <MDBInput wrapperClass='mb-4' label={this.state.Trans.login_form.email_username} type='text' name="username" onChange={this.handleChange} required={true}/>
                            <MDBInput wrapperClass='mb-4' label={this.state.Trans.login_form.password} type='password' name="password" onChange={this.handleChange} required={true}/>
                            <div className="d-flex justify-content-between mb-2">
                                <MDBCheckbox name='is_owner' value='' id='flexCheckOwner' label={this.state.Trans.login_form.is_owner} onChange={this.handleCheckbox} />
                                <MDBCheckbox name='remember' value='' id='flexCheckDefault' label={this.state.Trans.login_form.remember_me} onChange={this.handleCheckbox} />
                                <a href="#" onClick={(e) => { e.preventDefault(); alert('Pending');}}>{this.state.Trans.login_form.forgot_password}</a>
                            </div>
                            <MDBBtn className="w-100">{this.state.Trans.login_form.login}</MDBBtn>
                            {/*<p className="text-center">{this.state.Trans.login_form.not_a_member} 
                                <a href="#" onClick={(e) => { e.preventDefault(); alert('Pending');}}>{this.state.Trans.login_form.register}</a>
                            </p>*/}
                        </Form>
                    </MDBTabsPane>
                    <MDBTabsPane show={this.state.justifyActive === 'signup'}>
                        {/*<div className="text-center mb-3">
                            <p>Sign in with:</p>
                            <div className='d-flex justify-content-between mx-auto' style={{width: '40%'}}>
                                <MDBBtn tag='button' color='none' className='m-1 p-2 btn btn-light rounded-circle hover-opacity-5per d-flex justify-content-center btn-social'>
                                    <MDBIcon fab icon='facebook-f' size="sm" className={`mt-1`}/>
                                </MDBBtn>
                                <MDBBtn tag='button' color='none' className='m-1 p-2 btn btn-light rounded-circle hover-opacity-5per d-flex justify-content-center btn-social'>
                                    <MDBIcon fab icon='twitter' size="sm" className={`mt-1`}/>
                                </MDBBtn>
                                <MDBBtn tag='button' color='none' className='m-1 p-2 btn btn-light rounded-circle hover-opacity-5per d-flex justify-content-center btn-social'>
                                    <MDBIcon fab icon='google' size="sm" className={`mt-1`}/>
                                </MDBBtn>
                                <MDBBtn tag='button' color='none' className='m-1 p-2 btn btn-light rounded-circle hover-opacity-5per d-flex justify-content-center btn-social'>
                                    <MDBIcon fab icon='github' size="sm" className={`mt-1`}/>
                                </MDBBtn>
                            </div>
                            <p className="text-center mt-3">or:</p>
                        </div>*/}
                        <MDBInput wrapperClass='mb-4' label={this.state.Trans.login_form.name} type='text'/>
                        <MDBInput wrapperClass='mb-4' label={this.state.Trans.login_form.email_username} type='text'/>
                        {/*<MDBInput wrapperClass='mb-4' label='Email' type='email'/>*/}
                        <MDBInput wrapperClass='mb-4' label={this.state.Trans.login_form.password} type='password'/>
                        <div className='d-flex justify-content-center mb-4'>
                            <MDBCheckbox name='flexCheck' id='flexCheckDefault' label={this.state.Trans.login_form.term} />
                        </div>
                        <MDBBtn className="mb-4 w-100" onClick={() => alert('Pending')}>{this.state.Trans.login_form.register}</MDBBtn>
                    </MDBTabsPane>
                </MDBTabsContent>
                )}
            </MDBContainer>
        );
    }
}