import axios from 'axios';
import Util from '../core/util';
import { PREFIX_LOCAL } from '../core/constant';

export default class ServiceAuth {
    static instance = ServiceAuth.instance || new ServiceAuth();
    
    login = (data) => {
        return axios.post(`${Util.getAPIPrefix()}/login`, data);
    }

    checklogin = (wflow_id) =>{
        return axios.post(`${Util.getAPIPrefix()}/checklogin`, {username: JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'username')), hashtoken: JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'hashtoken'))}, Util.getHeaders());
    }

    logout = () =>{
        return axios.post(`${Util.getAPIPrefix()}/logout`, {token: JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'access_token'))}, Util.getHeaders());
    }
}