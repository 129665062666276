import axios from 'axios';
import Util from '../core/util';

export default class ServiceUser {
    static instance = ServiceUser.instance || new ServiceUser();

    pagingUser = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/pagingUser`, data, Util.getHeaders());
    }

    getLinkedUser = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/getLinkedUser`, data, Util.getHeaders());
    }
    
    storeUser = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/storeUser`, data, Util.getHeaders());
    }

    updateUser = (uid, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/updateUser/${uid}`, data, Util.getHeaders());
    }

    deleteUser = (uid) =>{
        return axios.delete(`${Util.getAPIPrefix()}/deleteUser/${uid}`, {}, Util.getHeaders());
    }
    
    assignWmber = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/assignWmber`, data, Util.getHeaders());
    }
    
    removeWmber = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/removeWmber`, data, Util.getHeaders());
    }
    
    deleteUser = (uid) =>{
        return axios.delete(`${Util.getAPIPrefix()}/deleteUser/${uid}`, Util.getHeaders());
    }

    changeLangcode = (langcode) =>{
        return axios.get(`${Util.getAPIPrefix()}/langcode/${langcode}`, Util.getHeaders());
    }

    pagingWmember = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/pagingWmember`, data, Util.getHeaders());
    }

    getNotifiesSetting = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/getNotifiesSetting`, data, Util.getHeaders());
    }

    storeNotifiesSetting = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/storeNotifiesSetting`, data, Util.getHeaders());
    }

    updateNotifiesSetting = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/updateNotifiesSetting/${id}`, data, Util.getHeaders());
    }

    pagingNotifies = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/pagingNotifies`, data, Util.getHeaders());
    }

    updateNotifies = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/updateNotifies/${id}`, data, Util.getHeaders());
    }

    deleteNotifies = (id) =>{
        return axios.delete(`${Util.getAPIPrefix()}/deleteNotifies/${id}`, Util.getHeaders());
    }
}