// ListColors
const ListColors = [];
// ListTags
const ListTags = [];
// ListIcons
const ListIcons = [];
// ListWinfos
const ListWinfos = [];
// ListMembers
const ListMembers = [];
// ObjectData
const ObjectData = {
    workfolows: {
        id: 1,
        name: '',
        statis: {
            name: 'Task',
        },
    },
    ontrack: '02:35:09',
    task_id : -1,
    tracking : [],
    filter: {
        inputsearch: '',
        stage_step: [],
        status: 'active',
        checklist_result: [],
        assign: '',
        watches: [],
        tags: [],
        dateline: 'created',
        template: 'default',
        date: {},
        clear: false,
    },
    noticount: 0,
    notitotal: 0,
    profiles: {
        uid: 1,
        fullname: '',
        shortname: 'CN',
        avatar: '',
        email: '',
        role: 'Administrator',
        language: 'VN',
        popover: {
            name: '',
            action: {
                account: 'Account',
                preferences: 'Preferences',
                logout: 'Logout',
            }
        }
    },
    inprocess: 0,
    review: 0,
    complete: 0,
    failure: 0,
    total: 0,
    unassign: {
        inprocess: 0,
        complete: 0,
        failure: 0,
        total: 0,
        total_task: 0,
    },
    tasks: [],
    columns: [],
    stages: [],
    templateActive: false,
    notifies: [],
    loadingNotifies: false,
    pagingNoti: {
        rows: 10,
        unread: false,
        mention: false,
    },
};
const Trans = {
    header: {},
    setting: {},
    login_form: {},
    profile: {},
    member: {},
    task: {},
    toast: {},
};
export {
    ListIcons,
    ListColors,
    ListTags,
    ListWinfos,
    ListMembers,
    ObjectData,
    Trans
};