// import npm packge
import React, { useState } from "react";
import { Card, CardHeader, CardBody, CardText, Button, ListGroup, InputGroup, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
    UncontrolledAccordion, AccordionItem, AccordionHeader, AccordionBody, FormGroup, Label, Input
} from 'reactstrap';
import DatePicker from "react-datepicker";
import $ from 'jquery';
// import service api
//
// import core class
import Util from '../../core/util';
// import com page
import { TrackingTime } from './../Stage/Task';
import { CustomElement } from './CustomElement';
// import modal tool
//
// import style media
import { FaRegFaceSmile, FaRegFaceSurprise } from "react-icons/fa6";
import { FcBusinessman, FcBusinesswoman, FcLike, FcCheckmark, FcCloseUpMode } from "react-icons/fc";
import { MdOutlineCalendarMonth } from "react-icons/md";

function RightTDetail(props) {
    // thêm func tránh lỗi element
    let onChangeTfield = (event, i) => {
        event.preventDefault();
    }
    let outFocusTfield = (event, i) => {
        event.preventDefault();
    }
    let handlePreventAccordion = (event) => {
        $('#accordion-right-task-detail .accordion-header').children('button').removeAttr('disabled');
        let _showing = $(event.currentTarget).next('.accordion-collapse.collapse').hasClass('show');
        if(!_showing){
            $(event.currentTarget).children('button').attr('disabled','disabled');
        }
    }
	return (
        [   
            <div key="maintask-right" className={`maintask-right bg-light h-100`}
                style={{width: 'var(--per-wi-34)', background: 'var(--bs-gray-100)'}}>
                <Card  className={`border-0 rounded-0 bg-transparent shadow-none`}
                    >
                    <CardBody
                        className={`px-0 py-0 bg-transparent action-task-detail d-flex grap-1 rounded-0`}
                    >
                        {props.taskTypes && props.activeType && props.taskTypes.length > 0 && (
                        <UncontrolledDropdown>
                            <DropdownToggle caret className={`btn btn-info btn-sm outline-info d-flex align-items-center text-white rounded-0`}>
                                {props.activeType.name}
                            </DropdownToggle>
                            <DropdownMenu end flip className={`border-1`}>
                                {props.taskTypes && props.taskTypes.map((type, i) => { 
                                    return type.identifier != props.activeType.identifier ? <DropdownItem key={i} className={`d-flex align-items-center hover-effect-border small`}
                                    onClick={() => props.changeType(type)}>
                                        {type.name}
                                    </DropdownItem> : ''
                                }
                                )}
                            </DropdownMenu >
                        </UncontrolledDropdown>)}
                        {props.Tinfo.uid && props.Tinfo.tasktime && !props.Tinfo.isRunning && (
                            <TrackingTime module={'task'} task_id={props.Tinfo.uid} task_time={props.Tinfo.tasktime} isRunning={props.Tinfo.isRunning} wms_class={props.wms_class} />
                        )}
                        {props.Tinfo.uid && props.Tinfo.tasktime && props.Tinfo.isRunning && (
                            <TrackingTime module={'task'} task_id={props.Tinfo.uid} task_time={props.Tinfo.tasktime} isRunning={props.Tinfo.isRunning} wms_class={props.wms_class} />
                        )}
                        <div
                            className={`btn btn-primary btn-sm outline-primary d-flex align-self-center px-0 py-0 shadow-none cursor-pointer rounded-0`}
                            title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.duedate : 'Due Date'}
                        >   
                            {
                                props.startDate != ('' || null) && (
                                    [
                                        <MdOutlineCalendarMonth key={`task-duedate-ico`} size="28" className={`bg-primary p-1`}/>,
                                        <DatePicker key={`task-duedate-com`}
                                            selected={props.startDate}
                                            onChange={(date) => props.setDueDate(date)}
                                            minDate={new Date()}
                                            dateFormat={props.wms_class.comStage.state.objData.workspace.date_fm + ` h:mm aa`}
                                            className={`input-datepicker form-control py-1 border-0 rounded-0`}
                                            calendarClassName={`rasta-stripes`}
                                            timeClassName={props.handleColor}
                                            showTimeSelect
                                            isClearable
                                            title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.duedate : 'Due Date'}
                                        />
                                    ]
                                )
                            }
                        </div>
                    </CardBody>
                </Card>
                {props.Tinfo.mode && (
                <UncontrolledAccordion key="accordion-right-task-detail" id="accordion-right-task-detail" className={`border-0 mt-0 px-0`}>
                    {props.Tinfo.mode == 'link' && (
                    <AccordionItem className={`border-0`}>
                        <AccordionHeader targetId="accordion_custom_1" className={`py-1 bg-light`} onClick={(event) => handlePreventAccordion(event)}>
                            {props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.infodetail : 'Info Detail'}
                        </AccordionHeader>
                        <AccordionBody accordionId="accordion_custom_1">
                        {props.Tinfo.wflow_fields && props.Tinfo.wflow_fields.map((field, i) => {
                            return field.onaccess == 'active' || field.onaccess == 'deactive' && props.wms_class.comStage.state.objData.profiles.accessfield == 'active' ? (
                            <FormGroup key={i}>
                                <Label>
                                    {field.name}
                                </Label>
                                <CustomElement disabled={true} element={field.element} index={i} className={``} name={'value'} value={field.value} onChange={onChangeTfield} onBlur={outFocusTfield}></CustomElement>
                            </FormGroup>) : ''
                            }
                        )}
                        </AccordionBody>
                    </AccordionItem>
                    )}
                    <AccordionItem className={`border-0`}>
                        <AccordionHeader targetId="accordion_custom_2" className={`py-1 bg-light`} onClick={(event) => handlePreventAccordion(event)}>
                            {props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.customfield : 'Custom Field'}
                        </AccordionHeader>
                        <AccordionBody accordionId="accordion_custom_2">
                        {props.Tinfo.custom_fields && props.Tinfo.custom_fields.map((field, i) => {
                            return field.on_main == 'deactive' && (field.onaccess == 'active' || field.onaccess == 'deactive' && props.wms_class.comStage.state.objData.profiles.accessfield == 'active') ? (
                            <FormGroup key={i}>
                                <Label>
                                    {field.name}
                                </Label>
                                <CustomElement element={field.element} index={i} className={``} name={'value'} value={field.value} onChange={props.onChangeTfield} onBlur={props.outFocusTfield} lstTags={props.wms_class.comStage.state.lstTags} setValueTagCustomField={props.setValueTagCustomField}></CustomElement>
                            </FormGroup>) : ''
                            }
                        )}
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className={`border-0`}>
                        <AccordionHeader targetId="accordion_custom_3" className={`py-1 bg-light`} onClick={(event) => handlePreventAccordion(event)}>
                            {props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.sticknote : 'Stick Note'}
                        </AccordionHeader>
                        <AccordionBody accordionId="accordion_custom_3">
                            <strong>
                                Pending.
                            </strong>
                        </AccordionBody>
                    </AccordionItem>
                </UncontrolledAccordion>
                )}
            </div>
        ]
    );
};

export { RightTDetail };