import axios from 'axios';
import Util from '../core/util';

export default class ServiceFile {
    static instance = ServiceFile.instance || new ServiceFile();util = new Util();
    
    uploadavatar = (data, files) =>{
        let form_data = this.util.jsonToFormData(data);
        Object.values(files).forEach(function(file, index) {
            form_data.append(`files[${index}]`, file);
        });
        return axios.post(`${Util.getAPIPrefix()}/uploadavatar`, form_data, Util.getHeaders());
    }
    
    uploadTfile = (uid, files) =>{
        let form_data = new FormData();;
        Object.values(files).forEach(function(file, index) {
            form_data.append(`files[${index}]`, file);
        });
        return axios.post(`${Util.getAPIPrefix()}/uploadTfile/${uid}`, form_data, Util.getHeaders());
    }

    savePathFile = (uid, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/uploadTfile/${uid}`, data, Util.getHeaders());
    }

    deleteTfile = (id) =>{
        return axios.delete(`${Util.getAPIPrefix()}/deleteTfile/${id}`, Util.getHeaders());
    }
}