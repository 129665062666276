import axios from 'axios';
import Util from '../core/util';

export default class ServiceSinfo {
    static instance = ServiceSinfo.instance || new ServiceSinfo();

    pagingSinfo = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/pagingSinfo`, data, Util.getHeaders());
    }
    
    storeSinfo = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/storeSinfo`, data, Util.getHeaders());
    }

    updateSinfo = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/updateSinfo/${id}`, data, Util.getHeaders());
    }

    deleteSinfo = (id) =>{
        return axios.delete(`${Util.getAPIPrefix()}/deleteSinfo/${id}`, Util.getHeaders());
    }
}