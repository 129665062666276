// import npm packge
import React, { useState } from "react";
import {
    CardImg,
    CardBody,
    Input
} from 'reactstrap';
// import service api
import ServiceFile  from '../../services/ServiceFile';
// import core class
import Util from '../../core/util';
// import com page
//
// import modal tool
//
// import style media
//
// coding
function UploadAndDisplayImage($this) {
    const [selectedImage, setSelectedImage] = useState($this.wms_class.comStage.workflowman.objData.profiles.avatar);
    const handleLoadAvatar = (e) => {
        var file = e.target.files[0];
        if (!file.type.startsWith('image/')) {
            Util.toast('Image is required', 'error');
            document.getElementById('customFileUpload').value = null;
            return false;
        }
        var reader = new FileReader();
        reader.onload = (e) => {
            var img = document.createElement("img");
            img.onload = async () => {
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                var MAX_WIDTH = 360;
                var MAX_HEIGHT = 360;
                var width = img.width;
                var height = img.height;
                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                var dataurl = canvas.toDataURL("image/png");
                await handleUpLoadAvatar($this, dataurl);
                ServiceFile.instance.uploadavatar({uid: $this.wms_class.comStage.workflowman.objData.profiles.uid}, [Util.dataURLtoFile(dataurl, file.name)])
                .then(({ data }) => {
                    if (data.status) {
                        handleUpLoadAvatar($this, data.path);
                    } else {
                        Util.toast(data.error, 'error')
                    }
                });
            }
            img.src = e.target.result;
        }
        reader.readAsDataURL(file);
    }
    const handleUpLoadAvatar = ($this, $dataurl, $target = null) => {
        let _idx_mem = Util.indexMembers($this.wms_class.lstMembers, $this.wms_class.objData.profiles.uid);
        if (_idx_mem){
            $this.wms_class.lstMembers[_idx_mem].avatar = $dataurl;
        }
        $this.wms_class.objData.profiles.avatar = $dataurl;
        const profileStage = {
            ...$this.wms_class.comStage.state,
            objData: {
                ...$this.wms_class.comStage.state.objData,
                profiles: $this.wms_class.objData.profiles
            },
            lstMembers: $this.wms_class.lstMembers
        }
        $this.wms_class.comStage.setState(profileStage);
        $this.wms_class.react.setState({ wms_class: $this.wms_class.comStage });
        setSelectedImage($dataurl);
    };
    const _name_str = Util.convertNameToText($this.wms_class.comStage.workflowman.objData.profiles.fullname);
    return (
        <div className={`text-center`}>
            {selectedImage && (
                <CardBody className={`py-0 px-0`}>
                    <CardImg
                        alt="Your profile"
                        src={selectedImage}
                        style={{
                            width: 150,
                            height: 150
                        }}
                        className={`rounded-circle border display-name-profile border-info border-5`}
                        />
                </CardBody>
            )}
            {!selectedImage && (
                <CardBody className={`py-0 px-0 d-flex justify-content-center`}>
                    <p
                        style={{
                            width: 150,
                            height: 150,
                            fontSize: 55,
                            lineHeight: '145px'
                        }}
                        className={`rounded-circle border display-name-profile border-info border-5`}>
                        {_name_str}
                    </p>
                </CardBody>
            )}
            <br />
            <Input type="file" accept="image/*" id="customFileUpload" name="customFile" onChange={(event) => handleLoadAvatar(event)} />
        </div>
    );
};
export { UploadAndDisplayImage };