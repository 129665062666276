// import npm packge
import React, { useState, useEffect } from 'react';
import {
    Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Form, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, //
    Table, Badge, InputGroup, InputGroupText, ListGroup, CardBody, CardText, Collapse
} from 'reactstrap';
import Select from 'react-select';
import _ from 'lodash';
// import serivce api
import ServiceConfig  from '../../services/ServiceConfig';
import ServiceWinfo  from '../../services/ServiceWinfo';
import ServiceWchlist  from '../../services/ServiceWchlist';
import ServiceWstep  from '../../services/ServiceWstep';
import ServiceWtags  from '../../services/ServiceWtags';
import ServiceWfields  from '../../services/ServiceWfields';
import ServiceWidgets  from '../../services/ServiceWidgets';
// import core class
import Util from '../../core/util';
import { subscribe, unsubscribe } from "../../core/events";
// import com page
//
// import modal tool
//
// import style media
import SVG from 'react-inlinesvg';
import { MdOutlineAdd, MdOutlineSave, MdOutlineClear, MdOutlineWidgets } from "react-icons/md";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import './default.css';
function WorkflowSetting($this) {
    let Trans = $this.wms_class.Trans;
    useEffect(() => {
        // console.log('debug workfolowsdata is: ', workfolows);
        subscribe("showWinfo", () => showWinfo());
        subscribe("pagingWtags", () => getWtags());
        return () => {
            unsubscribe("showWinfo", () => console.log('unsubscribe showWinfo'));
            unsubscribe("pagingWtags", () => console.log('unsubscribe pagingWtags'));
        }
    }, [workfolows]);
    const [modal, setModal] = useState(false);
    const [workfolows, setWorkflows] = useState({name: '', described: '', owner_by: '', createb_on: '', created_by: ''});
    // State for current active Tab
    const [currentActiveTab, setCurrentActiveTab] = useState('info');
    const [wsteps, setWsteps] = useState([]);
    const [wstepList, setWstepList] = useState([]);
    const [Wchecklists, setWchecklists] = useState([]);
    const [Wtags, setWtags] = useState([]);
    const [listColors, setListColors] = useState([]);
    const [Wfields, setWfields] = useState([]);
    const [listWfields, setListWfields] = useState([]);
    const [preferenceTables, setPreferenceTables] = useState([]);
    const [Elements, setElements] = useState([]);
    const [Fieldtypes, setFieldtypes] = useState([]);
    const [modeLink, setMode] = useState(false);
    const [modeLinkWlist, setModeWlist] = useState(true);
    const [widgetsList, setWidgetsList] = useState([]);
    const [widgetExtendsList, setWidgetExtendsList] = useState([]);
    const [autoAssignsList, setAutoAssignsList] = useState([]);
    const [stageChecklists, setStageChecklists] = useState([]);

    // Toggle active state for Tab
    const toggleTab = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
        $this.wms_class.onsubmit = false;
        $this.wms_class.trackInprocess();
        switch(true){
            case currentActiveTab !== tab && tab == 'info':
                showWinfo();
                break;
            case currentActiveTab !== tab && tab == 'checklist':
                getWchecklist();
                break;
            case currentActiveTab !== tab && tab == 'tag':
                getWtags();
                break;
            case currentActiveTab !== tab && tab == 'field':
                setMode(false);
                getWfields(false);
                break;
            case currentActiveTab !== tab && tab == 'extend':
                getWidgets(false);
                break;
        }
    }
    $this.header_self.toggleModalWinfo = () => {
        if (!modal) {
            setWorkflows({});
            $this.wms_class.onsubmit = false;
            $this.wms_class.trackInprocess();
            $this.wms_class.getLstMembers($this.wms_class.comStage.state.objData.workfolows.id, function($argmb, $statusmb){
                if($argmb){
                    $this.wms_class.react.setState({wms_class: $argmb});
                    showWinfo();
                }
                else {
                    Util.toast($statusmb.error, 'error');
                }
                $this.wms_class.onsubmit = true;
                $this.wms_class.trackInprocess();
            });
        }
        setModal(!modal)
    };
    const showWinfo = () => {
        ServiceWinfo.instance.showWinfo($this.wms_class.comStage.state.objData.workfolows.id)
        .then(async ({ data }) => {
            if (data.status) {
                let _winfo = data.data;
                _winfo.owner_by = Util.getFullname(($this.wms_class.comStage && $this.wms_class.comStage.state.lstMembers //
                    ? $this.wms_class.comStage.state.lstMembers : []), _winfo.owner);
                _winfo.created_by = Util.getFullname(($this.wms_class.comStage && $this.wms_class.comStage.state.lstMembers //
                    ? $this.wms_class.comStage.state.lstMembers : []), _winfo.created_by);
                $this.wms_class.react.setState({ wms_class: $this.wms_class.comStage });
                _winfo.created_at = Util.formatDateTime(_winfo.created_at, $this.wms_class.comStage.state.objData.workspace.date_fm);
                setWorkflows(prev => ({...{}, ..._winfo}));
            } else {
                Util.toast(data.error, 'error');
                setWorkflows(prev => ({...{}, ...{}}));
            }
            $this.wms_class.onsubmit = true;
            $this.wms_class.trackInprocess();
        })
        .catch(() => {
            //
        });
    }
    const handleChange = (event) => {
        event.preventDefault();
        const {name, value} = event.target;
        setWorkflows(prev => ({...prev, [name]: value }));
        if (name == 'name') {
            $this.wms_class.comStage.workflowman.objData.workfolows.name = value;
            $this.react.setState({ wms_class: $this.react.state.wms_class.comStage });
        }   
    }
    const onSubmitWinfo = (e) => {
        e.preventDefault();
        $this.wms_class.onsubmit = false;
        $this.wms_class.trackInprocess();
        ServiceWinfo.instance.updateWinfo($this.wms_class.comStage.state.objData.workfolows.id, {name: workfolows.name, described: workfolows.described})
        .then(({ data }) => {
            if (data.status) {
                // $this.wms_class.comStage.workflowman.objData.workfolows.name = workfolows.name;
                // $this.react.setState({ wms_class: $this.react.state.wms_class.comStage });
                Util.toast(data.success);
            } else {
                Util.toast(data.error, 'error');
            }
            $this.wms_class.onsubmit = true;
            $this.wms_class.trackInprocess();
        })
    }
    //wchecklist
    const getWchecklist = () => {
        ServiceWstep.instance.pagingWstep({wflow_id:$this.wms_class.comStage.state.objData.workfolows.id}).then(({ data }) => {
            if (data.status) {
                setWstepList(_.filter(data.data, function(o) { return modeLinkWlist ? o.parent_uid : !o.parent_uid; }));
                setWsteps(data.data);
            } else {
                Util.toast(data.error, 'error')
            }
            $this.wms_class.onsubmit = true;
            $this.wms_class.trackInprocess();
        })
        ServiceWchlist.instance.pagingWchlist({wflow_id:$this.wms_class.comStage.state.objData.workfolows.id}).then(({ data }) => {
            if (data.status) {
                setWchecklists(data.data);
            } else {
                setWchecklists([]);
                Util.toast(data.error, 'error')
            }
            $this.wms_class.onsubmit = true;
            $this.wms_class.trackInprocess();
        })
    }
    const changeMode = () => {
        setWstepList(_.filter(wsteps, function(o) { return !modeLinkWlist ? o.parent_uid : !o.parent_uid; }));
        setModeWlist(!modeLinkWlist);
    }
    const onSelectedWstep = (wstep) => {
        let checklists = [...Wchecklists];
        checklists.push({mode: modeLinkWlist ? 'link' : 'core', wflow_id: $this.wms_class.comStage.state.objData.workfolows.id, step: wstep.uid, content: wstep.name, stage_name: wstep.stage_name, index: ''});
        setWchecklists(checklists);
    }
    const handleChangeWchecklist = (event, i) => {
        event.preventDefault();
        const {name, value} = event.target;
        let checklists = [...Wchecklists];
        checklists[i][name] = value;
        setWchecklists(checklists);
    }
    const onSubmitChecklist = (checklist) => {
        $this.wms_class.onsubmit = false;
        $this.wms_class.trackInprocess();
        if (checklist.id) {
            const id = checklist.id;
            delete checklist.id;
            ServiceWchlist.instance.updateWchlist(id, checklist)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                getWchecklist();
            })
        } else {
            ServiceWchlist.instance.storeWchlist(checklist)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                getWchecklist();
            })
        }
    }
    const onDeleteChecklist = (id, i) => {
        if (!id) {
            setWchecklists([
                ...Wchecklists.slice(0, i),
                ...Wchecklists.slice(i + 1)
            ]);
        } else {
            $this.wms_class.onsubmit = false;
            $this.wms_class.trackInprocess();
            ServiceWchlist.instance.deleteWchlist(id)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                getWchecklist();
            })
        }   
    }
    //wtags
    const getWtags = () => {
        setListColors($this.wms_class.comStage.state.lstColors)
        $this.wms_class.getLstTags($this.wms_class.comStage.state.objData.workfolows.id, function($argmb, $res){
            if($argmb){
                setWtags($argmb.state.lstTags);
                $this.wms_class.react.setState({wms_class: $argmb});
            }
            else {
                setWtags([]);
                Util.toast($argmb.error, 'error');
            }
            $this.wms_class.onsubmit = true;
            $this.wms_class.trackInprocess();
        });
    }
    const addTag = () => {
        let wtags = [...Wtags];
        const random_color = _.sample($this.wms_class.comStage.state.lstColors);
        wtags.unshift({type: 'default', background: random_color ? random_color.hexcode : '#cccccc', name: '', wflow_id: $this.wms_class.comStage.state.objData.workfolows.id});
        setWtags(wtags);
    }
    const handleChangeWtag = (event, i) => {
        event.preventDefault();
        const {name, value} = event.target;
        let wtags = [...Wtags];
        wtags[i][name] = value;
        setWtags(wtags);
    }
    const onClickColor = (e, i, hexcode) => {
        e.preventDefault();
        let wtags = [...Wtags];
        wtags[i]['background'] = hexcode;
        setWtags(wtags);
    }
    const onSubmitWtag = (tag) => {
        $this.wms_class.onsubmit = false;
        $this.wms_class.trackInprocess();
        if (tag.id) {
            const id = tag.id;
            delete tag.id;
            ServiceWtags.instance.updateWtags(id, tag)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                // getWtags();
                $this.wms_class.onsubmit = true;
                $this.wms_class.trackInprocess();
            })
        } else {
            ServiceWtags.instance.storeWtags(tag)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                // getWtags();
                $this.wms_class.onsubmit = true;
                $this.wms_class.trackInprocess();
            })
        }
    }
    const onDeleteWtag= (id, i) => {
        if (!id) {
            setWtags([
                ...Wtags.slice(0, i),
                ...Wtags.slice(i + 1)
            ]);
        } else {
            $this.wms_class.onsubmit = false;
            $this.wms_class.trackInprocess();
            ServiceWtags.instance.deleteWtags(id)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                // getWtags();
                $this.wms_class.onsubmit = true;
                $this.wms_class.trackInprocess();
            })
        }   
    }
    //wfields
    const getWfields = (reload_root = true) => {
        ServiceConfig.instance.wreferences().then(({ data }) => {
            setPreferenceTables(data);
        })
        ServiceWfields.instance.pagingWfields({wflow_id:$this.wms_class.comStage.state.objData.workfolows.id}).then(({ data }) => {
            if (data.status) {
                setWfields(data.data);
            } else {
                setWfields([]);
                Util.toast(data.error, 'error')
            }
            $this.wms_class.onsubmit = true;
            $this.wms_class.trackInprocess();
            if (reload_root) {
                setTimeout(async function(){
                    await $this.wms_class.clearFilter();
                });
            }
        })
        let elements = _.filter($this.wms_class.comStage.state.lstConstants, { 'mode': 'element' });
        setElements(elements);
        let fieldtypes = _.filter($this.wms_class.comStage.state.lstConstants, { 'mode': 'fieldtype' });
        setFieldtypes(fieldtypes);
    }
    const addField = () => {
        let wfields = [...Wfields];
        wfields.unshift({mode: 'core', wflow_id: $this.wms_class.comStage.state.objData.workfolows.id, name: '', fieldtype: '', element: '', name: '', value: ''});
        setWfields(wfields);
    }
    const onSelectedOption = (table) => {
        let value = 'select * from ' + table.table_name;
        let wfields = [...Wfields];
        wfields.unshift({mode: 'link', wflow_id: $this.wms_class.comStage.state.objData.workfolows.id, name: '', fieldtype: '', element: '', name: '', value: value});
        setWfields(wfields);
    }
    const handleChangeWfield = (event, i) => {
        event.preventDefault();
        const {name, value} = event.target;
        let wfields = [...Wfields];
        wfields[i][name] = value;
        setWfields(wfields);
    }
    const onSubmitWfield = (field) => {
        $this.wms_class.onsubmit = false;
        $this.wms_class.trackInprocess();
        if (field.id) {
            const id = field.id;
            delete field.id;
            ServiceWfields.instance.updateWfields(id, field)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                getWfields();
            })
        } else {
            ServiceWfields.instance.storeWfields(field)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                getWfields();
            })
        }
    }
    const onDeleteWfield= (id, i) => {
        if (!id) {
            setWfields([
                ...Wfields.slice(0, i),
                ...Wfields.slice(i + 1)
            ]);
        } else {
            $this.wms_class.onsubmit = false;
            $this.wms_class.trackInprocess();
            ServiceWfields.instance.deleteWfields(id)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                getWfields();
            })
        }   
    }
    const handleCheck = async (key, e, i) => {
        e.preventDefault();
        let _checked = e.target.checked ? 'active' : 'deactive';
        let wms_class = $this.wms_class;
        if (!wms_class.onsubmit) {
            Util.toast($this.wms_class.comStage.state.Trans.toast.isLoading, 'warning');
            return;
        }
        wms_class.onsubmit = false;
        wms_class.trackInprocess();
        switch(key){
            case 'checklist':
                let checklists = [...Wchecklists];
                await setWchecklists([]);
                checklists[i]['status'] = _checked;
                setWchecklists(prev => ([...[], ...checklists]));
                onSubmitChecklist(checklists[i]);
                break;
            case 'tag':
                let wtags = [...Wtags];
                await setWtags([]);
                wtags[i]['status'] = _checked;
                setWtags(prev => ([...[], ...wtags]));
                onSubmitWtag(wtags[i]);
                break;
            case 'field':
                let _target_name = e.target.name;
                let wfields = [...Wfields];
                await setWfields([]);
                wfields[i][_target_name] = _checked;
                setWfields(prev => ([...[], ...wfields]));
                onSubmitWfield(wfields[i]);
                break;
        }
    }
    //widget
    const getWidgets = (reload_root = true, overwrite = true) => {
        ServiceWidgets.instance.pagingWidgets({wflow_id:$this.wms_class.comStage.state.objData.workfolows.id}).then(({ data }) => {
            if (data.status) {
                if (overwrite) {
                    setWidgetsList(data.data);
                }
            } else {
                setWidgetsList([]);
                Util.toast(data.error, 'error')
            }
            $this.wms_class.onsubmit = true;
            $this.wms_class.trackInprocess();
            if (reload_root) {
                setTimeout(async function(){
                    await $this.wms_class.getWidgetpacks(true);
                });
            }
        })
    }
    const toggleWidget = async function(e, i) {
        e.preventDefault();
        let wms_class = $this.wms_class;
        if (!wms_class.onsubmit) {
            Util.toast($this.wms_class.comStage.state.Trans.toast.isLoading, 'warning');
            return;
        }
        let widgets = [...widgetsList];
        await setWidgetsList([]);
        let _status = widgets[i]['status'];
        widgets[i]['status'] = _status == 'active' ? 'deactive' : 'active';
        setWidgetsList(prev => ([...[], ...widgets]));
        wms_class.onsubmit = false;
        wms_class.trackInprocess();
        ServiceWidgets.instance.updateWidgets(widgets[i].id, widgets[i])
        .then(({ data }) => {
            if (data.status) {
                Util.toast(data.success)
                getWidgets(true, false);
            } else {
                Util.toast(data.error, 'error')
                getWidgets();
            }
        })
    }
    const collapseExpand = async function(e, i) {
        e.preventDefault();
        let widgets = [...widgetsList];
        await setWidgetsList([]);
        _.forEach(widgets, function(value, index) {
            let _isOpen = value['isOpen'];
            value.isOpen = index == i ? (_isOpen == 1 ? 0 : 1) : 0;
        });
        setWidgetsList(prev => ([...[], ...widgets]));
        if (widgets[i]['isOpen'] == 1) {
            switch(true){
                case widgets[i] && widgets[i]['identifier'] == 'template_report':
                case widgets[i] && widgets[i]['identifier'] == 'notify_data':
                    getWidgetExtends(widgets[i]['identifier'], false);
                    break;
                case widgets[i] && widgets[i]['identifier'] == 'auto_assign':
                case widgets[i] && widgets[i]['identifier'] == 'auto_specify':
                case widgets[i] && widgets[i]['identifier'] == 'auto_checklist':
                case widgets[i] && widgets[i]['identifier'] == 'auto_status':
                case widgets[i] && widgets[i]['identifier'] == 'auto_extend':
                    getAutoAssigns(widgets[i]['identifier']);
                    if (widgets[i] && widgets[i]['identifier'] == 'auto_checklist') {
                        ServiceWchlist.instance.pagingWchlist({wflow_id:$this.wms_class.comStage.state.objData.workfolows.id}).then(({ data }) => {
                            setStageChecklists(_.map(data.stage_wchlists, function(p) {
                                p.label = p.stage_name + ' - ' + p.content;
                                p.value = p.id;
                                return p;
                            }));
                        })
                    }
                    break;
            }
        } else {
            setWidgetExtendsList([]);
        }
    }
    const getWidgetExtends = (identifier = '', reload_root = true) => {
        ServiceWidgets.instance.pagingWidgetExtends({wflow_id:$this.wms_class.comStage.state.objData.workfolows.id, identifier:identifier}).then(({ data }) => {
            if (data.status) {
                setWidgetExtendsList(data.data);
            } else {
                setWidgetExtendsList([]);
                Util.toast(data.error, 'error')
            }
            $this.wms_class.onsubmit = true;
            $this.wms_class.trackInprocess();
            ServiceWfields.instance.pagingWfields({wflow_id:$this.wms_class.comStage.state.objData.workfolows.id}).then(({ data }) => {
                if (data.status) {
                    setListWfields(data.data);
                } else {
                    setListWfields([]);
                    Util.toast(data.error, 'error')
                }
                if (reload_root) {
                    setTimeout(async function(){
                        await $this.wms_class.clearFilter();
                    });
                }
            })
        })
    }
    const addWigetExtend = function(identifier) {
        let _widgetExtendsList = [...widgetExtendsList];
        _widgetExtendsList.unshift({identifier: identifier, wflow_id: $this.wms_class.comStage.state.objData.workfolows.id, name: '', key: ''});
        setWidgetExtendsList(_widgetExtendsList);
    }
    const handleChangeWidget = (event, i) => {
        event.preventDefault();
        const {name, value} = event.target;
        let _widgetExtendsList = [...widgetExtendsList];
        _widgetExtendsList[i][name] = value;
        setWidgetExtendsList(_widgetExtendsList);
    }
    const onSubmitWidgetExtend = (identifier, widgetExtend) => {
        $this.wms_class.onsubmit = false;
        $this.wms_class.trackInprocess();
        if (widgetExtend.id) {
            const id = widgetExtend.id;
            delete widgetExtend.id;
            ServiceWidgets.instance.updateWidgetExtends(id, widgetExtend)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                getWidgetExtends(identifier);
            })
        } else {
            ServiceWidgets.instance.storeWidgetExtends(widgetExtend)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                getWidgetExtends(identifier);
            })
        }
    }
    const onDeleteWidgetExtend = (identifier, id, i) => {
        if (!id) {
            setWidgetExtendsList([
                ...widgetExtendsList.slice(0, i),
                ...widgetExtendsList.slice(i + 1)
            ]);
        } else {
            $this.wms_class.onsubmit = false;
            $this.wms_class.trackInprocess();
            ServiceWidgets.instance.deleteWidgetExtends(id)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                getWidgetExtends(identifier);
            })
        }   
    }
    const onSelectedCustomFields = async(fields, i) => {
        let _widgetExtendsList = [...widgetExtendsList];
        _widgetExtendsList[i]['custom_fields'] = _.map(fields, 'id').join(',');
        setWidgetExtendsList(_widgetExtendsList);
    }
    const getAutoAssigns = (identifier = '') => {
        ServiceWidgets.instance.pagingAutoAssigns({wflow_id:$this.wms_class.comStage.state.objData.workfolows.id, identifier:identifier}).then(({ data }) => {
            if (data.status) {
                setAutoAssignsList(data.data);
            } else {
                setAutoAssignsList([]);
                Util.toast(data.error, 'error')
            }
            $this.wms_class.onsubmit = true;
            $this.wms_class.trackInprocess();
        })
    }
    const onSelectedStage = function(stage, identifier) {
        let _autoAssignsList = [...autoAssignsList];
        let objData = {};
        if (identifier == 'auto_checklist') {
            objData = {identifier: identifier, wflow_id: $this.wms_class.comStage.state.objData.workfolows.id, stage_id: stage.stage_id, stage_name: stage.stage_name, value: stage.id, described: '', content: stage.content};
        } else {
            objData = {identifier: identifier, wflow_id: $this.wms_class.comStage.state.objData.workfolows.id, stage_id: stage.id, stage_name: stage.name, value: '', described: ''};
        }
        _autoAssignsList.unshift(objData);
        setAutoAssignsList(_autoAssignsList);
    }
    const onSelectedUser = function(user, identifier, i) {
        let _autoAssignsList = [...autoAssignsList];
        _autoAssignsList[i]['value'] = user.uid;
        setAutoAssignsList(_autoAssignsList);
    }
    const handleChangeAutoAssign = (event, i) => {
        event.preventDefault();
        const {name, value} = event.target;
        let _autoAssignsList = [...autoAssignsList];
        if (name == 'value' && _autoAssignsList[i]['identifier'] == 'auto_extend' && (parseInt(value) < 0 || parseInt(value) > 100)) {
            _autoAssignsList[i][name] = 0;
        } else {
            _autoAssignsList[i][name] = value;
        }
        setAutoAssignsList(_autoAssignsList);
    }
    const onSubmitAutoAssign = (identifier, autoAssign) => {
        $this.wms_class.onsubmit = false;
        $this.wms_class.trackInprocess();
        if (autoAssign.id) {
            const id = autoAssign.id;
            delete autoAssign.id;
            ServiceWidgets.instance.updateAutoAssigns(id, autoAssign)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                getAutoAssigns(identifier);
            })
        } else {
            ServiceWidgets.instance.storeAutoAssigns(autoAssign)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                getAutoAssigns(identifier);
            })
        }
    }
    const onDeleteAutoAssign = (identifier, id, i) => {
        if (!id) {
            setAutoAssignsList([
                ...autoAssignsList.slice(0, i),
                ...autoAssignsList.slice(i + 1)
            ]);
        } else {
            $this.wms_class.onsubmit = false;
            $this.wms_class.trackInprocess();
            ServiceWidgets.instance.deleteAutoAssigns(id)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                getAutoAssigns(identifier);
            })
        }   
    }

    return (
        <Modal isOpen={modal} toggle={$this.header_self.toggleModalWinfo} {...$this} fullscreen={true} scrollable={true}
            >
            <ModalHeader toggle={$this.header_self.toggleModalWinfo}
                className={``}>
                {Trans.setting.workflow_setting}
            </ModalHeader>
            <ModalBody className={`pb-0`}>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={currentActiveTab === 'info' ? "active" : undefined}
                            onClick={() => { toggleTab('info'); }}
                        >
                            {Trans.setting.info}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={currentActiveTab === 'checklist' ? "active" : undefined}
                            onClick={() => { toggleTab('checklist'); }}
                        >
                            {Trans.setting.checklists}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={currentActiveTab === 'tag' ? "active" : undefined}
                            onClick={() => { toggleTab('tag'); }}
                        >
                            {Trans.setting.tags}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={currentActiveTab === 'field' ? "active" : undefined}
                            onClick={() => { toggleTab('field'); }}
                        >
                            {Trans.setting.fields}
                        </NavLink>
                    </NavItem>
                    {($this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.objData.profiles && $this.wms_class.comStage.state.objData.profiles && $this.wms_class.comStage.state.objData.profiles.role != 'Member') && (
                    <NavItem>
                        <NavLink
                            className={currentActiveTab === 'extend' ? "active" : undefined}
                            onClick={() => { toggleTab('extend'); }}
                        >
                            {Trans.setting.extend}
                        </NavLink>
                    </NavItem>
                    )}
                </Nav>
                <TabContent activeTab={currentActiveTab}>
                    <TabPane tabId="info">
                        <Form className="mt-3 mb-0" onSubmit={onSubmitWinfo} autoComplete={'off'}>
                            <FormGroup>
                                <Label for="workflow-name">
                                    {Trans.setting.name}
                                </Label>
                                <Input
                                    id="workflow-name"
                                    name="name"
                                    value={workfolows.name || ''}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="described">
                                    {Trans.setting.described}
                                </Label>
                                <Input
                                    id="described"
                                    name="described"
                                    value={workfolows.described || ''}
                                    onChange={(e) => handleChange(e)}
                                />
                            </FormGroup>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="owner">
                                            {Trans.setting.owned_by}
                                        </Label>
                                        <Input
                                            id="owner"
                                            name="owner_by"
                                            disabled
                                            value={workfolows.owner_by || ''}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                    <Label for="created_on">
                                        {Trans.setting.created_on}
                                    </Label>
                                    <Input
                                        id="created_on"
                                        name="created_on"
                                        disabled
                                        value={workfolows.created_at || ''}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                    <Label for="created_by">
                                        {Trans.setting.created_by}
                                    </Label>
                                    <Input
                                        id="created_by"
                                        name="created_by"
                                        disabled
                                        value={workfolows.created_by || ''}
                                    />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <ModalFooter>
                                <Button color="primary">
                                    {Trans.setting.update}
                                </Button>
                            </ModalFooter>
                        </Form>
                    </TabPane>
                    <TabPane tabId="checklist">
                        <div className={`d-flex my-3`}>
                            <Col>
                                <FormGroup switch>
                                    <Input type="switch" role="switch" defaultChecked={modeLinkWlist} onClick={() => changeMode()} disabled={Wchecklists.length && !Wchecklists[0].id} />
                                    <Label check>{Trans.setting.mode_link}</Label>
                                </FormGroup>
                            </Col>
                            <Col style={{width: 'var(--pix-w-270)'}}>
                                <Select options={wstepList} onChange={onSelectedWstep} placeholder={Trans.setting.select_wstep}/>
                            </Col>
                        </div>
                        <Table striped className={`mb-3`}>
                            <thead>
                                <tr>
                                    <th>
                                        {Trans.setting.index}
                                    </th>
                                    <th>
                                        {Trans.setting.step_stage}
                                    </th>
                                    <th>
                                        {Trans.setting.content}
                                    </th>
                                    <th>
                                        {Trans.setting.action}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            {Wchecklists.length > 0 && Wchecklists.map((checklist, i) =>
                                <tr key={i}>
                                    <td className={`align-middle`}>
                                        <Input name="index" placeholder={Trans.setting.index} type="number" value={checklist.index} onChange={(e) => handleChangeWchecklist(e, i)} />
                                    </td>
                                    <td className={`align-middle`}>
                                        <Input name="stage_name" placeholder={Trans.setting.step_stage} type="text" value={checklist.stage_name || ''} disabled={true} />
                                    </td>
                                    <td className={`align-middle`}>
                                        <Input name="content" placeholder={Trans.setting.content} type="text" value={checklist.content} onChange={(e) => handleChangeWchecklist(e, i)} disabled={checklist.mode == 'link'} />
                                    </td>
                                    <td className={`align-middle`}>
                                        <div className={`d-flex align-items-center`}>
                                            <MdOutlineSave title={Trans.setting.savechecklist} size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1 ${checklist.content ? '' : 'invisible'}`} onClick={() => onSubmitChecklist(checklist)} />
                                            <InputGroup className={`rounded-circle mx-1 p-1 item-do-list hover-effect`}
                                                style={{cursor: 'initial', width: 'var(--per-w-24)' }}>
                                                <label className={`align-self-start checkcontainer cursor-pointer`} title="Active/Deactive">
                                                    <Input onChange={(e) => handleCheck('checklist', e, i)}
                                                        addon
                                                        aria-label="Check Completed"
                                                        type="checkbox"
                                                        checked={checklist.status == 'active' ? true : false}
                                                        disabled={false}
                                                    />
                                                    <InputGroupText className="checkmark"></InputGroupText>
                                                </label>
                                            </InputGroup>
                                            <MdOutlineClear title={Trans.setting.deletechecklist} size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1`} onClick={() => onDeleteChecklist(checklist.id, i)} />
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </TabPane>
                    <TabPane tabId="tag">
                        <div className={`d-flex my-3`}>
                            <Row className="row-cols-lg-auto g-3 align-items-center">
                                {(Wtags.length == 0 || Wtags[0].id) && (<Col>
                                    <MdOutlineAdd title={Trans.setting.addtag} size="30" className={`bg-light hover-effect-border rounded-circle p-1 text-muted`} onClick={addTag} />
                                </Col>)}
                            </Row>
                        </div>
                        <Table striped className={`mb-3`}>
                            <thead>
                                <tr>
                                    <th>
                                        {Trans.setting.content}
                                    </th>
                                    <th>
                                        {Trans.setting.type}
                                    </th>
                                    <th>
                                        {Trans.setting.color}
                                    </th>
                                    <th>
                                        {Trans.setting.action}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {Wtags.length > 0 && Wtags.map((tag, i) =>
                                <tr key={i}>
                                    <td className={`align-middle`}>
                                        <Input name="name" placeholder={Trans.setting.content} type="text" value={tag.name} onChange={(e) => handleChangeWtag(e, i)}/>
                                    </td>
                                    <td className={`align-middle`}>
                                        <Input name="type" placeholder={Trans.setting.type} type="text" value={tag.type} onChange={(e) => handleChangeWtag(e, i)} disabled={true}/>
                                    </td>
                                    <td className={`align-middle`}>
                                        <div className={`winfo-tags d-flex align-items-center gap-1`}>
                                        {listColors.length > 0 && listColors.map((color, j) =>
                                            <Badge color="skip" key={`wstage-color` + color.id} id={`wstage-color` + color.id} tag="button" pill className={`hover-opacity-7per border-1 border-light ${color.hexcode == tag.background ? 'on-selected' : ''}`} title={color.name} 
                                                style={{
                                                    backgroundColor: color.hexcode,
                                                }}
                                                onClick={(e) => onClickColor(e, i, color.hexcode)}
                                            >&nbsp;</Badge>
                                        )}
                                        </div>
                                    </td>
                                    <td className={`align-middle`}>
                                        <div className={`d-flex align-items-center`}>
                                            <MdOutlineSave title={Trans.setting.savetag} size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1 ${tag.name && tag.background ? '' : 'invisible'}`} onClick={() => onSubmitWtag(tag)} />
                                            <InputGroup className={`rounded-circle mx-1 p-1 item-do-list hover-effect`}
                                                style={{cursor: 'initial', width: 'var(--per-w-24)' }}>
                                                <label className={`align-self-start checkcontainer cursor-pointer`} title="Active/Deactive">
                                                    <Input onChange={(e) => handleCheck('tag', e, i)}
                                                        addon
                                                        aria-label="Check Completed"
                                                        type="checkbox"
                                                        checked={tag.status == 'active' ? true : false}
                                                        disabled={false}
                                                    />
                                                    <InputGroupText className="checkmark"></InputGroupText>
                                                </label>
                                            </InputGroup>
                                            <MdOutlineClear title={Trans.setting.deletetag} size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1`}  onClick={() => onDeleteWtag(tag.id, i)} />
                                        </div>
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </Table>
                    </TabPane>
                    <TabPane tabId="field">
                        <div className={`d-flex my-3`}>
                            <Row className="row-cols-lg-auto g-3 align-items-center">
                                <Col>
                                    <FormGroup switch>
                                        <Input type="switch" role="switch" defaultChecked={modeLink} onClick={() => { setMode(!modeLink); }} disabled={Wfields.length && !Wfields[0].id} />
                                        <Label check>{Trans.setting.mode_link}</Label>
                                    </FormGroup>
                                </Col>
                                {(Wfields.length == 0 || Wfields[0].id) && (
                                <Col style={{width: 'var(--pix-w-270)'}}>
                                    {modeLink && (<Select options={preferenceTables} defaultValue="" onChange={onSelectedOption} placeholder={Trans.setting.select_table}/>)}
                                    {!modeLink && (<MdOutlineAdd title={Trans.setting.addfield} size="30" className={`bg-light hover-effect-border rounded-circle p-1 text-muted`} onClick={addField} />)}
                                </Col>
                                )}
                            </Row>
                        </div>
                        <Table striped className={`mb-3`}>
                            <thead>
                                <tr>
                                    <th>
                                        {Trans.setting.name}
                                    </th>
                                    <th>
                                        {Trans.setting.fieldtype}
                                    </th>
                                    <th>
                                        {Trans.setting.element}
                                    </th>
                                    <th>
                                        {Trans.setting.value}
                                    </th>
                                    <th>
                                        {Trans.setting.action}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {Wfields.length > 0 && Wfields.map((field, i) =>
                                <tr key={i}>
                                    <td className={`align-middle`}>
                                        <Input name="name" placeholder={Trans.setting.name} type="text" value={field.name} onChange={(e) => handleChangeWfield(e, i)}/>
                                    </td>
                                    <td className={`align-middle`}>
                                        <Input name="fieldtype" type="select" value={field.fieldtype} onChange={(e) => handleChangeWfield(e, i)}>
                                            <option value="">
                                                {Trans.setting.select_fieldtype}
                                            </option>
                                            {Fieldtypes.length > 0 && Fieldtypes.map((fieldtype, j) =>
                                            <option key={j} value={fieldtype.identifier}>
                                                {fieldtype.name}
                                            </option>
                                            )}
                                        </Input>
                                    </td>
                                    <td className={`align-middle`}>
                                        <Input name="element" type="select" value={field.element} onChange={(e) => handleChangeWfield(e, i)}>
                                            <option value="">
                                                {Trans.setting.select_element}
                                            </option>
                                            {Elements.length > 0 && Elements.map((element, k) =>
                                            <option key={k} value={element.identifier}>
                                                {element.name}
                                            </option>
                                            )}
                                        </Input>
                                    </td>
                                    <td className={`align-middle`}>
                                        <Input name="value" placeholder={Trans.setting.value} type="text" value={field.value ? field.value : ''} onChange={(e) => handleChangeWfield(e, i)}/>
                                    </td>
                                    <td className={`align-middle`}>
                                        <div className={`d-flex align-items-center`}>
                                            <InputGroup className={`rounded-circle mx-1 p-1 item-do-list hover-effect`}
                                                style={{cursor: 'initial', width: 'var(--per-w-24)' }}>
                                                <label className={`align-self-start checkcontainer cursor-pointer`} title="On Access">
                                                    <Input onChange={(e) => handleCheck('field', e, i)}
                                                        addon
                                                        type="checkbox"
                                                        checked={field.onaccess == 'active' ? true : false}
                                                        name="onaccess"
                                                        disabled={false}
                                                    />
                                                    <InputGroupText className="checkmark border-warning"></InputGroupText>
                                                </label>
                                            </InputGroup>
                                            <InputGroup className={`rounded-circle mx-1 p-1 item-do-list hover-effect`}
                                                style={{cursor: 'initial', width: 'var(--per-w-24)' }}>
                                                <label className={`align-self-start checkcontainer cursor-pointer`} title="On Merge">
                                                    <Input onChange={(e) => handleCheck('field', e, i)}
                                                        addon
                                                        type="checkbox"
                                                        checked={field.on_merge == 'active' ? true : false}
                                                        name="on_merge"
                                                        disabled={false}
                                                    />
                                                    <InputGroupText className="checkmark border-success"></InputGroupText>
                                                </label>
                                            </InputGroup>
                                            <InputGroup className={`rounded-circle mx-1 p-1 item-do-list hover-effect`}
                                                style={{cursor: 'initial', width: 'var(--per-w-24)' }}>
                                                <label className={`align-self-start checkcontainer cursor-pointer`} title="On Main/Right Bar">
                                                    <Input onChange={(e) => handleCheck('field', e, i)}
                                                        addon
                                                        type="checkbox"
                                                        checked={field.on_main == 'active' ? true : false}
                                                        name="on_main"
                                                        disabled={false}
                                                    />
                                                    <InputGroupText className="checkmark border-primary"></InputGroupText>
                                                </label>
                                            </InputGroup>
                                            <MdOutlineSave title={Trans.setting.savefield} size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1 ${field.name && field.fieldtype && field.element ? '' : 'invisible'}`} onClick={() => onSubmitWfield(field)} />
                                            <InputGroup className={`rounded-circle mx-1 p-1 item-do-list hover-effect`}
                                                style={{cursor: 'initial', width: 'var(--per-w-24)' }}>
                                                <label className={`align-self-start checkcontainer cursor-pointer`}  title="Active/Deactive">
                                                    <Input onChange={(e) => handleCheck('field', e, i)}
                                                        addon
                                                        type="checkbox"
                                                        checked={field.status == 'active' ? true : false}
                                                        name="status"
                                                        disabled={false}
                                                    />
                                                    <InputGroupText className="checkmark"></InputGroupText>
                                                </label>
                                            </InputGroup>
                                            <MdOutlineClear title={Trans.setting.deletefield} size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1`}  onClick={() => onDeleteWfield(field.id, i)} />
                                        </div>
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </Table>
                    </TabPane>
                    {($this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.objData.profiles && $this.wms_class.comStage.state.objData.profiles && $this.wms_class.comStage.state.objData.profiles.role != 'Member') && (
                    <TabPane tabId="extend">
                        <CardBody className={`d-flex`}>
                            <ListGroup className={`mt-3 w-100`}>
                                {widgetsList && widgetsList.map((widget, i) => { 
                                    let _widget = _.find($this.wms_class.comStage.state.lstWidgetpacks, { identifier: widget.identifier});
                                    let _bgcolor = Util.indexColorbyId($this.wms_class.comStage.state.lstColors, _widget ? _widget.bgrid : false);
                                    let _txtcolor = _bgcolor;
                                    let _rgbcolor = Util.hex2Rgb(_bgcolor, 10);
                                    _bgcolor = _rgbcolor ? _rgbcolor.rgb.replace(/rgb/i, "rgba") : _bgcolor.replace(/\)/i,',0.1)');
                                    let _listIcons = [];
                                    _.forEach($this.wms_class.comStage.state.lstIcons, function(value, key) {
                                        _listIcons = _.uniq(_.flatten([_listIcons, value.items]));
                                    });
                                    let _icon = _.find(_listIcons, { id: _widget ? _widget.icoid : false});
                                    return _widget ? (
                                    <InputGroup key={i} className={`pb-2`}>
                                        <div className={`d-flex text-black-50 w-100`}>
                                            <div className={`d-flex align-items-start justify-content-start gap-3 text-black-50 w-100`}>
                                            {_icon && (<SVG src={_icon.path}
                                                width={32}
                                                height={32}
                                                className={`bg-light border rounded-circle text-black-50 p-1`}
                                            />)}
                                                <span className={`d-flex align-self-start fw-bold`}>{_widget.name}</span>
                                            </div>
                                            <FormGroup switch className={`d-flex justify-content-end`}>
                                                <Input type="switch" role="switch" checked={widget.status == 'active'} onChange={(e) => toggleWidget(e, i)} disabled={!($this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.objData.profiles && $this.wms_class.comStage.state.objData.profiles && $this.wms_class.comStage.state.objData.profiles.role == 'Owner')} />
                                                <Label check></Label>
                                            </FormGroup>
                                            <div className={`d-flex justify-content-end mx-3 ${_widget.oncrud == 'yes' ? 'cursor-pointer' : 'invisible'}`} onClick={(e) => collapseExpand(e, i)}>
                                                {widget.isOpen == 1 ? (<FaChevronUp/>) : (<FaChevronDown/>)}
                                            </div>
                                        </div>
                                        <div className={`ps-3 ms-3 w-100`}>
                                            <div className={`bg-light ms-3 px-1 py-1`} style={{marginTop: -10}}>
                                                <CardText style={{fontSize: 'var(--fs-13)'}} className={`ms-2 m-0 text-muted`} dangerouslySetInnerHTML={{__html: _widget.described}}>
                                                </CardText>
                                            </div>
                                        </div>
                                        {_widget.oncrud == 'yes' && _widget.identifier == 'template_report' ? (
                                        <Collapse className={`w-100`} isOpen={widget.isOpen == 1}>
                                            <div className={`d-flex my-3`}>
                                                <Row className="row-cols-lg-auto g-3 align-items-center">
                                                    {(widgetExtendsList.length == 0 || widgetExtendsList[0].id) && (<Col>
                                                        <MdOutlineAdd title={Trans.setting.addwidget} size="30" className={`bg-light hover-effect-border rounded-circle p-1 text-muted`} onClick={() => addWigetExtend(_widget.identifier)} />
                                                    </Col>)}
                                                </Row>
                                            </div>
                                            {(widgetExtendsList.length > 0) && 
                                                <Table striped className={`mb-3`}>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                {Trans.setting.name}
                                                            </th>
                                                            <th>
                                                                {Trans.setting.key}
                                                            </th>
                                                            <th>
                                                                {Trans.setting.fields}
                                                            </th>
                                                            <th>
                                                                {Trans.setting.action}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {widgetExtendsList.map((widgetExtend, j) =>
                                                        <tr key={j}>
                                                            <td className={`align-middle`}>
                                                                <Input name="name" placeholder={Trans.setting.name} type="text" value={widgetExtend.name} onChange={(e) => handleChangeWidget(e, j)}/>
                                                            </td>
                                                            <td className={`align-middle`}>
                                                                <Input name="key" placeholder={Trans.setting.key} type="text" value={widgetExtend.key} onChange={(e) => handleChangeWidget(e, j)}/>
                                                            </td>
                                                            <td className={`align-middle`}>
                                                                <CustomFields widget={widgetExtend} fields={listWfields} j={j} onSelectedCustomFields={onSelectedCustomFields}/>
                                                            </td>
                                                            <td className={`align-middle`}>
                                                                <div className={`d-flex align-items-center`}>
                                                                    <MdOutlineSave title={Trans.setting.savewidget} size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1 ${widgetExtend.name && widgetExtend.key ? '' : 'invisible'}`} onClick={() => onSubmitWidgetExtend(_widget.identifier, widgetExtend)} />
                                                                    <MdOutlineClear title={Trans.setting.deletewidget} size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1`}  onClick={() => onDeleteWidgetExtend(_widget.identifier, widgetExtend.id, j)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            }
                                        </Collapse>) : ''}
                                        {_widget.oncrud == 'yes' && _widget.identifier == 'auto_assign' ? (
                                        <Collapse className={`w-100`} isOpen={widget.isOpen == 1}>
                                            <div className={`d-flex my-3`}>
                                                <Row className="row-cols-lg-auto g-3 align-items-center">
                                                    {(autoAssignsList.length == 0 || autoAssignsList[0].id) && (
                                                    <Col style={{width: 'var(--pix-w-270)'}}>
                                                        <Select options={_.map(Object.assign([], $this.wms_class.comStage.state.objData.columns), function(p) {
                                                            p.label = p.name;
                                                            p.value = p.id;
                                                            return p;
                                                        })} onChange={(stage) => onSelectedStage(stage, _widget.identifier)} placeholder={Trans.setting.select_stage}/>
                                                    </Col>)}
                                                </Row>
                                            </div>
                                            {(autoAssignsList.length > 0) && 
                                                <Table striped className={`mb-3`}>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                {Trans.setting.stage}
                                                            </th>
                                                            <th>
                                                                {Trans.task.assign}
                                                            </th>
                                                            <th>
                                                                {Trans.setting.described}
                                                            </th>
                                                            <th>
                                                                {Trans.setting.action}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {autoAssignsList.map((autoAssign, j) =>
                                                        <tr key={j}>
                                                            <td className={`align-middle`}>
                                                                <Input name="stage_name" placeholder={Trans.setting.stage} type="text" value={autoAssign.stage_name} readOnly={true} onChange={(e) => handleChangeAutoAssign(e, j)} />
                                                            </td>
                                                            <td className={`align-middle`}>
                                                                <Input name="value" placeholder={Trans.task.assign} type="select" value={autoAssign.value} onChange={(e) => handleChangeAutoAssign(e, j)}>
                                                                    <option value="">
                                                                        {Trans.task.assign}
                                                                    </option>
                                                                {$this.wms_class.comStage.state.lstMembers.map((member, k) =>
                                                                    <option key={k} value={member.uid.toString()}>
                                                                        {member.fullname}
                                                                    </option>
                                                                )}
                                                                </Input>
                                                            </td>
                                                            <td className={`align-middle`}>
                                                                <Input name="described" placeholder={Trans.setting.described} type="text" value={autoAssign.described || ''} onChange={(e) => handleChangeAutoAssign(e, j)} />
                                                            </td>
                                                            <td className={`align-middle`}>
                                                                <div className={`d-flex align-items-center`}>
                                                                    <MdOutlineSave title={Trans.setting.saveassign} size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1 ${autoAssign.identifier && autoAssign.value ? '' : 'invisible'}`} onClick={() => onSubmitAutoAssign(_widget.identifier, autoAssign)} />
                                                                    <MdOutlineClear title={Trans.setting.deleteassign} size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1`}  onClick={() => onDeleteAutoAssign(_widget.identifier, autoAssign.id, j)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            }
                                        </Collapse>) : ''}
                                        {_widget.oncrud == 'yes' && _widget.identifier == 'auto_specify' ? (
                                        <Collapse className={`w-100`} isOpen={widget.isOpen == 1}>
                                            <div className={`d-flex my-3`}>
                                                <Row className="row-cols-lg-auto g-3 align-items-center">
                                                    {($this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.objData.profiles && $this.wms_class.comStage.state.objData.profiles && $this.wms_class.comStage.state.objData.profiles.role == 'Owner') && (autoAssignsList.length == 0 || autoAssignsList[0].id) && (
                                                    <Col style={{width: 'var(--pix-w-270)'}}>
                                                        <Select options={_.map(Object.assign([], $this.wms_class.comStage.state.objData.columns), function(p) {
                                                            p.label = p.name;
                                                            p.value = p.id;
                                                            return p;
                                                        })} onChange={(stage) => onSelectedStage(stage, _widget.identifier)} placeholder={Trans.setting.select_stage}/>
                                                    </Col>)}
                                                </Row>
                                            </div>
                                            {(autoAssignsList.length > 0) && 
                                                <Table striped className={`mb-3`}>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                {Trans.setting.stage}
                                                            </th>
                                                            <th>
                                                                {Trans.setting.value}
                                                            </th>
                                                            <th>
                                                                {Trans.setting.described}
                                                            </th>
                                                            <th>
                                                                {Trans.setting.action}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {autoAssignsList.map((autoAssign, j) =>
                                                        <tr key={j}>
                                                            <td className={`align-middle`}>
                                                                <Input name="stage_name" placeholder={Trans.setting.stage} type="text" value={autoAssign.stage_name} readOnly={true} onChange={(e) => handleChangeAutoAssign(e, j)} />
                                                            </td>
                                                            <td className={`align-middle`}>
                                                                <Input name="value" placeholder={Trans.setting.value} type="text" value={autoAssign.value || ''} onChange={(e) => handleChangeAutoAssign(e, j)} readOnly={($this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.objData.profiles && $this.wms_class.comStage.state.objData.profiles && $this.wms_class.comStage.state.objData.profiles.role != 'Owner')} />
                                                            </td>
                                                            <td className={`align-middle`}>
                                                                <Input name="described" placeholder={Trans.setting.described} type="text" value={autoAssign.described || ''} onChange={(e) => handleChangeAutoAssign(e, j)} readOnly={($this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.objData.profiles && $this.wms_class.comStage.state.objData.profiles && $this.wms_class.comStage.state.objData.profiles.role != 'Owner')} />
                                                            </td>
                                                            <td className={`align-middle`}>
                                                                <div className={`d-flex align-items-center`}>
                                                                    <MdOutlineSave title={Trans.setting.saveassign} size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1 ${autoAssign.identifier && autoAssign.value ? '' : 'invisible'}`} onClick={() => onSubmitAutoAssign(_widget.identifier, autoAssign)} />
                                                                    <MdOutlineClear title={Trans.setting.deleteassign} size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1`}  onClick={() => onDeleteAutoAssign(_widget.identifier, autoAssign.id, j)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            }
                                        </Collapse>) : ''}
                                        {_widget.oncrud == 'yes' && _widget.identifier == 'auto_checklist' ? (
                                        <Collapse className={`w-100`} isOpen={widget.isOpen == 1}>
                                            <div className={`d-flex my-3`}>
                                                <Row className="row-cols-lg-auto g-3 align-items-center">
                                                    {(autoAssignsList.length == 0 || autoAssignsList[0].id) && (
                                                    <Col style={{width: 'var(--pix-w-270)'}}>
                                                        <Select options={stageChecklists} onChange={(stage) => onSelectedStage(stage, _widget.identifier)} placeholder={Trans.setting.select_stage}/>
                                                    </Col>)}
                                                </Row>
                                            </div>
                                            {(autoAssignsList.length > 0) && 
                                                <Table striped className={`mb-3`}>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                {Trans.setting.stage}
                                                            </th>
                                                            <th>
                                                                {Trans.setting.checklists}
                                                            </th>
                                                            <th>
                                                                {Trans.task.assign}
                                                            </th>
                                                            <th>
                                                                {Trans.setting.action}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {autoAssignsList.map((autoAssign, j) =>
                                                        <tr key={j}>
                                                            <td className={`align-middle`}>
                                                                <Input name="stage_name" placeholder={Trans.setting.stage} type="text" value={autoAssign.stage_name} readOnly={true} onChange={(e) => handleChangeAutoAssign(e, j)} />
                                                            </td>
                                                            <td className={`align-middle`}>
                                                                <Input name="content" placeholder={Trans.setting.stage} type="text" value={autoAssign.content || ''} readOnly={true} onChange={(e) => handleChangeAutoAssign(e, j)} />
                                                            </td>
                                                            <td className={`align-middle`}>
                                                                <Input name="described" placeholder={Trans.task.assign} type="select" value={autoAssign.described || ''} onChange={(e) => handleChangeAutoAssign(e, j)}>
                                                                    <option value="">
                                                                        {Trans.task.assign}
                                                                    </option>
                                                                {$this.wms_class.comStage.state.lstMembers.map((member, k) =>
                                                                    <option key={k} value={member.uid.toString()}>
                                                                        {member.fullname}
                                                                    </option>
                                                                )}
                                                                </Input>
                                                            </td>
                                                            <td className={`align-middle`}>
                                                                <div className={`d-flex align-items-center`}>
                                                                    <MdOutlineSave title={Trans.setting.saveassign} size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1 ${autoAssign.identifier && autoAssign.value ? '' : 'invisible'}`} onClick={() => onSubmitAutoAssign(_widget.identifier, autoAssign)} />
                                                                    <MdOutlineClear title={Trans.setting.deleteassign} size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1`}  onClick={() => onDeleteAutoAssign(_widget.identifier, autoAssign.id, j)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            }
                                        </Collapse>) : ''}
                                        {_widget.oncrud == 'yes' && _widget.identifier == 'auto_status' ? (
                                        <Collapse className={`w-100`} isOpen={widget.isOpen == 1}>
                                            <div className={`d-flex my-3`}>
                                                <Row className="row-cols-lg-auto g-3 align-items-center">
                                                    {(autoAssignsList.length == 0 || autoAssignsList[0].id) && (
                                                    <Col style={{width: 'var(--pix-w-270)'}}>
                                                        <Select options={_.map(Object.assign([], $this.wms_class.comStage.state.objData.columns), function(p) {
                                                            p.label = p.name;
                                                            p.value = p.id;
                                                            return p;
                                                        })} onChange={(stage) => onSelectedStage(stage, _widget.identifier)} placeholder={Trans.setting.select_stage}/>
                                                    </Col>)}
                                                </Row>
                                            </div>
                                            {(autoAssignsList.length > 0) && 
                                                <Table striped className={`mb-3`}>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                {Trans.setting.stage}
                                                            </th>
                                                            <th>
                                                                {Trans.setting.result}
                                                            </th>
                                                            <th>
                                                                {Trans.setting.described}
                                                            </th>
                                                            <th>
                                                                {Trans.setting.action}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {autoAssignsList.map((autoAssign, j) =>
                                                        <tr key={j}>
                                                            <td className={`align-middle`}>
                                                                <Input name="stage_name" placeholder={Trans.setting.stage} type="text" value={autoAssign.stage_name} readOnly={true} onChange={(e) => handleChangeAutoAssign(e, j)} />
                                                            </td>
                                                            <td className={`align-middle`}>
                                                                <Input name="value" placeholder={Trans.setting.result} type="select" value={autoAssign.value} onChange={(e) => handleChangeAutoAssign(e, j)}>
                                                                    <option value="">
                                                                        {Trans.setting.result}
                                                                    </option>
                                                                {_.filter($this.wms_class.comStage.state.lstConstants, function(o) { return o.mode == 'result';}).map((constant, k) =>
                                                                    <option key={k} value={constant.identifier}>
                                                                        {constant.label}
                                                                    </option>
                                                                )}
                                                                </Input>
                                                            </td>
                                                            <td className={`align-middle`}>
                                                                <Input name="described" placeholder={Trans.setting.described} type="text" value={autoAssign.described || ''} onChange={(e) => handleChangeAutoAssign(e, j)} />
                                                            </td>
                                                            <td className={`align-middle`}>
                                                                <div className={`d-flex align-items-center`}>
                                                                    <MdOutlineSave title={Trans.setting.saveassign} size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1 ${autoAssign.identifier && autoAssign.value ? '' : 'invisible'}`} onClick={() => onSubmitAutoAssign(_widget.identifier, autoAssign)} />
                                                                    <MdOutlineClear title={Trans.setting.deleteassign} size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1`}  onClick={() => onDeleteAutoAssign(_widget.identifier, autoAssign.id, j)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            }
                                        </Collapse>) : ''}
                                        {_widget.oncrud == 'yes' && _widget.identifier == 'auto_extend' ? (
                                        <Collapse className={`w-100`} isOpen={widget.isOpen == 1}>
                                            <div className={`d-flex my-3`}>
                                                <Row className="row-cols-lg-auto g-3 align-items-center">
                                                    {(autoAssignsList.length == 0 || autoAssignsList[0].id) && (
                                                    <Col style={{width: 'var(--pix-w-270)'}}>
                                                        <Select options={_.map(Object.assign([], $this.wms_class.comStage.state.objData.columns), function(p) {
                                                            p.label = p.name;
                                                            p.value = p.id;
                                                            return p;
                                                        })} onChange={(stage) => onSelectedStage(stage, _widget.identifier)} placeholder={Trans.setting.select_stage}/>
                                                    </Col>)}
                                                </Row>
                                            </div>
                                            {(autoAssignsList.length > 0) && 
                                                <Table striped className={`mb-3`}>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                {Trans.setting.stage}
                                                            </th>
                                                            <th>
                                                                {Trans.setting.value}
                                                            </th>
                                                            <th>
                                                                {Trans.setting.described}
                                                            </th>
                                                            <th>
                                                                {Trans.setting.action}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {autoAssignsList.map((autoAssign, j) =>
                                                        <tr key={j}>
                                                            <td className={`align-middle`}>
                                                                <Input name="stage_name" placeholder={Trans.setting.stage} type="text" value={autoAssign.stage_name} readOnly={true} onChange={(e) => handleChangeAutoAssign(e, j)} />
                                                            </td>
                                                            <td className={`align-middle`}>
                                                                <Input name="value" placeholder={Trans.setting.value} type="number" min={0} max={100} value={autoAssign.value || 0} onChange={(e) => handleChangeAutoAssign(e, j)} />
                                                            </td>
                                                            <td className={`align-middle`}>
                                                                <Input name="described" placeholder={Trans.setting.described} type="text" value={autoAssign.described || ''} onChange={(e) => handleChangeAutoAssign(e, j)} />
                                                            </td>
                                                            <td className={`align-middle`}>
                                                                <div className={`d-flex align-items-center`}>
                                                                    <MdOutlineSave title={Trans.setting.saveassign} size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1 ${autoAssign.identifier && autoAssign.value ? '' : 'invisible'}`} onClick={() => onSubmitAutoAssign(_widget.identifier, autoAssign)} />
                                                                    <MdOutlineClear title={Trans.setting.deleteassign} size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1`}  onClick={() => onDeleteAutoAssign(_widget.identifier, autoAssign.id, j)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            }
                                        </Collapse>) : ''}
                                        {_widget.oncrud == 'yes' && _widget.identifier == 'notify_data' ? (
                                        <Collapse className={`w-100`} isOpen={widget.isOpen == 1}>
                                            <div className={`d-flex my-3`}>
                                                <Row className="row-cols-lg-auto g-3 align-items-center">
                                                    {$this.wms_class.comStage.state.objData.profiles.role === 'Owner' && (widgetExtendsList.length == 0 || widgetExtendsList[0].id) && (<Col>
                                                        <MdOutlineAdd title={Trans.setting.addwidget} size="30" className={`bg-light hover-effect-border rounded-circle p-1 text-muted`} onClick={() => addWigetExtend(_widget.identifier)} />
                                                    </Col>)}
                                                </Row>
                                            </div>
                                            {(widgetExtendsList.length > 0) && 
                                                <Table striped className={`mb-3`}>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                {Trans.setting.name}
                                                            </th>
                                                            <th>
                                                                {Trans.setting.key}
                                                            </th>
                                                            <th>
                                                                {Trans.setting.type}
                                                            </th>
                                                            <th>
                                                                {Trans.setting.color}
                                                            </th>
                                                            <th>
                                                                {Trans.setting.icon}
                                                            </th>
                                                            <th style={{width: '35px'}}>
                                                                &nbsp;
                                                            </th>
                                                            <th>
                                                                {Trans.setting.action}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {widgetExtendsList.map((widgetExtend, j) => {
                                                            let icoid = parseInt(widgetExtend.icoid);
                                                            let bgrid = parseInt(widgetExtend.bgrid);
                                                            let _iconExtend = _.find(_listIcons, { id: icoid});
                                                            let _bgcolorExtend = Util.indexColorbyId($this.wms_class.comStage.state.lstColors, bgrid);
                                                            let _txtcolorExtend = _bgcolorExtend;
                                                            let _rgbcolorExtend = Util.hex2Rgb(_bgcolorExtend, 10);
                                                            _bgcolorExtend = _rgbcolorExtend ? _rgbcolorExtend.rgb.replace(/rgb/i, "rgba") : _bgcolorExtend.replace(/\)/i,',0.1)');
                                                            return (
                                                            <tr key={j}>
                                                                <td className={`align-middle`}>
                                                                    <Input name="name" placeholder={Trans.setting.name} type="text" value={widgetExtend.name} onChange={(e) => handleChangeWidget(e, j)} readOnly={$this.wms_class.comStage.state.objData.profiles.role !== 'Owner'} />
                                                                </td>
                                                                <td className={`align-middle`}>
                                                                    <Input name="key" placeholder={Trans.setting.key} type="text" value={widgetExtend.key} onChange={(e) => handleChangeWidget(e, j)} readOnly={$this.wms_class.comStage.state.objData.profiles.role !== 'Owner'}/>
                                                                </td>
                                                                <td className={`align-middle`}>
                                                                    <Input name="custom_fields" placeholder={Trans.setting.fields} type="text" value={widgetExtend.custom_fields} onChange={(e) => handleChangeWidget(e, j)} readOnly={$this.wms_class.comStage.state.objData.profiles.role !== 'Owner'}/>
                                                                </td>
                                                                <td className={`align-middle`}>
                                                                    <Input name="bgrid" type="select" value={widgetExtend.bgrid} onChange={(e) => handleChangeWidget(e, j)} readOnly={$this.wms_class.comStage.state.objData.profiles.role === 'Member'}>
                                                                        {$this.wms_class.comStage.state.lstColors.length > 0 && $this.wms_class.comStage.state.lstColors.map((color, k) =>
                                                                        <option key={k} value={color.id}>
                                                                            {color.name}
                                                                        </option>
                                                                        )}
                                                                    </Input>
                                                                </td>
                                                                <td className={`align-middle`}>
                                                                    <Input name="icoid" type="select" value={widgetExtend.icoid} onChange={(e) => handleChangeWidget(e, j)} readOnly={$this.wms_class.comStage.state.objData.profiles.role === 'Member'}>
                                                                        {_listIcons.length > 0 && _listIcons.map((icon, l) =>
                                                                        <option key={l} value={icon.id}>
                                                                            {icon.name}
                                                                        </option>
                                                                        )}
                                                                    </Input>
                                                                </td>
                                                                <td className={`align-middle align-items-center`}>
                                                                    <div className={`border-0 rounded-0 opacity-75 fw-bolder`}
                                                                        style={{backgroundColor: _bgcolorExtend, color: _txtcolorExtend, width: '32px'}}>
                                                                        {_iconExtend && (<SVG src={_iconExtend.path}
                                                                            width={32}
                                                                            height={32}
                                                                            className={`me-auto d-flex align-self-center`}
                                                                        />)}
                                                                    </div>
                                                                </td>
                                                                <td className={`align-middle`}>
                                                                    <div className={`d-flex align-items-center`}>
                                                                        <MdOutlineSave title={Trans.setting.savewidget} size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1 ${widgetExtend.name && widgetExtend.key ? '' : 'invisible'}`} onClick={() => onSubmitWidgetExtend(_widget.identifier, widgetExtend)} />
                                                                        <MdOutlineClear title={Trans.setting.deletewidget} size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1 ${$this.wms_class.comStage.state.objData.profiles.role !== 'Owner' ? 'invisible' : ''}`}  onClick={() => onDeleteWidgetExtend(_widget.identifier, widgetExtend.id, j)} />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            )}
                                                        )}
                                                    </tbody>
                                                </Table>
                                            }
                                        </Collapse>) : ''}
                                    </InputGroup>) : ''
                                }
                                )}
                            </ListGroup>
                        </CardBody>
                    </TabPane>
                    )}
                </TabContent>
            </ModalBody>
        </Modal>
    );
};

const CustomFields = props => {
    let fields = _.map(Object.assign([], props.fields), function(p) {
        p.label = p.name;
        p.value = p.id;
        return p;
    });
    let array_fields = props.widget.custom_fields ? props.widget.custom_fields.split(',') : [];
    let custom_fields = _.filter(fields, function(item){
        return _.includes(array_fields, item.id+'');
    });
    return (
        <div style={{minWidth: 'var(--pix-w-200)', maxWidth: 'var(--pix-w-270)'}}>
            <Select
                isMulti
                value={custom_fields}
                options={fields}
                onChange={(e) => props.onSelectedCustomFields(e, props.j)}
                isClearable={false}
                className={`cuson-tags-value`}
            />
        </div>
    )
};
export { WorkflowSetting, CustomFields };