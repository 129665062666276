// import npm packge
import React, { useState } from "react";
import { CardBody, CardImg } from 'reactstrap';
// import service api
//
// import core class
import Util from '../../core/util';
// import com page
//
// import modal tool
//
// import style media
function AvatarCom(props) {
	const _name_str = Util.convertNameToText(props.member.fullname);
	const width = (props.width ? props.width : 90) + 'px';
	const height = (props.height ? props.height : 90) + 'px';
    const lineHeight = ((props.height ? props.height : 90) - (props.unsetborder ? 0 : 4)) + 'px';
	return (
        <div className={`text-center d-flex align-self-center ${props.className ? props.className : ''}`} title={props.title ? props.title : ''}>
            {props.member.avatar ? (
                <CardBody className={`py-0 px-0`}>
                    <CardImg
                        alt="Your profile"
                        src={props.member.avatar}
                        style={{
                            width: width,
                            height: height
                        }}
                        className={`rounded-circle border display-name-profile ${props.unsetborder ? '' : 'border-info border-3'}`}
                        />
                </CardBody>
            ) : (
                <p className={`rounded-circle bg-light border small display-name-profile ${props.unsetborder ? '' : 'border-info border-3'}`} style={{
                    width: width,
                    height: height,
                    lineHeight: lineHeight,
                    fontSize: ((props.width ? props.width : 90) / 2.75) + 'px'
                }}>{_name_str}</p>
            )}
        </div>
    );
};

export { AvatarCom };