import axios from 'axios';
import Util from '../core/util';

export default class ServiceWchlist {
    static instance = ServiceWchlist.instance || new ServiceWchlist();

    pagingWchlist = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/pagingWchlist`, data, Util.getHeaders());
    }
    
    storeWchlist = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/storeWchlist`, data, Util.getHeaders());
    }

    updateWchlist = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/updateWchlist/${id}`, data, Util.getHeaders());
    }

    deleteWchlist = (id) =>{
        return axios.delete(`${Util.getAPIPrefix()}/deleteWchlist/${id}`, Util.getHeaders());
    }
}