// import npm packge
import React, { useState, useEffect } from 'react';
import {
    Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Form, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, //
    Table, Badge, InputGroup, InputGroupText
} from 'reactstrap';
import Select from 'react-select';
import _ from 'lodash';
// import serivce api
import ServiceSinfo  from '../../services/ServiceSinfo';
import ServiceWstep  from '../../services/ServiceWstep';
// import core class
import Util from '../../core/util';
import { subscribe, unsubscribe } from "../../core/events";
// import com page
//
// import modal tool
//
// import style media
import SVG from 'react-inlinesvg';
import { MdOutlineAdd, MdOutlineSave, MdOutlineClear } from "react-icons/md";
import './default.css';
function WorkflowStage($this) {
    useEffect(() => {
        subscribe("pagingSinfo", () => getSinfo());
        return () => {
            unsubscribe("pagingSinfo", () => console.log('unsubscribe pagingSinfo'));
        }
    }, [Sinfo]);

    const [modal, setModal] = useState(false);
    const [selectedWstep, setSelectedWstep] = useState(null);
    const [wstepListFull, setWstepListFull] = useState([]);
    const [wstepList, setWstepList] = useState([]);
    const [Sinfo, setSinfo] = useState([]);
    const [listColors, setListColors] = useState([]);
    const [listIcons, setListIcons] = useState([]);
    const [modeLink, setMode] = useState(true);
    const newStep = {mode: 'core', parent_uid: null, identifier: 'addstep'};

    $this.header_self.toggleModalSinfo = () => {
        if (!modal) {
            setListColors($this.wms_class.comStage.state.lstColors)
            setListIcons($this.wms_class.comStage.state.lstIcons)
            getSinfo(false);
        }
        setModal(!modal)
    };
    //wsinfo
    const getSinfo = (overwriteroot = true) => {
        let _objData = $this.wms_class.comStage.state.objData;
        ServiceWstep.instance.pagingWstep({wflow_id: _objData.workfolows.id}).then(({ data }) => {
            if (data.status) {
                let _wteps = _.filter(data.data, function(o) { return !o.parent_uid; });
                setWstepListFull(prev => ([...[], ..._wteps]));
                getListWstep(_wteps, modeLink);
            } else {
                Util.toast(data.error, 'error')
            }
        })
        let filter = Object.assign({}, _objData.filter);
        let objPaging = {
            wflow_id: _objData.workfolows.id,
            search: filter.inputsearch,
            status: filter.status,
            step: filter.stage_step,
            result: filter.checklist_result,
            assign: filter.assign,
            watches: filter.watches,
            tags: filter.tags,
            dateline: filter.dateline,
            date: filter.date,
        }
        $this.wms_class.onsubmit = false;
        $this.wms_class.trackInprocess();
        ServiceSinfo.instance.pagingSinfo(objPaging).then(({ data }) => {
            if (data.status) {
                setSinfo(prev => ([...[], ...data.stages]));
                $this.wms_class.onsubmit = true;
                $this.wms_class.trackInprocess();
                if (overwriteroot) {
                    return overWriteRoot(data.stages, data.data);
                }
            } else {
                Util.toast(data.error, 'error');
            }
        });
    }
    const overWriteRoot = (_stages, stages) => {
        let comStage = $this.wms_class.comStage;
        let _objData = comStage.state.objData;
        let columns = Object.assign([], _objData.columns);
        if (stages.length != columns.length) {
            let _activeStages = _.map(stages, 'step');
            let _columns_ = Object.assign([], _.filter(columns, function(o) { return _activeStages.indexOf(o.step) > -1; }));
            _.forEach(stages, function(o, i) { 
                let findIndex = _.findIndex(_columns_, { step: o.step });
                if (findIndex > -1) {
                    _columns_[findIndex] = o;
                } else {
                    _columns_.push(o);
                }
            });
            let _columns = _.orderBy(_columns_, 'index', 'asc');
            _objData.stages = _stages;
            _objData.columns = _columns;
            comStage.setState({objData: _objData});
            $this.wms_class.react.setState({wms_class: $this.wms_class.comStage});
            $this.wms_class.clearFilter();
        } else {
            let inprocess = 0; let review = 0; let complete = 0; let total = 0;
            _.forEach(columns, function(o, i) { 
                let findIndex = _.findIndex(stages, { step: o.step });
                if (findIndex > -1) {
                    o.index = stages[findIndex]['index'];
                    o.name = stages[findIndex]['name'];
                    o.image = stages[findIndex]['image'];
                    o.background = stages[findIndex]['background'];
                    o.inprocess = stages[findIndex]['inprocess'];
                    o.review = stages[findIndex]['review'];
                    o.complete = stages[findIndex]['complete'];
                    o.total = stages[findIndex]['total'];
                }
                inprocess += parseInt(o.inprocess);
                review += parseInt(o.review);
                complete += parseInt(o.complete);
                total += parseInt(o.total);
            });
            _objData.inprocess = parseInt(inprocess);
            _objData.review = parseInt(review);
            _objData.complete = parseInt(complete);
            _objData.total = parseInt(total);
            let _columns = _.orderBy(columns, 'index', 'asc');
            _objData.stages = _stages;
            _objData.columns = _columns;
            comStage.setState({objData: _objData});
            $this.wms_class.react.setState({wms_class: $this.wms_class.comStage});
        }
    }
    const onSelectedWstep = (wstep) => {
        let sinfo = [...Sinfo];
        const random_color = _.sample($this.wms_class.comStage.state.lstColors);
        const lsteachcate = Util.listIconBy($this.wms_class.comStage.state.lstIcons);
        const random_icon = _.sample(lsteachcate);
        sinfo.push({workflow_id: $this.wms_class.comStage.state.objData.workfolows.id, 
            step: wstep.identifier, 
            name: wstep.name, 
            status: 'active', 
            sort_uid: 'asc', 
            background: random_color ? random_color.hexcode : '#cccccc', 
            image: random_icon ? random_icon.path : '', 
            bgrid: random_color ? random_color.id : null, 
            icoid: random_icon ? random_icon.id : null, 
            index: sinfo[sinfo.length - 1] ? (sinfo[sinfo.length - 1]['index'] + 1) : 1,
        });
        setSinfo(prev => ([...[], ...sinfo]));
        setSelectedWstep(null);
    }
    const changeModeLink = async() => {
        await setMode(!modeLink);
        setTimeout(async function(){
            await getListWstep(wstepListFull, !modeLink);
        }, 0);
    }
    const getListWstep = (steps, isLink) => {
        let _new_stage = Object.assign({}, newStep);
        _new_stage.label = $this.wms_class.comStage.state.Trans.setting.addstage;
        _new_stage.name = '';
        let _wteps = _.filter(steps, function(o) { return o.mode == (isLink ? 'link' : 'core'); });
        let new_wsteps = !isLink ? _.concat(_wteps, [_new_stage]) : _wteps;
        setWstepList(prev => ([...[], ...new_wsteps]));
    }
    const handleChangeSinfo = (event, i) => {
        event.preventDefault();
        const {name, value} = event.target;
        let sinfo = [...Sinfo];
        sinfo[i][name] = value;
        setSinfo(prev => ([...[], ...sinfo]));
    }
    const handleCheck = async (e, sinfo, i) => {
        e.preventDefault();
        Sinfo[i].status = e.target.checked ? 'active' : 'deactive';
        let _sinfos = Object.assign([], Sinfo);
        await setSinfo([]);
        setSinfo(prev => ([...[], ..._sinfos]));
        let wms_class = $this.wms_class;
        // if (!wms_class.onsubmit) {
        //     Util.toast($this.wms_class.comStage.state.Trans.toast.isLoading, 'warning');
        //     return;
        // }
        wms_class.prevent_continuity = true;
        wms_class.onsubmit = false;
        wms_class.trackInprocess();
        sinfo.status = _sinfos[i].status;
        ServiceSinfo.instance.updateSinfo(sinfo.id, sinfo)
        .then(({ data }) => {
            if (data.status) {
                Util.toast(data.success)
            } else {
                Util.toast(data.error, 'error')
            }
            // getSinfo();
            wms_class.prevent_continuity = false;
            wms_class.onsubmit = true;
            wms_class.trackInprocess();
        })
    }
    const onSubmitSinfo = (sinfo) => {
        $this.wms_class.prevent_continuity = true;
        $this.wms_class.onsubmit = false;
        $this.wms_class.trackInprocess();
        if (sinfo.id) {
            const id = sinfo.id;
            delete sinfo.id;
            ServiceSinfo.instance.updateSinfo(id, sinfo)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                // getSinfo();
                $this.wms_class.prevent_continuity = false;
                $this.wms_class.onsubmit = true;
                $this.wms_class.trackInprocess();
            })
        } else {
            $this.wms_class.prevent_continuity = true;
            $this.wms_class.onsubmit = false;
            $this.wms_class.trackInprocess();
            ServiceSinfo.instance.storeSinfo(sinfo)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                // getSinfo();
                $this.wms_class.prevent_continuity = false;
                $this.wms_class.onsubmit = true;
                $this.wms_class.trackInprocess();
            })
        }
    }
    const onDeleteSinfo = (id, i) => {
        if (!id) {
            setSinfo([
                ...Sinfo.slice(0, i),
                ...Sinfo.slice(i + 1)
            ]);
        } else {
            $this.wms_class.prevent_continuity = true;
            $this.wms_class.onsubmit = false;
            $this.wms_class.trackInprocess();
            ServiceSinfo.instance.deleteSinfo(id)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                // getSinfo();
                $this.wms_class.prevent_continuity = false;
                $this.wms_class.onsubmit = true;
                $this.wms_class.trackInprocess();
            })
        }   
    }

    return (
        <Modal isOpen={modal} toggle={$this.header_self.toggleModalSinfo} {...$this} size={`lg`} scrollable={true}
            >
            <ModalHeader toggle={$this.header_self.toggleModalSinfo}
                className={``}>
                {$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.setting.stage : 'Workflow Stage'}
            </ModalHeader>
            <ModalBody className={`pb-0`}>
                <div className={`d-flex my-3`}>
                    <Col>
                        <FormGroup switch>
                            <Input type="switch" role="switch" defaultChecked={modeLink} onClick={() => { changeModeLink() }} disabled={Sinfo.length && !Sinfo[0].id} />
                            <Label check>{$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.setting.mode_link : 'Mode Link'}</Label>
                        </FormGroup>
                    </Col>
                    <Col style={{width: 'var(--pix-w-270)'}}>
                        <Select options={wstepList} value={selectedWstep} onChange={onSelectedWstep} placeholder={$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.setting.select_wstep : 'Select Wstep'}/>
                    </Col>
                </div>
                <Table striped className={`mb-3`}>
                    <thead>
                        <tr>
                            <th>
                                {$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.setting.icon : 'Icon'}
                            </th>
                            <th>
                                {$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.setting.index : 'Index'}
                            </th>
                            <th>
                                {$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.setting.name : 'Name'}
                            </th>
                            <th>
                                {$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.setting.action : 'Action'}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {Sinfo.length > 0 && Sinfo.map((sinfo, i) =>
                        <tr key={i}>
                            <td className={`align-middle`}>
                                <Badge color={`skip`} title={sinfo.name} 
                                    className={`text-white border-0`}
                                    style={{ backgroundColor: sinfo.background }}
                                >
                                    <SVG src={sinfo.image}
                                        width={24}
                                        height={24}
                                        title={sinfo.name}
                                        className={`text-white`}
                                    />
                                </Badge>
                            </td>
                            <td className={`align-middle`}>
                                <Input name="index" placeholder="Index" type="number" value={sinfo.index} onChange={(e) => handleChangeSinfo(e, i)} />
                            </td>
                            <td className={`align-middle`}>
                                <Input name="name" placeholder="Name" type="text" value={sinfo.name} onChange={(e) => handleChangeSinfo(e, i)} />
                            </td>
                            <td className={`align-middle`}>
                                <div className={`d-flex align-items-center`}>
                                    <MdOutlineSave title={$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.setting.savestage : 'Save Stage'} size="30"className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1 ${sinfo.name ? '' : 'invisible'}`} onClick={() => onSubmitSinfo(sinfo)} />
                                    <InputGroup className={`rounded-circle mx-1 p-1 item-do-list hover-effect ${sinfo.id ? '' : 'invisible'}`}
                                        style={{cursor: 'initial', width: 'var(--per-w-24)' }}>
                                        <label  className={`align-self-start checkcontainer`} style={{cursor: 'pointer'}}>
                                            <Input onChange={(e) => handleCheck(e, sinfo, i)}
                                                addon
                                                aria-label="Check Completed"
                                                type="checkbox"
                                                checked={sinfo.status == 'active'}
                                            />
                                            <InputGroupText className="checkmark"></InputGroupText>
                                        </label>
                                    </InputGroup>
                                    <MdOutlineClear title={$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.setting.deletestage : 'Delete Stage'} size="30"className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1`} onClick={() => onDeleteSinfo(sinfo.id, i)} />
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </ModalBody>
        </Modal>
    );
};
export { WorkflowStage };