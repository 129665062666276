// import npm packge
import React, { } from 'react';
import _ from 'lodash';
import $ from 'jquery';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Pusher from "pusher-js";
// import service api
import ServiceAuth  from '../services/ServiceAuth';
// import core class
import { PREFIX_LOCAL } from '../core/constant';
import Util from '../core/util';
import wmsCls from "../core/wmsCls";
// import com page
import {Phome} from "../pages/home";
import {Paccess} from "../pages/access";
// import modal tool
//
// import style media
import "./default.css";
// coding
const wms_class = new wmsCls();
export default class Pages extends React.Component {
    constructor(props) {
        super(props);
        wms_class.initial(this);
        wms_class.unlockOnSubmit();
        this.state = {
            wms_class: wms_class,
        }
        this.checkLogin = this.checkLogin.bind(this);
    };
    componentWillMount() {
        const access_token = JSON.parse(localStorage.getItem(PREFIX_LOCAL + 'access_token'));
        if (window.location.pathname == '/access' && access_token) {
            window.location.assign('/');
            return;
        }
    };
    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
    }
    componentDidMount() {
        let _self = this;
        if (window.location.pathname == '/access') {
            return;
        }
        wms_class.checkLogin(function($arg, $status){
            if($arg){
                wms_class.getLstColors();
                wms_class.getLstIcons();
                wms_class.getWconfigs();
                wms_class.getLstWinfos(function($argwif, $statuswif){
                    if($argwif){
                        wms_class.getWidgetpacks();
                        wms_class.getLstTags($argwif.state.objData.workfolows.id);
                        wms_class.getLstMembers($argwif.state.objData.workfolows.id, function($argmb, $statusmb){
                            if($argmb){
                                _self.setState({wms_class: $argmb});
                                wms_class.prevent_continuity = true;
                                wms_class.onsubmit = false;
                                wms_class.trackInprocess();
                                wms_class.getLstStages($argwif.state.objData.workfolows.id, function($argstg, $lastest){
                                    if($argstg){
                                        _self.setState({wms_class: $argstg});
                                        if ($lastest){
                                            wms_class.prevent_continuity = false;
                                            wms_class.onsubmit = true;
                                            wms_class.trackInprocess();
                                        }
                                    }
                                    else {
                                        // alert($statusst.error);
                                    }
                                });
                            }
                            else {
                                // alert($statusmb.error);
                            }
                        });
                        // _self.setState({wms_class: $argwif});
                    }
                    else {
                        // alert($statuswif.error);
                    }
                });
                // _self.setState({wms_class: $arg});
                var intervalId = setInterval(_self.checkLogin, 60 * 5 * 1000);
                // store intervalId in the state so it can be accessed later:
                _self.setState({intervalId: intervalId});
                $(document).on('click', function() {
                    _self.checkLogin();
                });
                document.addEventListener("paste", (event) => {
                    // Define func switch upload from util or wmsCls
                    // switch(true) {
                    //     case $(event.target).is('input'):
                    //     case $(event.target).is('textarea'):
                    //     case $(event.target).is('select'):
                    //         break;
                    //     default:
                    //         wms_class.handleSharePaste(event);
                    //         break;
                    // }
                    wms_class.handleSharePaste(event);
                });
                _self.pusherEvent($arg.state.objData.workspace.pusher_key, $arg.state.objData.workspace.pusher_cluster);
            }
            else {
                _self.setState({wms_class: $status});
                // alert($status.error);
            }
        });
    };
    checkLogin() {
        let _self = this;
        if (_self.state.wms_class && _self.state.wms_class.state && _self.state.wms_class.state && !_self.state.wms_class.state.isLogin){
            return false;
        }
        ServiceAuth.instance.checklogin().then(async ({ data }) => {
            if (data.status) {
                // console.log('hasLogin')
            } else {
                await Util.clearLocalData();
                window.location.assign('/access');
            }
        }).catch(async (e) => {
            await Util.clearLocalData();
            window.location.assign('/access');
        });
    }
    pusherEvent(PUSHER_API_KEY, PUSHER_CLUSTER) {
        var pusher = new Pusher(`${PUSHER_API_KEY}`, {
            cluster: `${PUSHER_CLUSTER}`,
        });
        var channel = pusher.subscribe("notify-store");
        channel.bind("notify-event", (data: any) => {
            wms_class.handleNotifyEvent(data);
        });
    }
    /*etc https://viblo.asia/p/vong-doi-cua-component-trong-react-gGJ59XaJlX2*/
    render() {
        if (this.state.wms_class.state && !this.state.wms_class.state.isLogin && window.location.pathname != '/access') {
            Util.clearLocalData();
            return <Redirect to={'/access'} />;
        }
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" render={(props) => ( <Phome {...props} data={this.state.wms_class}/> )}/>
                    <Route exact path="/access" render={(props) => ( <Paccess {...props} data={this.state.wms_class}/> )}/>
                </Switch>
            </BrowserRouter>
        );
    };
}