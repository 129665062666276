// import npm packge
import React, { useState, useRef, useEffect, useCallback } from 'react';
import $ from 'jquery';
import _ from 'lodash';
import * as moment from "moment";
import { Form, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
// import service api
import ServiceTinfo  from '../../services/ServiceTinfo';  
// import core class
import Util from '../../core/util';
import { subscribe, unsubscribe } from "../../core/events";
import { CHECK_OUT_TIME, DURATION_TOAST } from '../../core/constant';
// import com page
import { colourStyles, CustomSelectOption, CustomSelectValue } from '../../components/Stage/Task';
// import modal tool
//
// import style media
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './default.css'
// coding
function AddTask($this) {
	const [modal, setModal] = useState(false);
	const [Tinfo, setTinfo] = useState({workflow_id: 0, mode: 'core', status: 'active', name: '', content: '', described: '', step: '', result: '', assign: null});
	const [activeStage, setActiveStage] = useState({});
	const [members, setMembers] = useState([]);
	const [selectedOption, setSelectedOption] = useState(null);
	const [sampleEditorContent, setSampleEditorContent] = useState(() =>
    	EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft('').contentBlocks))
    );
	//
	useEffect(() => {
        if (modal == true) {
			setActiveStage($this.wms_class.comStage.state.objData.columns[0]);
        	let _tinfo = {...Tinfo};
	    	_tinfo['step'] = $this.wms_class.comStage.state.objData.columns[0].step;
	    	_tinfo['workflow_id'] = $this.wms_class.comStage.state.objData.workfolows.id;
	    	_tinfo['result'] = 'pending';
			setTinfo(prevTinfo => ({...{}, ..._tinfo}));
        }
    }, [modal]);
	$this.react.toggleModalAddTask = () => {
        setModal(!modal);
    };
    let changeStage = (stage) => {
		let _activeStage = _.find($this.wms_class.comStage.state.objData.columns, {step: stage.step});
		setActiveStage(_activeStage);
		let _tinfo = {...Tinfo};
    	_tinfo['step'] = stage.step;
		setTinfo(prevTinfo => ({...{}, ..._tinfo}));
	}
	let onSelectedOption = async(member) => {
		await setSelectedOption(null);
		let _assign = _.find($this.wms_class.comStage.state.lstMembers, {uid: member ? member.uid : null});
		setSelectedOption(_assign ? _assign : null);
        let _tinfo = {...Tinfo};
    	_tinfo['assign'] = member ? member.uid : null;
		setTinfo(prevTinfo => ({...{}, ..._tinfo}));
    }
	let handleChange = (event) => {
    	event.preventDefault();
    	const {name, value} = event.target;
    	let _tinfo = {...Tinfo};
    	_tinfo[name] = value;
		setTinfo(prevTinfo => ({...{}, ..._tinfo}));
    }
	let outFocusDescribed = (event, editorState) => {
    	event.preventDefault();
        let _content = draftToHtml(convertToRaw(editorState.getCurrentContent())).replace(/\n$/,"");
		let _tinfo = {...Tinfo};
    	_tinfo['described'] = _content;
		setTinfo(prevTinfo => ({...{}, ..._tinfo}));
    }
    let onSubmitTask = (e) => {
    	e.preventDefault();
    	$this.wms_class.onsubmit = false;
        $this.wms_class.trackInprocess();
    	const toast_loading = Util.toast($this.wms_class.comStage.state.Trans.toast.textLoading, 'loading');
    	ServiceTinfo.instance.storeTinfo(Tinfo)
        .then(({ data }) => {
            Util.toast($this.wms_class.comStage.state.Trans.toast.textLoaded, 'update', toast_loading);
            if (data.status) {
                Util.toast(data.success);
                let _tinfo = {workflow_id: 0, mode: 'core', status: 'active', name: '', content: '', described: '', step: '', result: '', assign: null};
			    setTinfo(prevTinfo => ({...{}, ..._tinfo}));
			    $this.react.toggleModalAddTask();
            } else {
                Util.toast(data.error, 'error')
            }
            $this.wms_class.onsubmit = true;
        	$this.wms_class.trackInprocess();
        })
    }
  	return (
		<Modal isOpen={modal} toggle={$this.react.toggleModalAddTask} {...$this} size={`lg`} scrollable={true}
			>
		    <ModalHeader toggle={$this.react.toggleModalAddTask}
		    	className={`modal-maintask`}
		    	tag="div">
				{$this.wms_class && $this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.objData && activeStage && (
				<UncontrolledDropdown>
				    <DropdownToggle color="skip" caret className={`btn d-flex align-items-center text-white h-100`}
				    style={{backgroundColor: activeStage.background}} >
				        {activeStage.name}
				    </DropdownToggle>
				    <DropdownMenu end flip className={`border-0 py-0 mt-2`}>
				    	{$this.wms_class.comStage.state.objData.columns && $this.wms_class.comStage.state.objData.columns.map((stage, i) => { 
                            return stage.step != activeStage.step ? <DropdownItem key={i} className={`d-flex align-items-center hover-opacity-5per text-white`}
                            style={{backgroundColor: stage.background}} onClick={() => changeStage(stage)}>
                            	{stage.name}
                        	</DropdownItem> : ''
                        }
                        )}
				    </DropdownMenu >
				</UncontrolledDropdown>)}
				{/*{$this.wms_class && $this.wms_class.comStage && $this.wms_class.comStage.state && (
				<div
                    className={`bg-light hover-effect-border text-muted px-0 me-auto`}
                    title={$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.assign : 'Assign Employee'} style={{minWidth: 'var(--pix-w-240)'}}
                >
                	<Select
                		value={selectedOption}
	                	options={$this.wms_class.comStage.state.lstMembers}
	                	components={{
					        Option: CustomSelectOption,
					        SingleValue: CustomSelectValue
					    }}
					    isClearable={selectedOption ? true : false}
					    onChange={onSelectedOption}
					    className={`cuson-selects-value`}
				    />
                </div>)}*/}
		    </ModalHeader>
		    <Form onSubmit={onSubmitTask} className={`mb-0`} autoComplete={'off'}>
			    <ModalBody
			    	className={`modal-maintask work-scroller d-flex gap-3 mb-0`}>
				    <div
	                    className={`maintask-left h-100 w-100`}
	                >
	                	<Input
	                		name="name"
	                		style={{cursor: 'inherit'}}
	                		className={`border-0 hover-effect-border fs-5 py-0 bg-light`}
	                		value={Tinfo.name}
	                        onChange={handleChange}
	                		placeholder="Title & Name"
	                		required={true}
	  					/>
	  					<Input
	  						name="content"
	                		style={{cursor: 'inherit'}}
	                		className={`border-0 hover-effect-border fs-6 mt-1 py-0 bg-light text-black-50`}
	                		value={Tinfo.content}
	                		onChange={handleChange}
	                		placeholder="Content"
	                		required={true}
	  					/>
	  					<div className={`form-control border-1 hover-effect-border mt-1 text-muted bg-white cursor-text`}>
	  						<Editor
							  	toolbarHidden
							  	wrapperClassName="wrapper-class p-0"
							  	editorClassName="editor-class described"
							  	toolbarClassName="toolbar-class"
							  	wrapperStyle={{}}
							  	editorStyle={{ minHeight: '85px', overflow: 'hidden'}}
							  	editorState={sampleEditorContent}
							  	onEditorStateChange={setSampleEditorContent}
							  	onBlur={(event, editorState) => outFocusDescribed(event, editorState)}
							/>
	  					</div>
					</div>
			    </ModalBody>
			    <ModalFooter>
		          	<Button color="primary" type="submit">
		            	{$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.task.submit : 'Submit'}
		          	</Button>
		        </ModalFooter>
	        </Form>
		</Modal>
  );
};
export {AddTask};