import axios from 'axios';
import Util from '../core/util';

export default class ServiceTfield {
    static instance = ServiceTfield.instance || new ServiceTfield();
     
    storeTfield = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/storeTfield`, data, Util.getHeaders());
    }

    updateTfield = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/updateTfield/${id}`, data, Util.getHeaders());
    }

    deleteTfield = (id) =>{
        return axios.delete(`${Util.getAPIPrefix()}/deleteTfield/${id}`, Util.getHeaders());
    }
}