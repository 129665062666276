import axios from 'axios';
import Util from '../core/util';

export default class ServiceWtags {
    static instance = ServiceWtags.instance || new ServiceWtags();

    pagingWtags = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/pagingWtags`, data, Util.getHeaders());
    }
    
    storeWtags = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/storeWtags`, data, Util.getHeaders());
    }

    updateWtags = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/updateWtags/${id}`, data, Util.getHeaders());
    }

    deleteWtags = (id) =>{
        return axios.delete(`${Util.getAPIPrefix()}/deleteWtags/${id}`, Util.getHeaders());
    }

    assignTtags = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/assignTtags`, data, Util.getHeaders());
    }

    removeTtags = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/removeTtags`, data, Util.getHeaders());
    }
}