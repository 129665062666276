import axios from 'axios';
import Util from '../core/util';

export default class ServiceTinfo {
    static instance = ServiceTinfo.instance || new ServiceTinfo();

    pagingTinfo = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/pagingTinfo`, data, Util.getHeaders());
    }
    
    storeTinfo = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/storeTinfo`, data, Util.getHeaders());
    }

    showTinfo = (id) =>{
        return axios.get(`${Util.getAPIPrefix()}/showTinfo/${id}`, Util.getHeaders());
    }

    updateTinfo = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/updateTinfo/${id}`, data, Util.getHeaders());
    }

    cloneTinfo = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/cloneTinfo/${id}`, data, Util.getHeaders());
    }

    deleteTinfo = (id) =>{
        return axios.delete(`${Util.getAPIPrefix()}/deleteTinfo/${id}`, Util.getHeaders());
    }

    assignTmber = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/assignTmber`, data, Util.getHeaders());
    }

    removeTmber = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/removeTmber`, data, Util.getHeaders());
    }

    commentTinfo = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/commentTinfo/${id}`, data, Util.getHeaders());
    }

    updateCommentTinfo = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/updateCommentTinfo/${id}`, data, Util.getHeaders());
    }

    deleteCommentTinfo = (id) =>{
        return axios.delete(`${Util.getAPIPrefix()}/deleteCommentTinfo/${id}`, Util.getHeaders());
    }

    pagingTlogs = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/pagingTlogs`, data, Util.getHeaders());
    }

    exportExcel = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/exportExcel`, data, Util.getHeaders());
    }
}