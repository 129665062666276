import axios from 'axios';
import Util from '../core/util';

export default class ServiceTlist {
    static instance = ServiceTlist.instance || new ServiceTlist();
    
    storeTlist = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/storeTlist`, data, Util.getHeaders());
    }

    showTlist = (id) =>{
        return axios.get(`${Util.getAPIPrefix()}/showTlist/${id}`, Util.getHeaders());
    }

    updateTlist = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/updateTlist/${id}`, data, Util.getHeaders());
    }

    deleteTlist = (id) =>{
        return axios.delete(`${Util.getAPIPrefix()}/deleteTlist/${id}`, Util.getHeaders());
    }

    completeTlist = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/completeTlist/${id}`, data, Util.getHeaders());
    }

    rejectTlist = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/rejectTlist/${id}`, data, Util.getHeaders());
    }

    renewTlist = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/renewTlist/${id}`, data, Util.getHeaders());
    }

    completeAllTlist = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/completeAllTlist/${id}`, data, Util.getHeaders());
    }

    cancelTlist = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/cancelTlist/${id}`, data, Util.getHeaders());
    }
}