import axios from 'axios';
import Util from '../core/util';

export default class ServiceWfields {
    static instance = ServiceWfields.instance || new ServiceWfields();

    pagingWfields = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/pagingWfields`, data, Util.getHeaders());
    }
    
    storeWfields = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/storeWfields`, data, Util.getHeaders());
    }

    updateWfields = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/updateWfields/${id}`, data, Util.getHeaders());
    }

    deleteWfields = (id) =>{
        return axios.delete(`${Util.getAPIPrefix()}/deleteWfields/${id}`, Util.getHeaders());
    }
}