// import npm packge
import _ from 'lodash';
import $ from 'jquery';
import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, CardText, Button, ListGroup, InputGroup, Input, InputGroupText, Dropdown, DropdownToggle, //
         DropdownMenu, DropdownItem} from 'reactstrap';
import TextareaAutosize from 'react-autosize-textarea';
import { EditorState, ContentState, convertToRaw, getDefaultKeyBinding } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
// import service api
//
// import core class
import Util from '../../core/util';
// import com page
import { AvatarCom } from './AvatarCom';
// import modal tool
//
// import style media
import { MdOutlineDoneAll, MdOutlineAdd, MdOutlineDoDisturbAlt, MdOutlineDone, MdOutlineRedo, MdOutlineAutorenew, MdOutlineClear, MdCheck } from "react-icons/md";
import { FcBusinesswoman, FcBusinessman, FcManager} from "react-icons/fc";
import { TbProgressHelp } from "react-icons/tb"; 
// coding
function ChecklistsCom(props) {
    const [wMemberOpen, setwMemberOpen] = useState([]);
    const [inputSearch, setInputSearch] = useState([]);
    const [onWmembers, setOnWmembers] = useState([]);
    let toggleWmembers = (i) => { 
        let _checklist = Object.assign({}, props.Tinfo.checklists[i]);
        if (_checklist && _checklist.mode != 'system') {
            if (!wMemberOpen[i]) {
                filterWmembers(i);
                setInputSearch([]);
            }
            wMemberOpen[i] = !wMemberOpen[i]
            setwMemberOpen(prev => ([...[], ...wMemberOpen]));
        }  
    }
    let filterWmembers = (i, search = '') => {
        let _checklist = Object.assign({}, props.Tinfo.checklists[i]);
        props.wms_class.filterWmembers({wflow_id: props.Tinfo.workflow_id, search: search, uid: _checklist.work_mem ? _checklist.work_mem : ''}, function(data){
            let _temp = data.slice(0, 5);
            setOnWmembers(prev => ([...[], ..._temp]));
        });
    }
    let onSearchWmembers = (e, i) => {
        let _search = e.target.value;
        inputSearch[i] = _search;
        setInputSearch(prev => ([...[], ...inputSearch]));
        props.wms_class.searchLiveDownTimer(e, function(){
            filterWmembers(i, _search);
        });
    }
    let onSelectedWmember = (i, member) => {
        props.Tinfo.checklists[i]['fullname'] = member ? member.fullname : null;
        props.Tinfo.checklists[i]['avatar'] = member ? member.avatar : null;
        props.Tinfo.checklists[i]['work_mem'] = member ? member.uid : null;
        props.updateTlist(props.Tinfo.checklists[i], i);
    }
	return (
        <Card className={`border-0 rounded-0 mt-0 checklist-task`}>
            <CardHeader
                className={`border-0 rounded-0 fs-6 d-flex justify-content-start py-1 text-primary gap-1`}
            >
                <div className={`me-auto d-flex align-self-center`}>{props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.checklists : 'Checklists'} ({Util.renderNumberPlus(props.Tinfo.inprocess_checklists ? props.Tinfo.inprocess_checklists : 0)}/{Util.renderNumberPlus(props.Tinfo.review_checklists ? props.Tinfo.review_checklists : 0)}/{Util.renderNumberPlus(props.Tinfo.complete_checklists ? props.Tinfo.complete_checklists : 0)}/{Util.renderNumberPlus(props.Tinfo.fail_checklists ? props.Tinfo.fail_checklists : 0)}/{Util.renderNumberPlus(props.Tinfo.checklists ? props.Tinfo.checklists.length : 0)})</div>
                <MdOutlineDoneAll size="27" className={`bg-light hover-effect-border rounded-circle p-1 text-muted`} title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.approveall : 'Approve All'} onClick={() => props.approvalAll()}/>
                {props.accessAddTlist && (<MdOutlineAdd size="27" className={`bg-light hover-effect-border rounded-circle p-1 text-muted`} onClick={() => props.addChecklist()} title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.addchecklist : 'Add Checklist'}/>)}
            </CardHeader>
            <ListGroup>
                {props.Tinfo && props.Tinfo.checklists && props.Tinfo.checklists.map((checklist, i) => (
                    <InputGroup key={i} className={`px-3 gap-3 align-items-center item-do-list hover-effect`}
                        style={{cursor: 'initial'}}
                    >
                        <div
                            className={`d-flex align-self-center`}
                        >
                            <Dropdown isOpen={wMemberOpen[i]} toggle={() => toggleWmembers(i)} direction="up">
                                <DropdownToggle caret color="light" className={`btn-sm p-0 border-0 rounded-circle force-stage-unset element-unset`}
                                    style={{marginTop: 'var(--pix-m-2)'}}
                                >
                                    {checklist.work_mem ? (<AvatarCom className={`hover-opacity-5per cursor-pointer`} member={checklist} width={'25'} height={'25'} unsetborder={true} title={checklist.fullname} />)
                                     : (<TbProgressHelp size="25" className={`hover-opacity-5per bg-light border rounded-circle p-0 cursor-pointer`} title="Unassign" />)}
                                </DropdownToggle>
                                <DropdownMenu end flip className={`border-0 mt-2`}
                                    style={{width: 'var(--pix-wi-240)'}}
                                >
                                    <DropdownItem header>
                                        {props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.assign : "Assign Member"}
                                    </DropdownItem>
                                    <DropdownItem header tag="div" className={``}>
                                        <InputGroup size="sm">
                                            <Input type="text" placeholder="........." value={inputSearch[i] || ''} onChange={(e) => onSearchWmembers(e, i)}/>
                                        </InputGroup>
                                    </DropdownItem>
                                    <DropdownItem className={`d-flex align-items-center hover-effect-border`} onClick={() => onSelectedWmember(i, null) }>
                                        <TbProgressHelp size="25" className={`me-2 bg-light border rounded-circle p-0`} /> Unassign.....
                                        {!checklist.work_mem && (<MdCheck size="25" className={`ms-auto bg-transparent p-0`} />)}
                                    </DropdownItem>
                                    {checklist.work_mem && (
                                    <DropdownItem className={`d-flex align-items-center hover-effect-border`}>
                                        <AvatarCom className={`me-2`} member={checklist} width={'25'} height={'25'} unsetborder={true}/> {checklist.fullname}
                                        <MdCheck size="25" className={`ms-auto bg-transparent p-0`} />
                                    </DropdownItem>)}
                                    <DropdownItem divider></DropdownItem>
                                    {onWmembers.map((member, j) => (
                                    <DropdownItem key={j} className={`d-flex align-items-center hover-effect-border`} onClick={() => onSelectedWmember(i, member) }>
                                        <AvatarCom className={`me-2`} member={member} width={'25'} height={'25'} unsetborder={true}/> {member.fullname}
                                    </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        {checklist.result != 'failure' && (
                        <label className={`align-items-center checkcontainer mt-n1 cursor-pointer`} title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.donechecklist : 'Done Checklist'}>
                            <Input onChange={(event) => props.handleCheck(event, checklist, i)}
                                addon
                                aria-label="Check Completed"
                                type="checkbox"
                                checked={checklist.result == 'review' || checklist.result == 'complete'}
                                disabled={checklist.mode == 'system' || checklist.result == 'complete' || checklist.result == 'failure'}
                            />
                            <InputGroupText className="checkmark mt-1"></InputGroupText>
                        </label>
                        )}
                        {checklist.result == 'failure' && //
                        (
                            <label className={`align-items-center rounded-circle bg-danger mt-n1 cursor-pointer`} >
                                <MdOutlineDoDisturbAlt size="24" className={`text-white`} title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.decline : 'Decline'} />
                            </label>
                        )}
                        <CustomEditor checklist={checklist} wms_class={props.wms_class} i={i} outFocusChecklist={props.outFocusChecklist} myRefs={props.myRefs} />
                        {checklist.mode != 'system' && checklist.result == 'review' && (<MdOutlineDone size="24" className={`bg-light rounded-circle p-1 text-muted cursor-pointer hover-effect-border`} title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.approve : 'Approve Checklist'} style={{cursor: 'pointer'}} onClick={() => props.completeTlist(checklist.id, i)} />)}
                        {checklist.mode != 'system' && checklist.result == 'review' && (<MdOutlineDoDisturbAlt size="24" className={`bg-light rounded-circle p-1 text-muted cursor-pointer hover-effect-border`} title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.decline : 'Decline Checklist'} style={{cursor: 'pointer'}} onClick={() => props.rejectTlist(checklist.id, i)} />)}
                        {checklist.mode != 'system' && (checklist.result == 'complete' || checklist.result == 'failure') && (<MdOutlineRedo size="24" className={`bg-light rounded-circle p-1 text-muted cursor-pointer hover-effect-border`} title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.redochecklist : 'Redo Checklist'} style={{cursor: 'pointer'}} onClick={() => props.redoTlist(checklist, i)} />)}
                        {checklist.mode != 'system' && (checklist.result == 'complete' || checklist.result == 'failure') && (<MdOutlineAutorenew size="24" className={`bg-light rounded-circle p-1 text-muted cursor-pointer hover-effect-border`} title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.renewchecklist : 'Renew Checklist'} style={{cursor: 'pointer'}} onClick={() => props.renewTlist(checklist.id, i)} />)}
                        {(checklist.mode == 'single' || checklist.mode == 'template' && checklist.count > 1) && checklist.result != 'review' && checklist.result != 'failure' && checklist.result != 'complete' && (<MdOutlineClear size="24" className={`visible-on-focus bg-light rounded-circle p-1 text-muted cursor-pointer hover-effect-border`} title={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.Trans ? props.wms_class.comStage.state.Trans.task.deletechecklist : 'Delete Checklist'} style={{cursor: 'pointer'}} onClick={() => props.deleteTlist(checklist.id, i)} />)}
                    </InputGroup>
                ))}
            </ListGroup>
        </Card>
    );
};

const CustomEditor = props => {
    useEffect(() => {
        setSampleEditorContent(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(props.checklist.content).contentBlocks)))
    }, [sampleContent]);
    const [sampleEditorContent, setSampleEditorContent] = useState(() =>
        EditorState.createEmpty()
    );
    const [sampleContent, setSampleContent] = useState(props.checklist.content);
    const outFocusContentChecklist = (event, editorState) => {
        event.preventDefault();
        let _content = draftToHtml(convertToRaw(editorState.getCurrentContent())).replace(/\n$/,"");
        if (_content != props.checklist.content) {
            props.outFocusChecklist(_content, props.i);
        }
    }
    const listMentions = _.reject(props.wms_class.comStage.state.lstMembers, function(item) {
        item.text = item.fullname;
        item.value = item.fullname;
        item.url = item.fullname;
        return !item.isMember;
    });
    const keyBindingFn = (event) => {
        if (event.key === "@") {
            // setTimeout(async function() {
            //     var e = $.Event("keydown");
            //     e.which = 40;                     
            //     $(this).trigger(e);
            //     await document.dispatchEvent(new KeyboardEvent("keydown", { key: "ArrowDown" })); 
            // },2000);
        }
        if (event.key === "Tab") {
            return event.preventDefault();
        }
        return getDefaultKeyBinding(event);
    };
    return (
        <div className={`h-auto p-0 form-control border-0 me-auto text-skip hover-child bg-white overflow-hidden action-skip ${props.checklist.result == 'complete' || props.checklist.result == 'failure' ? 'text-decoration-line-through' : ''}`}
            style={{fontSize: 'var(--fs-15)', marginTop: 'var(--pix-m-2)', color: Util.indexColorbyId(props.wms_class.comStage.state.lstColors, props.checklist.bgrid)}}>
            <Editor
                key={props.i}
                toolbarHidden
                wrapperClassName="wrapper-class p-0"
                editorClassName="editor-class checklist-content"
                toolbarClassName="toolbar-class"
                wrapperStyle={{}}
                editorStyle={{ minHeight: '36px', overflow: 'hidden'}}
                editorState={sampleEditorContent}
                onEditorStateChange={setSampleEditorContent}
                onBlur={(event, editorState) => outFocusContentChecklist(event, editorState)}
                readOnly={(props.checklist.mode != 'single' && props.checklist.count == 1) || props.checklist.result == 'review' || props.checklist.result == 'complete' || props.checklist.result == 'failure'}
                spellCheck={false}
                editorRef={(el) => (props.myRefs.current[props.i] = el)}
                mention={{
                    separator: ' ',
                    trigger: '@',
                    suggestions: listMentions,
                }}
                keyBindingFn={(event) => keyBindingFn(event)}
            />
        </div>
    )
};

export { ChecklistsCom, CustomEditor };