import axios from 'axios';
import Util from '../core/util';

export default class ServiceTtime {
    static instance = ServiceTtime.instance || new ServiceTtime();

    checkinTime = (id, data = {}) =>{
        return axios.post(`${Util.getAPIPrefix()}/checkinTime/${id}`, data, Util.getHeaders());
    }
    
    checkoutTime = (id, data = {}) =>{
        return axios.post(`${Util.getAPIPrefix()}/checkoutTime/${id}`, data, Util.getHeaders());
    }

    removeTime = (id) =>{
        return axios.delete(`${Util.getAPIPrefix()}/removeTime/${id}`, Util.getHeaders());
    }
}