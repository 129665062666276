import axios from 'axios';
import Util from '../core/util';

export default class ServiceWstep {
    static instance = ServiceWstep.instance || new ServiceWstep();

    pagingWstep = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/pagingWstep`, data, Util.getHeaders());
    }
    
    storeWstep = (data) =>{
        return axios.post(`${Util.getAPIPrefix()}/storeWstep`, data, Util.getHeaders());
    }

    updateWstep = (id, data) =>{
        return axios.post(`${Util.getAPIPrefix()}/updateWstep/${id}`, data, Util.getHeaders());
    }

    deleteWstep = (id) =>{
        return axios.delete(`${Util.getAPIPrefix()}/deleteWstep/${id}`, Util.getHeaders());
    }
}