// import npm packge
import React, { useState } from 'react';
import _ from 'lodash';
import {
    Form, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, //
    Input, InputGroup, InputGroupText, Card, CardHeader, CardBody, CardImg, Table, Row, Col,
} from 'reactstrap';
import Select, { components } from 'react-select';
// import service api
import ServiceUser from '../../services/ServiceUser';
// import core class
import Util from '../../core/util';
// import com page
//
// import modal tool
import {colourStyles, CustomSelectOption, CustomSelectValue} from '../../components/Stage/Task';
// import style media
import SVG from 'react-inlinesvg';
import { FcBusinessman, FcBusinesswoman, FcLike, FcCheckmark, FcCloseUpMode } from "react-icons/fc";
import { MdOutlineAdd, MdOutlineSave, MdOutlineClear } from "react-icons/md";
import { FaRegBell } from "react-icons/fa";
import './default.css';
// coding
function ManageMember($this) {
    const [modal, setModal] = useState(false);
    const [modeLink, setMode] = useState(false);
    const [selectedMember, setSelectedMember] = useState([]);
    const [linkUsers, setLinkedUsers] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isOpenNotify, setOpenNotify] = useState(false);
    const [notifiesSetting, setNotifiesSetting] = useState([]);
    const [memberNotifiesSetting, setMemberNotifiesSetting] = useState([]);
    const [userNotify, setUserNotify] = useState({});
    const $rootreact = $this.react.props.data.workflowman;
    $this.react.toggleModalUsers = () => {
        if (!modal) {
            getMembers(true);
        }
        setModal(!modal)
    };
    const getMembers = (init = false) => {
        $this.wms_class.getLstMembers($this.wms_class.comStage.state.objData.workfolows.id, function($argmb, $statusmb){
            if($argmb){
                setSelectedMember(prev => ([...[], ...$argmb.state.lstMembers]));
                $rootreact.react.setState({wms_class: $argmb});
                if (init) {
                    ServiceUser.instance.getLinkedUser()
                    .then(({ data }) => {
                        if (data.status) {
                            setLinkedUsers(prev => ([...[], ...data.data]));
                        } else {
                            Util.toast(data.error, 'error')
                        }
                    })
                }
            }
            else {
                Util.toast($statusmb.error, 'error');
            }
        });
    }
    const addMemberList = () => {
        let members = [...selectedMember];
        members.unshift({mode: 'core', username: '', fullname: '', password: '', user_role: 'member'});
        setSelectedMember(members);
    }
    const onSelectedOption = (member) => {
        let members = [...selectedMember];
        members.unshift({mode: 'link', username: member.username, fullname: member.fullname, password: '', user_role: 'member'});
        setSelectedMember(members);
    }
    const handleChange = (event, i) => {
        event.preventDefault();
        const {name, value} = event.target;
        let members = [...selectedMember];
        members[i][name] = value;
        setSelectedMember(members);
    }
    const onSubmitMember = (member) => {
        if (member.uid) {
            const uid = member.uid;
            delete member.uid;
            ServiceUser.instance.updateUser(uid, member)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                getMembers();
            })
        } else {
            ServiceUser.instance.storeUser(member)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                getMembers();
            })
        }
    }
    const handleCheck = async (e, uid, index, action = 'member') => {
        e.preventDefault();
        // real data async await bettwen service vs client
        if (action == 'member') {
            selectedMember[index].isMember = e.target.checked;
        } else {
            selectedMember[index].accessfield = e.target.checked == true ? 'active' : 'deactive';
        }
        const memberStage = {
            ...$this.wms_class.comStage.state,
            lstMembers: selectedMember
        }
        setSelectedMember([]);
        await $rootreact.comStage.setState(memberStage);
        setSelectedMember(prev => ([...[], ...$rootreact.comStage.state.lstMembers]));
        $rootreact.react.setState({wms_class: $rootreact.comStage});
        //==================================================
        if (action == 'member') {
            const member = {
                assign: uid,
                wflow_id: $this.wms_class.comStage.state.objData.workfolows.id,
            };
            if (selectedMember[index].isMember) {
                ServiceUser.instance.assignWmber(member)
                .then(({ data }) => {
                    if (data.status) {
                        Util.toast(data.success)
                    } else {
                        Util.toast(data.error, 'error')
                    }
                    getMembers();
                })
            } else {
                ServiceUser.instance.removeWmber(member)
                .then(({ data }) => {
                    if (data.status) {
                        Util.toast(data.success)
                    } else {
                        Util.toast(data.error, 'error')
                    }
                    getMembers();
                })
            }
        } else {
            onSubmitMember(selectedMember[index]);
        }
    }
    const onDeleteMember = (uid, i) => {
        if (!uid) {
            setSelectedMember([
                ...selectedMember.slice(0, i),
                ...selectedMember.slice(i + 1)
            ]);
        } else {
            ServiceUser.instance.deleteUser(uid)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                getMembers();
            })
        }   
    }
    const toggleModalNotify = () => {
        setOpenNotify(!isOpenNotify);
    }
    const openModalNotify = (member) => {
        setOpenNotify(true);
        setUserNotify(member)
        getNotifiesSetting(member.uid);
    }
    const getNotifiesSetting = (uid) => {
        ServiceUser.instance.getNotifiesSetting({uid: uid, wflow_id: $this.wms_class.comStage.state.objData.workfolows.id})
        .then(({ data }) => {
            if (data.status) {
                //
            } else {
                Util.toast(data.error, 'error')
            }
            setNotifiesSetting(data.settingnotifies);
            setMemberNotifiesSetting(data.data);
        })
    }
    const addNotifySetting = () => {
        let notifies = [...memberNotifiesSetting];
        notifies.unshift({widget_extend_id: '', wflow_id: $this.wms_class.comStage.state.objData.workfolows.id, member_uid: userNotify.uid, status: 'active'});
        setMemberNotifiesSetting(notifies);
    }
    const handleChangeNotifySetting = (event, i) => {
        event.preventDefault();
        const {name, value} = event.target;
        let members = [...memberNotifiesSetting];
        members[i][name] = value;
        setMemberNotifiesSetting(members);
    }
    const handleCheckNotify = async (e, id, index) => {
        e.preventDefault();
        // real data async await bettwen service vs client
        memberNotifiesSetting[index].status = e.target.checked == true ? 'active' : 'deactive';
        setMemberNotifiesSetting([]);
        await setMemberNotifiesSetting(prev => ([...[], ...memberNotifiesSetting]));
        //==================================================
        onSubmitMemberNotify(memberNotifiesSetting[index]);
    }
    const onSubmitMemberNotify = (notify) => {
        if (notify.id) {
            const id = notify.id;
            delete notify.id;
            ServiceUser.instance.updateNotifiesSetting(id, notify)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                getNotifiesSetting(notify.member_uid);
            })
        } else {
            ServiceUser.instance.storeNotifiesSetting(notify)
            .then(({ data }) => {
                if (data.status) {
                    Util.toast(data.success)
                } else {
                    Util.toast(data.error, 'error')
                }
                getNotifiesSetting(notify.member_uid);
            })
        }
    }
    return (
        [
        <Modal key={`member-management`} isOpen={modal} toggle={$this.react.toggleModalUsers} {...$this} fullscreen={true} size={`lg`} scrollable={true}
            >
            <ModalHeader toggle={$this.react.toggleModalUsers}
                className={``}>
                {$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.member.account : 'Member Account'}
            </ModalHeader>
            <ModalBody className={`gap-3 mb-0`}>
                <div className={`d-flex`}>
                    <Row className="row-cols-lg-auto g-3 align-items-center">
                        <Col>
                            <FormGroup switch>
                                <Input type="switch" role="switch" defaultChecked={modeLink} onClick={() => { setMode(!modeLink); }} disabled={selectedMember.length && !selectedMember[0].uid} />
                                <Label check>Mode Link</Label>
                            </FormGroup>
                        </Col>
                        {(selectedMember.length == 0 || selectedMember[0].uid) && (<Col style={{width: 'var(--pix-w-270)'}}>
                            {modeLink && (<Select
                                defaultValue={selectedOption}
                                options={linkUsers}
                                components={{
                                    Option: CustomSelectOption,
                                    SingleValue: CustomSelectValue
                                }}
                                onChange={onSelectedOption}
                            />)}
                            {!modeLink && (<MdOutlineAdd title={$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.member.add : 'Add User'} size="30" className={`bg-light hover-effect-border rounded-circle p-1 text-muted`} onClick={addMemberList} />)}
                        </Col>)}
                    </Row>
                </div>
                <Table striped className={`mb-0`}>
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th>
                                {$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.member.username : 'Username'}
                            </th>
                            <th>
                                {$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.member.fullname : 'Fullname'}
                            </th>
                            <th>
                                {$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.member.role : 'Role'}
                            </th>
                            <th>
                                {$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.member.password : 'Password'}
                            </th>
                            <th>
                                {$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.member.action : 'Action'}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {selectedMember.length > 0 && selectedMember.map((member, i) =>
                        <tr key={i}>
                            <th scope="member" className={`align-middle`}>
                                {i + 1}
                            </th>
                            <td className={`align-middle`}>
                                <Input name="username" placeholder={$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.member.username : 'Username'} type="text" value={member.username} onChange={(e) => handleChange(e, i)} />
                            </td>
                            <td className={`align-middle`}>
                                <Input name="fullname" placeholder={$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.member.fullname : 'Fullname'} type="text" value={member.fullname} onChange={(e) => handleChange(e, i)} />
                            </td>
                            <td className={`align-middle`}>
                                <Input type="select" bsSize="xl" value={member.user_role} name="user_role" style={{'cursor': 'pointer'}} onChange={(e) => handleChange(e, i)}>
                                    {$this.wms_class.comStage.state.lstConstants.map((role, i) => {
                                        return role.mode == 'role' ? <option key={i} value={role.identifier}>
                                        {role.name}
                                    </option> : ''
                                    })}
                                </Input>
                            </td>
                            <td className={`align-middle`}>
                                <Input name="password" placeholder={$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.member.password : 'Password'} type="text" value={member.password} onChange={(e) => handleChange(e, i)} />
                            </td>
                            <td className={`align-middle`}>
                                <div className={`d-flex align-items-center`}>
                                    <FaRegBell title={$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.member.notify_setting : 'Notifications Setting'} size="30"className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1`} onClick={() => openModalNotify(member)} />
                                    <InputGroup className={`rounded-circle mx-1 p-1 item-do-list hover-effect`}
                                        style={{cursor: 'initial', width: 'var(--per-w-24)' }}>
                                        <label className={`align-self-start checkcontainer cursor-pointer`} title="Access Field">
                                            <Input onChange={(e) => handleCheck(e, member.uid, i, 'user')}
                                                addon
                                                type="checkbox"
                                                checked={member.accessfield == 'active' ? true : false}
                                                name="accessfield"
                                                disabled={false}
                                            />
                                            <InputGroupText className="checkmark border-warning"></InputGroupText>
                                        </label>
                                    </InputGroup>
                                    <MdOutlineSave title={$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.member.save : 'Save User'} size="30"className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1 ${member.username && member.fullname && (member.uid || member.password) ? '' : 'invisible'}`} onClick={() => onSubmitMember(member)} />
                                    <InputGroup className={`rounded-circle mx-1 p-1 item-do-list hover-effect ${member.uid && member.uid != $this.wms_class.comStage.state.objData.profiles.uid ? '' : 'invisible'}`}
                                        style={{cursor: 'initial', width: 'var(--per-w-24)' }}>
                                        <label className={`align-self-start checkcontainer cursor-pointer`} title="Assign Member">
                                            <Input onChange={(e) => handleCheck(e, member.uid, i)}
                                                addon
                                                aria-label="Check Completed"
                                                type="checkbox"
                                                checked={member.isMember || false}
                                                disabled={member.uid == $this.wms_class.comStage.state.objData.profiles.uid}
                                            />
                                            <InputGroupText className="checkmark"></InputGroupText>
                                        </label>
                                    </InputGroup>
                                    <MdOutlineClear title={$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.member.delete : 'Delete User'} size="30"className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1 ${member.uid != $this.wms_class.comStage.state.objData.profiles.uid ? '' : 'invisible'}`} onClick={() => onDeleteMember(member.uid, i)} />
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </ModalBody>
        </Modal>,
        <Modal key={`member-notifies`} isOpen={isOpenNotify} toggle={toggleModalNotify} size={`lg`} scrollable={true}>
            <ModalHeader toggle={toggleModalNotify} className={``}>
                {$this.wms_class.comStage && $this.wms_class.comStage.state ? $this.wms_class.comStage.state.Trans.member.notify_setting : 'Notifications Setting'}
            </ModalHeader>
            <ModalBody className={`gap-3 mb-0`}>
                <div className={`d-flex`}>
                    <Row className="row-cols-lg-auto g-3 align-items-center">
                        {(memberNotifiesSetting.length == 0 || memberNotifiesSetting[0].id) && (
                        <Col>
                            <MdOutlineAdd title={'Add Notify Setting'} size="30" className={`bg-light hover-effect-border rounded-circle p-1 text-muted`} onClick={() => addNotifySetting()} />
                        </Col>)}
                    </Row>
                </div>
                <Table striped className={`mb-0`}>
                    <thead>
                        <tr>
                            <th>
                                {'Notify'}
                            </th>
                            <th>
                                {'Action'}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {memberNotifiesSetting.length > 0 && memberNotifiesSetting.map((notify, i) =>
                        <tr key={i}>
                            <td className={`align-middle`}>
                                <Input type="select" bsSize="xl" value={notify.widget_extend_id} name="widget_extend_id" style={{'cursor': 'pointer'}} onChange={(e) => handleChangeNotifySetting(e, i)}>
                                    <option value="">
                                        Select Notify
                                    </option>
                                    {notifiesSetting.length && notifiesSetting.map((notify_setting, j) => {
                                        return <option key={j} value={notify_setting.id}>
                                            {notify_setting.name}
                                        </option>
                                    })}
                                </Input>
                            </td>
                            <td className={`align-middle`}>
                                <div className={`d-flex align-items-center`}>
                                    {notify.id && (<InputGroup className={`rounded-circle mx-1 p-1 item-do-list hover-effect`}
                                        style={{cursor: 'initial', width: 'var(--per-w-24)' }}>
                                        <label className={`align-self-start checkcontainer cursor-pointer`} title="Active/Deactive">
                                            <Input onChange={(e) => handleCheckNotify(e, notify.id, i)}
                                                addon
                                                type="checkbox"
                                                checked={notify.status == 'active' ? true : false}
                                                name="status"
                                                disabled={false}
                                            />
                                            <InputGroupText className="checkmark border-warning"></InputGroupText>
                                        </label>
                                    </InputGroup>)}
                                    {!notify.id && (<MdOutlineSave title={'Save'} size="30"className={`bg-light hover-effect-border rounded-circle text-muted p-1 mx-1 ${notify.widget_extend_id && notify.member_uid ? '' : 'invisible'}`} onClick={() => onSubmitMemberNotify(notify)} />)}
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </ModalBody>
        </Modal>
        ]
    );
};
export { ManageMember };