// import npm packge
import React, { useState } from "react";
import _ from 'lodash';
import * as moment from "moment";
import { UncontrolledCollapse, Toast, ToastHeader, ToastBody, InputGroup, Input, Button, Badge, //
        UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";
// import service api
import ServiceWidgets  from '../../services/ServiceWidgets';
// import core class
import Util from '../../core/util';
// import com page
//
// import modal tool
import {colourStyles, CustomSelectOption, CustomSelectValue} from '../../components/Stage/Task';
// import style media
import { MdOutlineSearch, MdMoveToInbox, MdOutlineAccountCircle, MdOutlineCalendarMonth, MdOutlineCheckCircleOutline, //
    MdOutlineMore, MdSettingsOverscan, MdOutlineTask, MdOutlineSchedule} from "react-icons/md";
import "./default.css";
function MainFilter(props) {
    const [inputsearch, setInputsearch] = useState('');
    const [onStages, setOnStages] = useState([]);
    const [activeStatus, setActiveStatus] = useState({});
    const [activeDateline, setActiveDateline] = useState({});
    const [checklistResults, setChecklistResults] = useState([]);
    const [onResults, setOnResults] = useState([]);
    const [onTags, setOnTags] = useState([]);
    const [onWatches, setOnWatches] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [activeTemplate, setActiveTemplate] = useState({});

    if (props.wms_class && props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.objData && props.wms_class.comStage.state.objData.filter.inputsearch != inputsearch) {
        setInputsearch(props.wms_class.comStage.state.objData.filter.inputsearch);
    }
    if (props.wms_class && props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.objData && props.wms_class.comStage.state.objData.filter.stage_step.length != onStages.length) {
        let _stages = _.map(Object.assign([], _.filter(props.wms_class.comStage.state.objData.stages, function(o) { return props.wms_class.comStage.state.objData.filter.stage_step.indexOf(o.step) > -1; })), function(p) {
            p.label = p.name;
            p.value = p.step;
            return p;
        })
        setOnStages(_stages);
    }
    if (props.wms_class && props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.lstConstants && props.wms_class.comStage.state.lstConstants.length > 0) {
        let _status = _.find(props.wms_class.comStage.state.lstConstants, {identifier:props.wms_class.comStage.state.objData.filter.status});
        if (_status && _status.identifier != activeStatus.identifier){
            setActiveStatus(prev => ({...{}, ..._status}));
        }
        let _dateline = _.find(props.wms_class.comStage.state.lstConstants, {identifier:props.wms_class.comStage.state.objData.filter.dateline});
        if (_dateline && _dateline.identifier != activeDateline.identifier){
            setActiveDateline(prev => ({...{}, ..._dateline}));
        }
    }
    if (props.wms_class && props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.objData && props.wms_class.comStage.state.objData.filter.checklist_result.length != onResults.length) {
        let _results = _.map(_.filter(props.wms_class.comStage.state.lstConstants, function(o) { return o.mode == 'result' && props.wms_class.comStage.state.objData.filter.checklist_result.indexOf(o.identifier) > -1;}), function(p) {
            p.background = '#ffffff';
            return p;
        })
        setOnResults(_results);
    }
    if (props.wms_class && props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.objData && props.wms_class.comStage.state.objData.filter.tags.length != onTags.length) {
        let _tags = _.filter(props.wms_class.comStage.state.lstTags, function(o) { return props.wms_class.comStage.state.objData.filter.tags.indexOf(o.id) > -1;})
        setOnTags(_tags);
    }
    if (props.wms_class && props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.objData && props.wms_class.comStage.state.objData.filter.watches.length != onWatches.length) {
        let _watches = _.filter(props.wms_class.comStage.state.lstMembers, function(o) { return props.wms_class.comStage.state.objData.filter.watches.indexOf(o.uid) > -1;})
        setOnWatches(_watches);
    }
    if (props.wms_class && props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.objData && !props.wms_class.comStage.state.objData.filter.date.start && startDate) {
        setStartDate(null)
    }
    if (props.wms_class && props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.objData && !props.wms_class.comStage.state.objData.filter.date.end && endDate) {
        setEndDate(null)
    }
    if (props.wms_class && props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.objData && props.wms_class.comStage.state.objData.templateActive && props.wms_class.comStage.state.objData.filter.template == '' && activeTemplate.key) {
        setActiveTemplate({})
    }
    if (props.wms_class && props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.lstWidgetpacks && !props.wms_class.comStage.state.objData.templateActive && props.wms_class.comStage.state.objData.filter.template == 'default' && (activeTemplate.key != 'default' || !activeTemplate.key) && props.wms_class.comStage.state.objData.extendWidgets && props.wms_class.comStage.state.objData.extendWidgets.length == 0) {
        setActiveTemplate({name: props.wms_class.comStage.state.Trans.filter.template_default, key: 'default'}); 
    }

    let onChangeDates = (dates) => {
        const [start, end] = dates;
        let filterrange = parseInt(props.wms_class.comStage.state.objData.workspace.filterrange);
        if (end && start && moment(end).diff(moment(start), 'days') + 1 > filterrange*30) {
            let msg = props.wms_class.comStage.state.Trans.toast.out_range_filter;
            Util.toast(msg.replace("@day", filterrange*30), 'error');
            setStartDate(null);
            setEndDate(null);
            changeValueSearch('date', {});
            return;
        }
        setStartDate(start);
        setEndDate(end);
        changeValueSearch('date', {start: moment(start).format("YYYY-MM-DD"),end: moment(end).format("YYYY-MM-DD")});
    };
    let clearFilter = () => {
        setTimeout(async function(){
            await props.wms_class.clearFilter();
        }, 0);
    }
    let changeValueSearch = (key, value) => {
        let filter = Object.assign({}, props.wms_class.comStage.state.objData.filter);
        filter[key] = value;
        let _objData = props.wms_class.comStage.state.objData;
        _objData.filter = filter;
        props.wms_class.comStage.setState({objData: _objData});
        props.wms_class.react.setState({wms_class: props.wms_class});
    }
    let handleChangeSearch = (e) => {
        setInputsearch(e.target.value);
        changeValueSearch('inputsearch', e.target.value);
    }
    let onSelectedStages = (stages) => {
        setOnStages(stages);
        changeValueSearch('stage_step', _.map(stages, 'step'));
    }
    let onSelectedChecklistResults = (results) => {
        setOnResults(results);
        changeValueSearch('checklist_result', _.map(results, 'identifier'));
    }
    let onSelectedWatches = (members) => {
        setOnWatches(members);
        changeValueSearch('watches', _.map(members, 'uid'));
    }
    let onSelectedTags = (tags) => {
        setOnTags(tags);
        changeValueSearch('tags', _.map(tags, 'id'));
    }
    
    return (
        <UncontrolledCollapse horizontal navbar toggler="#wms-advance-filter"
            style={{
                width: 'var(--pix-w-330)',
                height: '100%',
            }}
            className={`position-absolute panel-activity-filter bg-dark`}>
            <Toast className={`bg-transparent min-vh-100`}>
                <ToastHeader className={`bg-dark bg-gradient rounded-0 text-white`}>
                    {props.wms_class.comStage && props.wms_class.comStage.state ? props.wms_class.comStage.state.Trans.filter.advance : 'Advance Filter'}
                </ToastHeader>
                <ToastBody className={`work-scroller filter-color-scroller`}>
                    {props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.objData && (
                    <InputGroup size="xl" className={`w-auto`} title={props.wms_class.comStage && props.wms_class.comStage.state ? props.wms_class.comStage.state.Trans.filter.search : 'Searching Task'}>
                        <Input type="text" placeholder={props.wms_class.comStage && props.wms_class.comStage.state ? props.wms_class.comStage.state.Trans.filter.search : 'Searching Task'} name="search" value={inputsearch} onChange={(e) => handleChangeSearch(e)} />
                    </InputGroup>
                    )}
                    {props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.objData && props.wms_class.comStage.state.objData.columns && (
                    <div
                        className={`d-flex justify-content-start mt-3 gap-3`}
                    >
                        <label className={`me-auto d-flex align-self-center lh-lg`}>
                            <Badge color="skip" pill>
                                <MdMoveToInbox size="19"/>
                            </Badge>
                            {props.wms_class.comStage && props.wms_class.comStage.state ? props.wms_class.comStage.state.Trans.filter.stage : 'Stage'}
                        </label>
                        <div className={`hover-effect-border px-0 text-mute`} style={{minWidth: 'var(--pix-w-200)', color: '#000'}}>
                            <Select
                                isMulti
                                value={onStages}
                                options={_.map(Object.assign([], props.wms_class.comStage.state.objData.stages), function(p) {
                                    p.label = p.name;
                                    p.value = p.step;
                                    return p;
                                })}
                                onChange={onSelectedStages}
                                isClearable={false}
                                className={`cuson-tags-value`}
                            />
                        </div>
                    </div>
                    )}
                    {props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.lstConstants.length > 0 && (
                    <div
                        className={`d-flex justify-content-start mt-3 gap-3`}
                    >
                        <label className={`me-auto d-flex align-self-center lh-lg`}>
                            <Badge color="skip" pill>
                                <MdOutlineTask size="19" />
                            </Badge>
                            {props.wms_class.comStage && props.wms_class.comStage.state ? props.wms_class.comStage.state.Trans.filter.status : 'Status'}
                        </label>
                        <div className={`hover-effect-border px-0 text-mute`} style={{minWidth: 'var(--pix-w-200)', color: '#000'}}>
                            <UncontrolledDropdown>
                                <DropdownToggle caret size="xl" color="skip" className={`bg-white text-end w-100`}>
                                    {activeStatus.label ? activeStatus.label : (props.wms_class.comStage && props.wms_class.comStage.state ? props.wms_class.comStage.state.Trans.filter.select : 'Select Status')}
                                </DropdownToggle>
                                <DropdownMenu end flip className={`border-0 rounded-0 py-0`}>
                                    {props.wms_class.comStage.state.lstConstants.map((status, i) => { 
                                        return status.mode == 'status' && status.identifier != activeStatus.identifier ? <DropdownItem key={i} size="sm"
                                        className={`hover-opacity-5per text-mute`} onClick={() => {setActiveStatus(status); changeValueSearch('status', status.identifier)}}>
                                            {status.label}
                                        </DropdownItem> : ''
                                    }
                                    )}
                                </DropdownMenu >
                            </UncontrolledDropdown>
                        </div>
                    </div>
                    )}
                    {props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.lstConstants.length > 0 && (
                    <div
                        className={`d-flex justify-content-start mt-3 gap-3`}
                    >
                        <label className={`me-auto d-flex align-self-center lh-lg`}>
                            <Badge color="skip" pill>
                                <MdOutlineCheckCircleOutline size="19" />
                            </Badge>
                            {props.wms_class.comStage && props.wms_class.comStage.state ? props.wms_class.comStage.state.Trans.filter.result : 'Result'}
                        </label>
                        <div className={`hover-effect-border px-0 text-mute`} style={{minWidth: 'var(--pix-w-200)', color: '#000'}}>
                            <Select
                                isMulti
                                value={onResults}
                                options={_.map(_.filter(props.wms_class.comStage.state.lstConstants, function(o) { return o.mode == 'result';}), function(p) {
                                    p.background = '#ffffff';
                                    return p;
                                })}
                                onChange={onSelectedChecklistResults}
                                isClearable={false}
                                className={`cuson-tags-value`}
                            />
                        </div>
                    </div>
                    )}
                    {props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.lstTags.length > 0 && (
                    <div
                        className={`d-flex justify-content-start mt-3 gap-3`}
                    >
                        <label className={`me-auto d-flex align-self-center lh-lg`}>
                            <Badge color="skip" pill>
                                <MdOutlineMore size="19" />
                            </Badge>
                            {props.wms_class.comStage && props.wms_class.comStage.state ? props.wms_class.comStage.state.Trans.filter.tags : 'Tags'}
                        </label>
                        <div className={`hover-effect-border px-0`} style={{minWidth: 'var(--pix-w-200)'}}>
                            <Select
                                isMulti
                                value={onTags}
                                options={_.filter(props.wms_class.comStage.state.lstTags, function(o) { return o.type == 'default';})}
                                onChange={onSelectedTags}
                                isClearable={false}
                                styles={colourStyles}
                                className={`cuson-tags-value`}
                            />
                        </div>
                    </div>
                    )}
                    {props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.lstMembers.length > 0 && (
                    <div
                        className={`d-flex justify-content-start mt-3 gap-3`}
                    >
                        <label className={`me-auto d-flex align-self-center lh-lg`}>
                            <Badge color="skip" pill>
                                <MdOutlineAccountCircle size="19" />
                            </Badge>
                            {props.wms_class.comStage && props.wms_class.comStage.state ? props.wms_class.comStage.state.Trans.filter.watches : 'Watches'}
                        </label>
                        <div className={`hover-effect-border px-0`} style={{minWidth: 'var(--pix-w-200)', color: '#000'}}>
                            <Select
                                isMulti
                                value={onWatches}
                                options={props.wms_class.comStage.state.lstMembers}
                                components={{
                                    Option: CustomSelectOption,
                                    MultiValueLabel: CustomSelectValue
                                }}
                                onChange={onSelectedWatches}
                                isClearable={false}
                                className={`cuson-tags-value`}
                            />
                        </div>
                    </div>
                    )}
                    {props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.lstConstants.length > 0 && (
                    <div
                        className={`d-flex justify-content-start mt-3 gap-3`}
                    >
                        <label className={`me-auto d-flex align-self-center lh-lg`}>
                            <Badge color="skip" pill>
                                <MdOutlineSchedule size="19" />
                            </Badge>
                            {props.wms_class.comStage && props.wms_class.comStage.state ? props.wms_class.comStage.state.Trans.filter.dateline : 'Dateline'}
                        </label>
                        <div className={`hover-effect-border px-0 text-mute`} style={{minWidth: 'var(--pix-w-200)', color: '#000'}}>
                            <UncontrolledDropdown>
                                <DropdownToggle caret size="xl" color="skip" className={`bg-white text-end w-100`}>
                                    {activeDateline.label ? activeDateline.label : (props.wms_class.comStage && props.wms_class.comStage.state ? props.wms_class.comStage.state.Trans.filter.select_dateline : 'Select Dateline')}
                                </DropdownToggle>
                                <DropdownMenu end flip className={`border-0 rounded-0 py-0`}>
                                    {props.wms_class.comStage.state.lstConstants.map((dateline, i) => { 
                                        return dateline.mode == 'dateline' && dateline.identifier != activeDateline.identifier ? <DropdownItem key={i} size="sm"
                                        className={`hover-opacity-5per text-mute`} onClick={() => {setActiveDateline(dateline); changeValueSearch('dateline', dateline.identifier)}}>
                                            {dateline.label}
                                        </DropdownItem> : ''
                                    }
                                    )}
                                </DropdownMenu >
                            </UncontrolledDropdown>
                        </div>
                    </div>
                    )}
                    {props.wms_class.comStage && props.wms_class.comStage.state && (
                        <div
                            className={`d-flex justify-content-start mt-3 gap-3`}
                        >
                            <label className={`me-auto d-flex align-self-center lh-lg`}>
                                <Badge color="skip" pill>
                                    <MdOutlineCalendarMonth size="19" />
                                </Badge>
                                {props.wms_class.comStage && props.wms_class.comStage.state ? props.wms_class.comStage.state.Trans.filter.duedate : 'Due date'}
                            </label>
                            <div className={`hover-effect-border px-0`} style={{minWidth: 'var(--pix-w-200)', color: '#000'}}>
                                <DatePicker
                                    selected={startDate}
                                    onChange={onChangeDates} 
                                    dateFormat={props.wms_class.comStage.state.objData.workspace.date_fm}
                                    startDate={startDate} 
                                    endDate={endDate} 
                                    selectsRange
                                    className="form-control ps-2 pe-2"
                                />
                            </div>
                        </div>
                    )}
                    <div className={`d-flex mt-3 fs-5`}>
                        <Badge pill color="skip" href="#" className={`p-0 me-auto`} onClick={(e) => { e.preventDefault(); clearFilter()}}>
                            {props.wms_class.comStage && props.wms_class.comStage.state ? props.wms_class.comStage.state.Trans.filter.reset : 'Reset all filters'}
                        </Badge>
                        <Badge pill color="skip" href="#" className={`p-0`} onClick={(e) => { e.preventDefault(); props.wms_class.onSearchTinfos()}}>
                            {props.wms_class.comStage && props.wms_class.comStage.state ? props.wms_class.comStage.state.Trans.filter.submit : 'Submit'}
                        </Badge>
                    </div>
                    <div
                        className={`d-flex justify-content-start mt-3 gap-3`}
                    >
                        <div className={`hover-effect-border px-0 text-mute`} style={{minWidth: 'var(--pix-w-200)', color: '#000'}}>
                            <UncontrolledDropdown>
                                <DropdownToggle caret={props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.objData.templateActive ? true : false} size="sm" color="skip" className={`bg-white text-end w-100`}>
                                    {activeTemplate.name ? activeTemplate.name : (props.wms_class.comStage && props.wms_class.comStage.state ? props.wms_class.comStage.state.Trans.filter.select_template : 'Select Template')}
                                </DropdownToggle>
                                {props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.objData.templateActive && (
                                <DropdownMenu end flip className={`border-0 rounded-0 py-0`}>
                                    {activeTemplate.key && (<DropdownItem size="sm" className={`hover-opacity-5per text-mute`} onClick={() => {changeValueSearch('template', ''); setActiveTemplate({})}}>
                                        {props.wms_class.comStage && props.wms_class.comStage.state ? props.wms_class.comStage.state.Trans.filter.select_template : 'Select Template'}
                                    </DropdownItem>
                                    )}
                                    {props.wms_class.comStage && props.wms_class.comStage.state && props.wms_class.comStage.state.objData.extendWidgets && props.wms_class.comStage.state.objData.extendWidgets.map((widget, i) => { 
                                        return widget.key != activeTemplate.key ? <DropdownItem key={i} size="sm"
                                        className={`hover-opacity-5per text-mute`} onClick={() => {changeValueSearch('template', widget.key); setActiveTemplate(widget)}}>
                                            {widget.name}
                                        </DropdownItem> : ''
                                    }
                                    )}
                                </DropdownMenu>)}
                            </UncontrolledDropdown>
                        </div>
                        <Badge pill color="skip" href="#" className={`p-0 me-auto justify-content-end align-self-center fs-6`} onClick={(e) => { e.preventDefault(); props.wms_class.exportExcel()}}>
                            {props.wms_class.comStage && props.wms_class.comStage.state ? props.wms_class.comStage.state.Trans.filter.export : 'Export'}
                        </Badge>
                    </div>
                </ToastBody>
          </Toast>
        </UncontrolledCollapse>
    );
};
export { MainFilter };