// import npm packge
import React from "react";
import { connect } from "react-redux";
import $ from 'jquery';
import { InputGroup, Input, Button, Card, CardHeader, CardBody, CardTitle, CardSubtitle, CardText, CardFooter, Badge,//
        UncontrolledAccordion, AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';
// import service api
//
// import core class
//
// import com page
//
// import modal tool
//
// import style media
import { RxDashboard } from "react-icons/rx";
import { MdOutlineMoreHoriz, MdOutlineAdd, MdOutlineDesignServices, MdAccountBalance, MdCode, MdOutlineSendTimeExtension,//
        MdWarehouse, MdMultilineChart } from "react-icons/md";
import { VscOrganization, VscGitPullRequestCreate } from "react-icons/vsc";
import { TfiWrite } from "react-icons/tfi";
import { AiOutlineWechat } from "react-icons/ai";
import { GiGps } from "react-icons/gi";
import { FaRegCircle, FaRegCheckCircle } from "react-icons/fa";
import { LuCalendarOff } from "react-icons/lu";
import { HiOutlineDocumentReport } from "react-icons/hi";
import "./default.css";
// coding
const LeftNav = (props) => {
    return (
            /*Sidebar*/
                <Card id="sidebar-wrapper"
                    className={`bg-dark rounded-0`}
                >
                    <CardHeader className={`p-1 bg-dark logo d-flex align-items-center`}>
                        <Badge href="/" tag="a" color="transparent" className={`p-0 text-white logo-mini`}>
                            <div className={`logo-img`}>
                                <img className={`p-2 logo`} 
                                    src="./logo.png" alt="wms-logo" />
                            </div>
                        </Badge>
                        <Badge tag="a" color="transparent" className={`cursor-default text-white text-decoration-none fw-bolder fs-6 p-0 logo-normal`}>
                            Workflow Management System
                        </Badge>
                    </CardHeader>
                    <CardBody className={`nav-content work-scroller nav-color-scroller pt-1 pb-1`}>
                        <UncontrolledAccordion defaultOpen="nav-overview">
                            <AccordionItem className={'rounded-0 bg-transparent border-0'}>
                                <AccordionHeader targetId="nav-overview" className={`d-none`}>
                                    {/*Overview*/}
                                </AccordionHeader>
                                <div className={`d-flex align-items-center btn btn-dark bg-gray-800 p-0 shadow-none rounded-0 hover-opacity-7per active`}>
                                    <Button color="transparent" className={`me-auto bg-transparent w-75 text-capitalize rounded-0 text-white border-0 d-flex align-self-center shadow-none`} 
                                    onClick={() => alert('Pending Dashboard') }>
                                        <RxDashboard size="21" className={`me-2`} /> Overview
                                    </Button >
                                    <MdOutlineMoreHoriz size="29" className={`me-2 bg-transparent hover-effect-border rounded-circle p-1`} title="Morething" onClick={() => alert('Pending') }/>
                                    <MdOutlineAdd size="29" className={`me-2 bg-transparent hover-effect-border rounded-circle p-1`} title="New Workflow" onClick={() => alert('Pending') }/>
                                </div>
                                <AccordionBody accordionId="nav-overview" className={`nav-items`}>
                                    <div className={`d-flex align-items-center btn btn-dark p-0 shadow-none rounded-0 hover-opacity-7per hover-opacity-unset`}>
                                        <Button color="transparent" className={`me-auto bg-transparent w-90 text-capitalize rounded-0 text-white border-0 d-flex align-items-center shadow-none`} 
                                        onClick={() => alert('Pending') }>
                                            <MdOutlineDesignServices size="23" className={`me-2 bg-purple rounded-circle border-1 p-1`} /> Work Design
                                        </Button >
                                        <MdOutlineMoreHoriz size="29" className={`me-2 bg-transparent hover-effect-border rounded-circle p-1 opacity-0 unset-child`} title="Setting" onClick={() => alert('Pending') }/>
                                    </div>
                                    <div className={`d-flex align-items-center btn btn-dark p-0 shadow-none rounded-0 hover-opacity-7per hover-opacity-unset`}>
                                        <Button color="transparent" className={`me-auto bg-transparent w-90 text-capitalize rounded-0 text-white border-0 d-flex align-items-center shadow-none`} 
                                        onClick={() => alert('Pending') }>
                                            <MdAccountBalance size="23" className={`me-2 bg-cyan rounded-circle border-1 p-1`} /> Work Accounting
                                        </Button >
                                        <MdOutlineMoreHoriz size="29" className={`me-2 bg-transparent hover-effect-border rounded-circle p-1 opacity-0 unset-child`} title="Setting" onClick={() => alert('Pending') }/>
                                    </div>
                                    <div className={`d-flex align-items-center btn btn-dark p-0 shadow-none rounded-0 hover-opacity-7per hover-opacity-unset`}>
                                        <Button color="transparent" className={`me-auto bg-transparent w-90 text-capitalize rounded-0 text-white border-0 d-flex align-items-center shadow-none`} 
                                        onClick={() => alert('Pending') }>
                                            <MdCode size="23" className={`me-2 bg-teal rounded-circle border-1 p-1`} /> Work Program
                                        </Button >
                                        <MdOutlineMoreHoriz size="29" className={`me-2 bg-transparent hover-effect-border rounded-circle p-1 opacity-0 unset-child`} title="Setting" onClick={() => alert('Pending') }/>
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                        </UncontrolledAccordion>
                        <UncontrolledAccordion className={`mt-1`}>
                            <AccordionItem className={'rounded-0 bg-transparent border-0'}>
                                <AccordionHeader targetId="nav-extend" className={`nav-header d-flex align-items-center`}>
                                    <MdOutlineSendTimeExtension size="21" className={`me-2`} /> Extends
                                </AccordionHeader>
                                <AccordionBody accordionId="nav-extend" className={`nav-items`}>
                                    <div className={`d-flex align-items-center btn btn-dark p-0 shadow-none rounded-0 hover-opacity-7per hover-opacity-unset`}>
                                        <Button color="transparent" className={`me-auto bg-transparent w-90 text-capitalize rounded-0 text-white border-0 d-flex align-items-center shadow-none`} 
                                        onClick={() => alert('Pending') }>
                                            <VscOrganization size="23" className={`me-2 bg-indigo rounded-circle border-1 p-1`} /> ERP
                                        </Button >
                                    </div>
                                    <div className={`d-flex align-items-center btn btn-dark p-0 shadow-none rounded-0 hover-opacity-7per hover-opacity-unset`}>
                                        <Button color="transparent" className={`me-auto bg-transparent w-90 text-capitalize rounded-0 text-white border-0 d-flex align-items-center shadow-none`} 
                                        onClick={() => alert('Pending') }>
                                            <MdWarehouse size="23" className={`me-2 bg-pink rounded-circle border-1 p-1`} /> Warehouse
                                        </Button >
                                    </div>
                                    <div className={`d-flex align-items-center btn btn-dark p-0 shadow-none rounded-0 hover-opacity-7per hover-opacity-unset`}>
                                        <Button color="transparent" className={`me-auto bg-transparent w-90 text-capitalize rounded-0 text-white border-0 d-flex align-items-center shadow-none`} 
                                        onClick={() => alert('Pending') }>
                                            <VscGitPullRequestCreate size="23" className={`me-2 bg-orange rounded-circle border-1 p-1`} /> Much More
                                        </Button >
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                        </UncontrolledAccordion>
                        <UncontrolledAccordion className={`mt-1`}>
                            <AccordionItem className={'rounded-0 bg-transparent border-0'}>
                                <AccordionHeader targetId="nav-utilities" className={`nav-header d-flex align-items-center`}>
                                    <MdMultilineChart size="21" className={`me-2`} /> Utilities
                                </AccordionHeader>
                                <AccordionBody accordionId="nav-utilities" className={`nav-items`}>
                                    <div className={`d-flex align-items-center btn btn-dark p-0 shadow-none rounded-0 hover-opacity-7per hover-opacity-unset`}>
                                        <Button color="transparent" className={`me-auto bg-transparent w-90 text-capitalize rounded-0 text-white border-0 d-flex align-items-center shadow-none`} 
                                        onClick={() => alert('Pending') }>
                                            <TfiWrite size="23" className={`me-2 bg-blue rounded-circle border-1 p-1`} /> Help Write
                                        </Button >
                                    </div>
                                    <div className={`d-flex align-items-center btn btn-dark p-0 shadow-none rounded-0 hover-opacity-7per hover-opacity-unset`}>
                                        <Button color="transparent" className={`me-auto bg-transparent w-90 text-capitalize rounded-0 text-white border-0 d-flex align-items-center shadow-none`} 
                                        onClick={() => alert('Pending') }>
                                            <AiOutlineWechat size="23" className={`me-2 bg-red rounded-circle border-1 p-1`} /> Ask GPT
                                        </Button >
                                    </div>
                                    <div className={`d-flex align-items-center btn btn-dark p-0 shadow-none rounded-0 hover-opacity-7per hover-opacity-unset`}>
                                        <Button color="transparent" className={`me-auto bg-transparent w-90 text-capitalize rounded-0 text-white border-0 d-flex align-items-center shadow-none`} 
                                        onClick={() => alert('Pending') }>
                                            <GiGps size="23" className={`me-2 bg-green rounded-circle border-1 p-1`} /> Tracking
                                        </Button >
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                        </UncontrolledAccordion>
                        <UncontrolledAccordion className={`mt-1`}>
                            <AccordionItem className={'rounded-0 bg-transparent border-0'}>
                                <AccordionHeader targetId="nav-report" className={`nav-header d-flex align-items-center`}>
                                    <HiOutlineDocumentReport size="21" className={`me-2`} /> Reports
                                </AccordionHeader>
                                <AccordionBody accordionId="nav-report" className={`nav-items`}>
                                    <div className={`d-flex align-items-center btn btn-dark p-0 shadow-none rounded-0 hover-opacity-7per hover-opacity-unset`}>
                                        <Button color="transparent" className={`me-auto bg-transparent w-90 text-capitalize rounded-0 text-white border-0 d-flex align-items-center shadow-none`} 
                                        onClick={() => alert('Pending') }>
                                            <FaRegCircle size="23" className={`me-2 bg-info rounded-circle border-1 p-1`} /> All Open
                                        </Button >
                                    </div>
                                    <div className={`d-flex align-items-center btn btn-dark p-0 shadow-none rounded-0 hover-opacity-7per hover-opacity-unset`}>
                                        <Button color="transparent" className={`me-auto bg-transparent w-90 text-capitalize rounded-0 text-white border-0 d-flex align-items-center shadow-none`} 
                                        onClick={() => alert('Pending') }>
                                            <FaRegCheckCircle size="23" className={`me-2 bg-success rounded-circle border-1 p-1`} /> Completed
                                        </Button >
                                    </div>
                                    <div className={`d-flex align-items-center btn btn-dark p-0 shadow-none rounded-0 hover-opacity-7per hover-opacity-unset`}>
                                        <Button color="transparent" className={`me-auto bg-transparent w-90 text-capitalize rounded-0 text-white border-0 d-flex align-items-center shadow-none`} 
                                        onClick={() => alert('Pending') }>
                                            <LuCalendarOff size="23" className={`me-2 bg-warning rounded-circle border-1 p-1`} /> OverDue
                                        </Button >
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                        </UncontrolledAccordion>
                    </CardBody>
                    <CardFooter className={`text-white`}>
                        <CardTitle tag="h5">
                            You have run out of functions
                        </CardTitle>
                        <CardSubtitle className={`text-justify`}>
                            Get unlimited system, scheduling, automation, and much more...
                        </CardSubtitle>
                        <Button color="transparent" className={`bg-indigo text-white w-100 text-capitalize hover-opacity-7per`}>
                            Develop & Integrate, Contact Now!
                        </Button>
                    </CardFooter>
                </Card>
            /*/#sidebar-wrapper*/
     );
}
export default LeftNav;