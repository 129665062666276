// import npm packge
import React, { useState, useRef, useEffect, useCallback } from 'react';
import $ from 'jquery';
import _ from 'lodash';
import { toast } from 'react-toastify';
import * as moment from "moment";
import TextareaAutosize from 'react-autosize-textarea';
import { UncontrolledPopover, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input,//
		Card, CardHeader, CardBody, CardText, ListGroup, ListGroupItem, InputGroup, InputGroupText,//
		UncontrolledDropdown, Badge, DropdownToggle, DropdownMenu, DropdownItem, CardTitle, CardSubtitle,//
		UncontrolledAccordion, AccordionItem, AccordionHeader, AccordionBody, Dropdown, FormGroup, Label } from 'reactstrap';
import MDEditor, {  } from '@uiw/react-md-editor';
import { LightgalleryProvider, LightgalleryItem, ItemTitle, LinesEllipsis } from "react-lightgallery";
import DatePicker from "react-datepicker";
import Select, { components, StylesConfig } from 'react-select';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
// import service api
import ServiceTinfo  from '../../services/ServiceTinfo';  
import ServiceWtags  from '../../services/ServiceWtags';  
import ServiceFile  from '../../services/ServiceFile';  
import ServiceTlist  from '../../services/ServiceTlist';  
import ServiceTtime  from '../../services/ServiceTtime';  
import ServiceTfield  from '../../services/ServiceTfield';  
// import core class
import Util from '../../core/util';
import { subscribe, unsubscribe } from "../../core/events";
import { CHECK_OUT_TIME, DURATION_TOAST } from '../../core/constant';
// import com page
import { ActivityCom } from '../Widget/ActivityCom';
import { AttachmentCom } from '../Widget/AttachmentCom';
import { ChecklistsCom } from '../Widget/ChecklistsCom';
import { RightTDetail } from '../Widget/RightTDetail';
import { CustomElement } from '../Widget/CustomElement';
// import modal tool
//
// import style media
import SVG from 'react-inlinesvg';
import { FcBusinessman, FcBusinesswoman, FcLike, FcCheckmark, FcCloseUpMode } from "react-icons/fc";
import { FaRegFaceSmile, FaRegFaceSurprise } from "react-icons/fa6";
import { MdOutlineKeyboardDoubleArrowRight, MdOutlineKeyboardDoubleArrowLeft, MdOutlineMoreHoriz, MdOutlinePushPin, MdOutlineVisibility,//
		MdOutlineCloudUpload, MdOutlineAdd, MdOutlineClear, MdCheckCircleOutline, MdOutlineAddReaction, MdOutlineCalendarMonth, MdOutlinePlayCircle,//
		MdOutlineDeleteSweep, MdOutlineDone, MdOutlineClose, MdOutlineDoDisturbAlt, MdOutlineStopCircle, MdOutlineRedo, MdOutlineAutorenew, MdOutlineDoneAll
} from "react-icons/md";
import { LiaTagsSolid } from "react-icons/lia";
import { PiArchiveDuotone, PiVinylRecordBold, PiCopySimpleBold } from "react-icons/pi";
import { MdDoneAll, MdOutlineFreeCancellation, MdSettingsBackupRestore, MdRunningWithErrors } from "react-icons/md";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import chroma from 'chroma-js';
import './default.css'
// coding
const { Option } = components;
const CustomSelectOption = props => {
	const _name_str = Util.convertNameToText(props.data.fullname);
	const width = (props.width ? props.width : 27) + 'px';
	const height = (props.height ? props.height : 27) + 'px';
    const lineHeight = ((props.height ? props.height : 27) - (props.unsetborder ? 0 : 4)) + 'px';
	return (
		<Option {...props} className={`text-center d-flex align-items-center gap-1`}>
		    <div className={`hover-effect-none bg-transparent border-0 ms-1 px-0 py-0 d-flex align-self-start avatar-name`}>
		        { props.data.avatar ? (<img className={`rounded-circle bg-light border`} src={props.data.avatar} alt="avatar" />) :
		        (<p className={`rounded-circle bg-light border small display-name-profile d-flex justify-content-center align-self-center}`} style={{
                    width: width,
                    height: height,
                    lineHeight: lineHeight,
                    fontSize: ((props.width ? props.width : 27) / 2.75) + 'px'
                }}>{_name_str}</p>)
		        }
		    </div>
		    {props.data.label}
	    </Option>
	)
};
const CustomSelectValue = props => {
	const _name_str = Util.convertNameToText(props.data.fullname);
	const width = (props.width ? props.width : 27) + 'px';
	const height = (props.height ? props.height : 27) + 'px';
    const lineHeight = ((props.height ? props.height : 27) - (props.unsetborder ? 0 : 4)) + 'px';
	return props.data.uid ? (
		<div className={`d-flex align-items-center gap-1 ${props.data.is_created ? 'highlight-background' : ''}`}>
	    	<div className={`hover-effect-none bg-transparent border-0 ms-1 px-0 py-0 d-flex align-self-start avatar-name`}>
		        { props.data.avatar ? (<img className={`rounded-circle bg-light border`} src={props.data.avatar} alt="avatar" />) :
		        (<p className={`rounded-circle bg-light border small display-name-profile d-flex justify-content-center align-self-center}`} style={{
                    width: width,
                    height: height,
                    lineHeight: lineHeight,
                    fontSize: ((props.width ? props.width : 27) / 2.75) + 'px'
                }}>{_name_str}</p>)
		        }
		    </div>
	    	{props.data.label}
	    </div>
    ) : ''
};
const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.background);
        return {
            ...styles,
            backgroundColor: isDisabled ?
                undefined :
                isSelected ?
                data.background :
                isFocused ?
                color.alpha(0.1).css() :
                undefined,
            color: isDisabled ?
                '#ccc' :
                isSelected ?
                chroma.contrast(color, 'white') > 2 ?
                'white' :
                'black' :
                data.background,
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled ?
                    isSelected ?
                    data.background :
                    color.alpha(0.3).css() :
                    undefined,
            },
        };
    },
    multiValue: (styles, { data }) => {
        const color = chroma(data.background);
        return {
            ...styles,
            backgroundColor: color.alpha(0.1).css(),
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.background,
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.background,
        ':hover': {
            backgroundColor: data.background,
            color: 'white',
        },
    }),
}
const TrackingTime = ($this) => {
    // state to store time
    const [time, setTime] = useState(Util.calculateTime($this.task_time)[2]);
    // state to check stopwatch running or not
    const [isRunning, setIsRunning] = useState($this.isRunning);
    useEffect(() => {
        let intervalId;
        if (isRunning) {
            // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
            intervalId = setInterval(() => setTime(time + 1), 1000);
        }
        return () => clearInterval(intervalId);
    }, [isRunning, time]);
    // Hours calculation
    const hours = Math.floor(time / 3600);
    // Minutes calculation
    const minutes = Math.floor((time % 3600) / 60);
    // Seconds calculation
    const seconds = Math.floor(time % 60);
    // Method to start and stop timer
    const startAndStop = () => {
        if (!isRunning) {
        	setIsRunning(!isRunning);
        	ServiceTtime.instance.checkinTime($this.task_id, {start_time: moment().format("YYYY-MM-DD HH:mm:ss"), check_out_time: CHECK_OUT_TIME}).then(({ data }) => {
	            if (data.status) {
        			//
	            } else {
	            	Util.toast(data.error, 'error');
	            }
	        })
        } else {
        	ServiceTtime.instance.checkoutTime($this.task_id, {end_time: moment().format("YYYY-MM-DD HH:mm:ss")}).then(({ data }) => {
	            if (data.status) {
        			if ($this.module == 'task') {
        				setIsRunning(!isRunning);
        			}
	            } else {
	            	Util.toast(data.error, 'error');
	            }
	        })
        }
    };
    return (
    	<div className={`btn btn-sm d-flex align-self-center px-1 py-0 gap-1 cursor-pointer rounded-0 shadow-none ${isRunning ? 'btn-danger outline-danger text-white' : 'btn-default outline-default'}`}
            title={$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.tracktime : 'Track Time'}
            style={{height: `var(--pix-h-31)`}}
        >
            {!isRunning && (<MdOutlinePlayCircle size="27" className={`btn btn-transparent btn-sm outline-transparent rounded-circle p-1`} onClick={() => startAndStop()} />)}
            {isRunning && (<MdOutlineStopCircle size="27" className={`btn btn-transparent btn-sm outline-transparent rounded-circle text-white p-1`} onClick={() => startAndStop()} />)}
        	<span className={`d-flex align-self-center small`}
        		style={{lineHeight: 0}}		
        	>
        	{hours.toString().padStart(2, "0")}:{minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}</span>
        </div>
    );
};
function TDetail($this) {
	const [modal, setModal] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [Tinfo, setTinfo] = useState({});
	const [activeStage, setActiveStage] = useState({});
	const [prevStage, setPrevStage] = useState({});
	const [nextStage, setNextStage] = useState({});
	const [taskResult, setTaskResult] = useState({});
    const [selectedOption, setSelectedOption] = useState(null);
	const [members, setMembers] = useState([]);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [watches, setWatches] = useState([]);
	const [onWatches, setOnWatches] = useState([]);
	const [tags, setTaggs] = useState([]);
	const [onTags, setOnTags] = useState([]);
	const myRefs = useRef([]);
	const [taskTypes, setTaskTypes] = useState([]);
	const [activeType, setActiveType] = useState({});
	const [accessAddTlist, setAccessAddTlist] = useState(false);
	const [listObjPagings, setListObjPagings] = useState([]);
	const [continueMore, setContinueMore] = useState(false);
	const [isConfirm, setCorfirm] = useState(false);
	const [taskWorklogs, setTaskWorklogs] = useState([]);
	const [initSubscribeEvent, setInitSubscribeEvent] = useState(false);
    const [activeTabLog, setActiveTabLog] = useState('showall');
    const [loadedTasklog, setLoadedTasklog] = useState(false);
    const [onUpdateTask, setOnUpdateTask] = useState(false);
	const [sampleEditorContent, setSampleEditorContent] = useState(() =>
    	EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft('').contentBlocks))
    );
	//
	useEffect(() => {
        if (Tinfo && Tinfo.result == 'pending' && !$this.wms_class.stopChangeResultInprocess) {
        	updateTask('result', 'inprocess');
        }
        if (Tinfo && Tinfo.result) {
        	$this.wms_class.handleSharePaste = handlePaste;
        }
    }, [Tinfo]);
    useEffect(() => {
        if ($('.modal').hasClass('show') && initSubscribeEvent === true && modal && Tinfo) {
	       	setInitSubscribeEvent(false);
	       	let elm_id = $('.modal > div.modal-dialog > div.modal-content').attr('id');
	       	if (!elm_id){
	       		elm_id = moment().unix();
	       		$('.modal > div.modal-dialog > div.modal-content').attr('id', elm_id);
	       	}
	       	$this.wms_class.onTinfoDetail = elm_id;
	       	$this.wms_class.handleRealTaskDetail(elm_id, function($arg){
	       		subscribe("showTinfo", (e) => {
	       			let elm_id = $('.modal > div.modal-dialog > div.modal-content').attr('id');
	       			if ($this.wms_class.onTinfoDetail && elm_id == $arg && $this.wms_class.onTinfoDetail == $arg){
			        	overWriteTinfoRealtime(e.detail);
		       		}
		        });
	       });
        } 
        return () => {
            unsubscribe("showTinfo", (e) => console.log(e));
        }
    }, [initSubscribeEvent, modal]);
    useEffect(() => {
        $this.wms_class.taskWorklogs = Object.assign([], taskWorklogs);
    }, [taskWorklogs]);
	$this.header_self.toggleMTD = (uid) => {
    	setActiveTabLog((prev) => { return 'showall'; });
    	setAccessAddTlist(true);
    	setTinfo(prevTinfo => ({...{}, ...{}}));
    	setSelectedOption(null);
		setStartDate(null);
		setTaskWorklogs(prevTaskWorklogs => ([...[], ...[]]));
		setLoadedTasklog(false);
		setOnUpdateTask(false);
        if (!modal) {
    		setDataSelectDropdown(uid, true);
        } else {
        	setInitSubscribeEvent(false);
        }
        setModal(!modal);
        $this.wms_class.handleSharePaste = function(event){
			//
		};
    };
    const toggleWatches = () => {
    	if (!dropdownOpen) {
        	let _watches = _.filter($this.wms_class.comStage.state.lstMembers, function(o) {return o.uid != Tinfo.assign;});
        	_watches = _.map(_watches, function(w){
        		w.is_created = Tinfo.created_by == w.uid;
        		return w;
        	});
			setWatches(prevwatches => ([...[], ..._watches]));
			let _watch_ids = _.map(Tinfo.watches, 'uid');
			let _onWatches = _.filter($this.wms_class.comStage.state.lstMembers, function(o) {return o.uid != Tinfo.assign && _watch_ids.indexOf(o.uid) > -1;});
			setOnWatches(prevwatches => ([...[], ..._onWatches]));
        }
        setDropdownOpen(!dropdownOpen)
    }
    let setDataSelectDropdown = (uid, initTdetail = false) => {
    	let _reset = [];
    	showTinfo(uid, function(data){
        	setMembers($this.wms_class.comStage.state.lstMembers);
			let _assign = _.find($this.wms_class.comStage.state.lstMembers, {uid: data.assign});
			setSelectedOption(_assign ? _assign : null);
			let _watches = _.filter($this.wms_class.comStage.state.lstMembers, function(o) {return o.uid != data.assign;});
			setWatches(prevwatches => ([...[], ..._watches]));
			let _watch_ids = _.map(data.watches, 'uid');
			let _onWatches = _.filter($this.wms_class.comStage.state.lstMembers, function(o) {return o.uid != data.assign && _watch_ids.indexOf(o.uid) > -1;});
			setOnWatches(prevwatches => ([...[], ..._onWatches]));
			let _activeTags = _.filter($this.wms_class.comStage.state.lstTags, function(o){ return o.status == 'active' && o.type != 'selecttags'});
			setTaggs(prevtags => ([...[], ..._activeTags]));
			let _tag_ids = _.map(data.tags, 'id');
			let _onTags = _.filter($this.wms_class.comStage.state.lstTags, function(o) {return _tag_ids.indexOf(o.id) > -1;});
			setOnTags(prevtags => ([...[], ..._onTags]));
            let _taskTypes = _.filter($this.wms_class.comStage.state.lstConstants, function(o) {return o.mode == 'type';});
            setTaskTypes(prev => ([...[], ..._taskTypes]));
            let _activeType = _.find(_taskTypes, {identifier: data.type});
			setActiveType(_activeType);
			setStartDate(data.duedate ? new Date(data.duedate) : new Date(moment().add(15, 'minutes')));
			if (initTdetail) {
				setInitSubscribeEvent(true);
				initAccordion(data);
			}
        });
    }
    let initAccordion = (tinfo) => {
    	setTimeout(async function(){
            if(tinfo && tinfo.mode == "link"){
                await $('#accordion-right-task-detail .accordion-item:nth-child(1) .accordion-header').children('button').trigger('click');
            }
            else {
                await $('#accordion-right-task-detail .accordion-item:nth-child(2) .accordion-header').children('button').trigger('click');
            }
        }, 100)
    }
    let showTinfo = (uid, $callback) => {
    	ServiceTinfo.instance.showTinfo(uid)
        .then(async ({ data }) => {
        	let _listObjPagings = Object.assign([], listObjPagings);
        	let	objPaging = {task_id: uid, type: activeTabLog, rows: 25, offset: 0, total_rows: 0};
        	setSampleEditorContent(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(data.described).contentBlocks)));
        	pagingTlogs(uid, [], objPaging.type, objPaging.rows, objPaging.offset, function($status, $data){
        		setTaskWorklogs(prevTaskWorklogs => ([...[], ...$data]));
        		data.cp_custom_fields = data.custom_fields;
        		data.cp_checklists = data.checklists;
        		let onTracking = Util.calculateTime(data.tracking);
	            data.isRunning = onTracking[0];
        		data.tasktime = onTracking[1];
        		setTinfo(prevTinfo => ({...{}, ...data}));
				let activeStages = _.filter($this.wms_class.comStage.state.objData.columns, {status: 'active'});
				let _activeStage = _.find($this.wms_class.comStage.state.objData.columns, {step: data.step});
				setActiveStage(_activeStage);
				let active_index = _.findIndex(activeStages, {step: data.step});
				setPrevStage(active_index > 0 ? activeStages[active_index - 1] : {});
				setNextStage(active_index < activeStages.length - 1 ? activeStages[active_index + 1] : {});
				let _taskResult = _.find($this.wms_class.comStage.state.lstConstants, {identifier: data.result});
				setTaskResult(_taskResult);
				return $callback(data);
        	})
        })
    }
	let handleColor = (time) => {
		return time.getHours() > 12 ? "text-success" : "text-error";
	};
	let changeStage = (stage) => {
		let comStage = $this.wms_class.comStage;
	    let _stages = comStage.state.objData.columns.map(item => ({...item}));
		let objTask = {step: stage.step, index: 1};
		$this.wms_class.updateTinfo(Tinfo.uid, objTask, function(res){
			if (!res.status) {
				Util.toast(res.error, 'error');
				return;
			}
			Util.toast(res.success, 'success');
            let __tinfo = Object.assign({}, Tinfo);
            showTinfo(__tinfo.uid, function(data){
				let _taskResult = _.find($this.wms_class.comStage.state.lstConstants, {identifier: data.result});
				setTaskResult(_taskResult);
	        	data.attachments = __tinfo.attachments;
				setTinfo(prevTinfo => ({...{}, ...data}));
				if (data.assign != __tinfo.assign) {
					let _assign = _.find($this.wms_class.comStage.state.lstMembers, {uid: data.assign});
					setSelectedOption(_assign ? _assign : null);
				}	
	        });
	    });
	}
	let updateTask = async(key, value) => {
		let comStage = $this.wms_class.comStage;
		let dataObj = {};
		dataObj[key] = value;
		if (key == 'result' && value == 'failure') {
			dataObj['status'] = 'delete';
		}
		await setOnUpdateTask(true);
		$this.wms_class.updateTinfo(Tinfo.uid, dataObj, async function(arg){ 
			if (!arg.status) {
				Util.toast(arg.error, 'error');
				if (key == 'result' && Tinfo[key] == 'pending' && dataObj[key] == 'inprocess') {
					$this.wms_class.stopChangeResultInprocess = true;
				}
				await setOnUpdateTask(false);
				return setDataSelectDropdown(Tinfo.uid);
			}
			Util.toast(arg.success, 'success');
			if (key == 'status' && value != comStage.state.objData.filter.status || dataObj['status'] && dataObj['status'] != comStage.state.objData.filter.status) {
			    return $this.header_self.toggleMTD(Tinfo.uid);
	    	}
		    let __tinfo = Object.assign({}, Tinfo);
			showTinfo(__tinfo.uid, async function(data){
				let _taskResult = _.find($this.wms_class.comStage.state.lstConstants, {identifier: data.result});
				setTaskResult(_taskResult);
	        	data.attachments = __tinfo.attachments;
				setTinfo(prevTinfo => ({...{}, ...data}));
				await setOnUpdateTask(false);
	        });
	    });
	}
	let onSelectedOption = async(member) => {
		await setSelectedOption(null);
		let _assign = _.find($this.wms_class.comStage.state.lstMembers, {uid: member ? member.uid : null});
		setSelectedOption(_assign ? _assign : null);
        updateTask('assign', member ? member.uid : -1);
    }
    let duplicateTask = () => {
    	setModal(!modal);
    	toggleConfirm();
    }
    let deleteTask = () => {
    	ServiceTinfo.instance.deleteTinfo(Tinfo.uid).then(({ data }) => {
            if (data.status) {
			    $this.header_self.toggleMTD(Tinfo.uid);
			    Util.toast(data.success, 'success');
            } else {
            	Util.toast(data.error, 'error');
            }
        })
    }
    let onSelectedWatch = async(members) => {
    	if (members.length < Tinfo.watches.length) {
    		let diff = _.differenceBy(Tinfo.watches, members, 'uid');
    		await setOnWatches([]);
    		let _onWatches = _.filter(members, function(o) {return o.uid != Tinfo.assign;});
			setOnWatches(prevwatches => ([...[], ..._onWatches]));
	        ServiceTinfo.instance.removeTmber({workflow_id: $this.wms_class.comStage.state.objData.workfolows.id, task_id: Tinfo.uid, watch_id: diff[0].uid}).then(({ data }) => {
	            if (data.status) {
	            	setDataSelectDropdown(Tinfo.uid);
	            } else {
	            	toggleWatches();
	            	Util.toast(data.error, 'error');
	            }
	        })
    	} else {
    		let diff = _.differenceBy(members, Tinfo.watches, 'uid');
    		await setOnWatches([]);
    		let _onWatches = _.filter(members, function(o) {return o.uid != Tinfo.assign;});
			setOnWatches(prevwatches => ([...[], ..._onWatches]));
	        ServiceTinfo.instance.assignTmber({workflow_id: $this.wms_class.comStage.state.objData.workfolows.id, task_id: Tinfo.uid, watch_id: diff[0].uid}).then(({ data }) => {
	            if (data.status) {
	            	setDataSelectDropdown(Tinfo.uid);
	            } else {
	            	toggleWatches();
	            	Util.toast(data.error, 'error');
	            }
	        })
    	}
    }
    let onSelectedTag = async (tags) => {
    	if (tags.length < Tinfo.tags.length) {
    		let diff = _.differenceBy(Tinfo.tags, tags, 'id');
    		await setOnTags([]);
			setOnTags(prevtags => ([...[], ...tags]));
	        ServiceWtags.instance.removeTtags({workflow_id: $this.wms_class.comStage.state.objData.workfolows.id, task_id: Tinfo.uid, tags: diff[0].id}).then(({ data }) => {
	            setDataSelectDropdown(Tinfo.uid);
	        })
    	} else {
    		let diff = _.differenceBy(tags, Tinfo.tags, 'id');
    		await setOnTags([]);
			setOnTags(prevtags => ([...[], ...tags]));
	        ServiceWtags.instance.assignTtags({workflow_id: $this.wms_class.comStage.state.objData.workfolows.id, task_id: Tinfo.uid, tags: diff[0].id}).then(({ data }) => {
	            setDataSelectDropdown(Tinfo.uid);
	        })
    	}
    }
    let outFocusTinfo = (event) => {
        event.preventDefault();
    	const {name, value} = event.target;
        updateTask(name, value);
    }
    let outFocusDescribed = (event, editorState) => {
    	event.preventDefault();
        let _content = draftToHtml(convertToRaw(editorState.getCurrentContent())).replace(/\n$/,"");
		if (_content != Tinfo.described) {
        	updateTask('described', _content);
		}
    }
    let handlePaste = (event) => {
    	if (event.clipboardData.files.length > 0 && Tinfo && Tinfo.result) {
			let wms_class = $this.wms_class;
			if (!wms_class.onsubmit) {
	            Util.toast($this.wms_class.comStage.state.Trans.toast.isLoading, 'warning');
	            return;
	        }
	        wms_class.onsubmit = false;
			const toast_loading = Util.toast(wms_class.comStage.state.Trans.toast.textLoading, 'loading');
	    	uploadTfile(event.clipboardData.files, function(){
	    		document.getElementById('file-upload-attachment').value = null;
	            Util.toast(wms_class.comStage.state.Trans.toast.textLoaded, 'update', toast_loading);
	            overWriteTinfo(function() {
	    			wms_class.onsubmit = true;
	    		}, Tinfo, 'yes', 'yes');
	    	}, event);
		}
    }
    let handleAttachFile = (event) => {
    	let files = event.target.files;
    	let wms_class = $this.wms_class;
    	if (!wms_class.onsubmit) {
            Util.toast($this.wms_class.comStage.state.Trans.toast.isLoading, 'warning');
            return;
        }
        wms_class.onsubmit = false;
        const toast_loading = Util.toast(wms_class.comStage.state.Trans.toast.textLoading, 'loading');
    	uploadTfile(files, function(){
    		document.getElementById('file-upload-attachment').value = null;
            Util.toast(wms_class.comStage.state.Trans.toast.textLoaded, 'update', toast_loading);
            overWriteTinfo(function() {
    			wms_class.onsubmit = true;
    		}, Tinfo, 'yes', 'yes');
    	}, event);
    }
    let uploadTfile = (files, $callback, event) => {
    	if (files.length == 0) {
    		return;
    	}
    	let file = files[0];
    	if (!file.type.startsWith('image/')) {
            return beginUploadFile(file, files, $callback, event);;
        }
        var reader = new FileReader();
        reader.onload = (e) => {
            var img = document.createElement("img");
            img.onload = () => {
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                var MAX_WIDTH = 900;
                var MAX_HEIGHT = 600;
                var width = img.width;
                var height = img.height;
                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                var dataurl = canvas.toDataURL("image/png");
                return beginUploadFile(Util.dataURLtoFile(dataurl, file.name), files, $callback, event);
            }
            img.src = e.target.result;
        }
        reader.readAsDataURL(file);
    }
    let beginUploadFile = (_file, files, $callback, event) => {
    	ServiceFile.instance.uploadTfile(Tinfo.uid, [_file]).then(({ data }) => {
	        if (data.status) {
	        	let _tinfo = Object.assign({}, Tinfo);
	        	_tinfo.attachments.unshift(data.file);
	        	setTinfo(prev => ({...{}, ..._tinfo}));
	        	let _tempFiles = Object.assign([], files);
	        	_tempFiles.splice(0, 1);
	        	if (_tempFiles.length > 0) {
	        		return uploadTfile(_tempFiles, $callback, event);
	        	} else {
				    return $callback();
	        	}
	        } else {
	        	Util.toast(data.error, 'error');
	        	return $callback();
	        }
	    })
    }
    let savePathFile = (pathfile, $callback) => {
    	ServiceFile.instance.savePathFile(Tinfo.uid, {pathfile:pathfile}).then(({ data }) => {
        	if (typeof $callback == 'function') {
		    	$callback(data.status);
		    }
	        if (data.status) {
	        	let _tinfo = {...Tinfo};
	        	_tinfo.attachments.unshift(data.file);
	        	setTinfo(prevTinfo => ({...{}, ..._tinfo}));
	        } else {
	        	Util.toast(data.error, 'error');
	        }
	    })
    }
    let onDeleteAttach = (tfile_id) => {
    	ServiceFile.instance.deleteTfile(tfile_id).then(({ data }) => {
    		if (data.status) {
    			let _tinfo = {...Tinfo};
    			let _index = _.findIndex(_tinfo.attachments, {id: tfile_id});
	        	_tinfo.attachments.splice(_index, 1);
	        	setTinfo(prevTinfo => ({...{}, ..._tinfo}));
	        	Util.toast(data.success, 'success');
	        } else {
	        	Util.toast(data.error, 'error');
	        }
	    })
    }
    let addChecklist = () => {
    	let _tinfo = {...Tinfo};
    	_tinfo.checklists.push({task_id: Tinfo.uid, mode: 'single', wstage: activeStage.uid, bgrid: activeStage.bgrid, content: ''});
    	setTinfo(prevTinfo => ({...{}, ..._tinfo}));
    	waitingFocus(_tinfo.checklists.length - 1);
    	setAccessAddTlist(false);
    }
    let waitingFocus = (index) => {
    	if (myRefs.current[index]) {
    		return myRefs.current[index].focus();
    	} else {
    		setTimeout(async function(){
	    		return waitingFocus(index);
	    	}, 200);
    	}
    }
    let outFocusChecklist = (value, i) => {
    	let checklists = Tinfo.checklists.map(item => ({...item}));
    	let checklist = {...checklists[i]};
    	checklist.content = value;
    	if (checklist.id) {
	    	let cp_checklists = Tinfo.cp_checklists.map(item => ({...item}));
	    	let cp_checklist = Object.assign({}, cp_checklists[i]);
	    	if (checklist.content == cp_checklist.content) {
	    		return;
	    	}
    		updateTlist(checklist, i);
    	} else {
    		let _content = checklist.content.replace(/(<([^>]+)>)/ig, "");
    		if (_content.length == 0) {
    			return;
    		}
    		let wms_class = $this.wms_class;
	        wms_class.onsubmit = false;
	        wms_class.trackInprocess();
    		let __tinfo = Object.assign({}, Tinfo);
    		ServiceTlist.instance.storeTlist(checklist).then(async ({ data }) => {
	    		if (data.status) {
	        		Util.toast(data.success, 'success');
	        		let _tinfo = Object.assign({}, Tinfo);
        			__tinfo.checklists[i] = data.checklist;
        			_tinfo.checklists = [];
        			await setTinfo(prevTinfo => ({...{}, ..._tinfo}));
        			await setTinfo(prevTinfo => ({...{}, ...__tinfo}));
		        } else {
		        	Util.toast(data.error, 'error');
		        }
    			overWriteTinfo(function() {
	    			wms_class.onsubmit = true;
	            	wms_class.trackInprocess();
	            	setAccessAddTlist(true);
	    		}, __tinfo, 'yes', 'yes');
		    })
    	}	
    }
    let updateTlist = (checklist, i) => {
        let wms_class = $this.wms_class;
        wms_class.prevent_continuity = true;
        wms_class.onsubmit = false;
        wms_class.trackInprocess();
    	delete checklist.index;
    	let _tinfo_ = Object.assign({}, Tinfo);
    	Tinfo.checklists[i].result = checklist.result;
    	let __tinfo = Object.assign({}, Tinfo);
    	setTinfo(prevTinfo => ({...{}, ...__tinfo}));
    	ServiceTlist.instance.updateTlist(checklist.id, checklist).then(async ({ data }) => {
    		if (data.status) {
        		Util.toast(data.success, 'success');
        		let _tinfo = Object.assign({}, Tinfo);
    			__tinfo.checklists[i] = data.checklist;
    			_tinfo.checklists = [];
    			await setTinfo(prevTinfo => ({...{}, ..._tinfo}));
    			await setTinfo(prevTinfo => ({...{}, ...__tinfo}));
    			overWriteTinfo(function() {
					wms_class.prevent_continuity = false;
	    			wms_class.onsubmit = true;
	            	wms_class.trackInprocess();
	            	setAccessAddTlist(true);
	    		}, __tinfo, 'yes', 'yes');
	        } else {
	        	Util.toast(data.error, 'error');
	        	overWriteTinfo(function() {
					wms_class.prevent_continuity = false;
	    			wms_class.onsubmit = true;
	            	wms_class.trackInprocess();
	            	setAccessAddTlist(true);
	    		});
	        }
	    })
    }
    let overWriteTinfo = ($callback, holddata, ishold_file = 'no', ishold_checklist = 'no') => {
		ServiceTinfo.instance.showTinfo(Tinfo.uid)
        .then(({ data }) => {
	    	pagingTlogs(Tinfo.uid, [], activeTabLog, 25, 0, function($status, $data){
	    		let _tinfo = Object.assign({}, Tinfo);
	    		setTaskWorklogs(prevTaskWorklogs => ([...[], ...$data]));
	        	_tinfo.result = data.result;
	        	_tinfo.attachments = ishold_file == 'yes' ? holddata.attachments : data.attachments;
	        	_tinfo.checklists = ishold_checklist == 'yes' ? holddata.checklists : data.checklists;
				_tinfo.inprocess_checklists = _.filter(_tinfo.checklists, {result: 'inprocess'}).length;
				_tinfo.complete_checklists = _.filter(_tinfo.checklists, {result: 'complete'}).length;
				_tinfo.review_checklists = _.filter(_tinfo.checklists, function(o) { return o.result == 'review' || o.result == 'complete'; }).length;
				_tinfo.fail_checklists = _.filter(_tinfo.checklists, {result: 'failure'}).length;
				let _taskResult = _.find($this.wms_class.comStage.state.lstConstants, {identifier: data.result});
				setTaskResult(_taskResult);
				setTinfo(prev => ({...{}, ..._tinfo}));
				return $callback();
	    	})
        })
    }
    let loadMoreTlogs = () => {
    	let _listObjPagings = Object.assign([], listObjPagings);
    	let objPaging = _.find(_listObjPagings, {task_id: Tinfo.uid});
    	if (!objPaging) {
    		objPaging = {task_id: Tinfo.uid, rows: 10, offset: 0, total_rows: 0};
    		_listObjPagings.push(objPaging);
    		setListObjPagings(prev => ([...[], ..._listObjPagings]));
    	}
    	if (objPaging.offset >= objPaging.total_rows) {
    		setContinueMore(false);
    		return;
    	} else {
    		setContinueMore(true);
    	}
    	pagingTlogs(objPaging.task_id, taskWorklogs, objPaging.type, objPaging.rows, objPaging.offset, function($status, $data){
    		setTaskWorklogs(prevTaskWorklogs => ([...[], ...$data]));
    	})
    }
    let pagingTlogs = ($task_id, prev_tlogs = [], $type = 'showall', $rows = 10, $offset = 0, $callback) => {
    	let objPaging = {
			type: $type,
			rows: $rows,
			offset: $offset,
			task_id: $task_id,
		};
		ServiceTinfo.instance.pagingTlogs(objPaging).then(({ data }) => {
			setLoadedTasklog(true);
            if (data.status) {
                let tlogs = data.data;
                let new_tlogs = _.uniqBy(_.concat(prev_tlogs, tlogs), 'id');
                let _listObjPagings = Object.assign([], listObjPagings);
    			let _findIndex = _.findIndex(_listObjPagings, {task_id: $task_id});
    			let _objPaging;
    			if (_findIndex >= 0) {
    				_listObjPagings[_findIndex].total_rows = data.total_rows;
    				_listObjPagings[_findIndex].offset = new_tlogs.length;
    				_objPaging = _listObjPagings[_findIndex];
    				setListObjPagings(prev => ([...[], ..._listObjPagings]));
    			} else {
    				_objPaging = {task_id: $task_id, type: $type, rows: $rows, offset: new_tlogs.length, total_rows: data.total_rows};
    				_listObjPagings.push(_objPaging);
    				setListObjPagings(prev => ([...[], ..._listObjPagings]));
    			}
    			if (_objPaging.offset >= _objPaging.total_rows) {
    				setContinueMore(false);
    			} else {
		    		setContinueMore(true);
		    	}
                return $callback(data.status, new_tlogs);
            } else {
            	return $callback(data.status, []);
            }
        })
    }
    let handleCheck = (event, checklist, i) => {
    	checklist.result = event.target.checked ? 'review' : 'inprocess';
    	updateTlist(checklist, i);
    }
    let completeTlist = (id, i) => {
    	let wms_class = $this.wms_class;
        wms_class.prevent_continuity = true;
        wms_class.onsubmit = false;
        wms_class.trackInprocess();
    	let checklists = Tinfo.checklists.map(item => ({...item}));
		checklists[i].result = 'complete';
    	Tinfo.checklists[i].result = checklists[i].result;
    	setTinfo(prevTinfo => ({...{}, ...Tinfo}));
    	ServiceTlist.instance.completeTlist(id).then(({ data }) => {
    		if (data.status) {
        		Util.toast(data.success, 'success');
	        } else {
	        	Util.toast(data.error, 'error');
	        }
	        overWriteTinfo(function() {
	        	wms_class.prevent_continuity = false;
    			wms_class.onsubmit = true;
            	wms_class.trackInprocess();
    		}, Tinfo, 'yes', 'yes');
	    })
    }
    let rejectTlist = (id, i) => {
    	let wms_class = $this.wms_class;
        wms_class.prevent_continuity = true;
        wms_class.onsubmit = false;
        wms_class.trackInprocess();
    	let checklists = Tinfo.checklists.map(item => ({...item}));
		checklists[i].result = 'failure';
    	Tinfo.checklists[i].result = checklists[i].result;
    	setTinfo(prevTinfo => ({...{}, ...Tinfo}));
    	ServiceTlist.instance.rejectTlist(id).then(({ data }) => {
    		if (data.status) {
        		checklists.splice(i+1, 0, data.checklist);
        		Util.toast(data.success, 'success');
	        } else {
	        	Util.toast(data.error, 'error');
	        }
	        overWriteTinfo(function() {
	        	wms_class.prevent_continuity = false;
    			wms_class.onsubmit = true;
            	wms_class.trackInprocess();
    		}, Tinfo, 'yes');
	    })
    }
    let deleteTlist = async(id, i) => {
		let _tinfo = Object.assign({}, Tinfo);
		let ___tinfo = Object.assign({}, Tinfo);
    	if (!id) {
			_tinfo.checklists.splice(i, 1);
			_tinfo.inprocess_checklists = _.filter(_tinfo.checklists, {result: 'inprocess'}).length;
			_tinfo.complete_checklists = _.filter(_tinfo.checklists, {result: 'complete'}).length;
			_tinfo.review_checklists = _.filter(_tinfo.checklists, function(o) { return o.result == 'review' || o.result == 'complete'; }).length;
			_tinfo.fail_checklists = _.filter(_tinfo.checklists, {result: 'failure'}).length;
			await setTinfo(prevTinfo => ({...{}, ..._tinfo}));
			setAccessAddTlist(true);
    	} else {
    		let wms_class = $this.wms_class;
	        wms_class.prevent_continuity = true;
	        wms_class.onsubmit = false;
	        wms_class.trackInprocess();
	        ___tinfo.checklists = []
	        await setTinfo(prevTinfo => ({...{}, ...___tinfo}));
	        let _index = _.findIndex(_tinfo.checklists, {id: id});
			_tinfo.checklists.splice(_index, 1);
			_tinfo.inprocess_checklists = _.filter(_tinfo.checklists, {result: 'inprocess'}).length;
			_tinfo.complete_checklists = _.filter(_tinfo.checklists, {result: 'complete'}).length;
			_tinfo.review_checklists = _.filter(_tinfo.checklists, function(o) { return o.result == 'review' || o.result == 'complete'; }).length;
			_tinfo.fail_checklists = _.filter(_tinfo.checklists, {result: 'failure'}).length;
			await setTinfo(prevTinfo => ({...{}, ..._tinfo}));
    		ServiceTlist.instance.deleteTlist(id).then(({ data }) => {
	    		if (data.status) {
	    			Util.toast(data.success, 'success');
		        } else {
		        	Util.toast(data.error, 'error');
		        }
		        overWriteTinfo(function() {
		        	wms_class.prevent_continuity = false;
	    			wms_class.onsubmit = true;
	            	wms_class.trackInprocess();
	    		}, _tinfo, 'yes', 'yes');
		    })
    	}	
    }
    let redoTlist = (checklist, i) => {
    	checklist.result = 'inprocess';
    	updateTlist(checklist, i);
    }
    let renewTlist = (id, i) => {
    	let wms_class = $this.wms_class;
        wms_class.prevent_continuity = true;
        wms_class.onsubmit = false;
        wms_class.trackInprocess();
    	let checklists = Tinfo.checklists.map(item => ({...item}));
    	ServiceTlist.instance.renewTlist(id).then(({ data }) => {
    		if (data.status) {
        		Util.toast(data.success, 'success');
	        } else {
	        	Util.toast(data.error, 'error');
	        }
	        overWriteTinfo(function() {
	        	wms_class.prevent_continuity = false;
    			wms_class.onsubmit = true;
            	wms_class.trackInprocess();
    		}, Tinfo, 'yes');
	    })
    }
    let approvalAll = () => {
    	let wms_class = $this.wms_class;
        if (!wms_class.onsubmit) {
            Util.toast($this.wms_class.comStage.state.Trans.toast.isLoading, 'warning');
            return;
        }
        wms_class.onsubmit = false;
        wms_class.trackInprocess();
    	let checklists = Tinfo.checklists.map(item => ({...item}));
    	ServiceTlist.instance.completeAllTlist(Tinfo.uid).then(({ data }) => {
    		if (data.status) {
        		if (data.next_stage) {
        			let comStage = $this.wms_class.comStage;
	    			let _stages = comStage.state.objData.columns.map(item => ({...item}));
        			let _finish = _.find(_stages, {step: data.next_stage.step});
        			if (_finish) {
        				changeStage(_finish);
        			}
        		}
        		Util.toast(data.success, 'success');
	        } else {
	        	Util.toast(data.error, 'error');
	        }
	        wms_class.onsubmit = true;
            wms_class.trackInprocess();
	    })
    }
    let changeType = (type) => {
    	setActiveType(prev => ({...{}, ...type}));
    	updateTask('type', type.identifier);
    }
    let setDueDate = (date) => {
    	setStartDate(date);
    	updateTask('duedate', date ? moment(date).format("YYYY-MM-DD HH:mm:ss"): date);
    }
    let onChangeTfield = (event, i) => {
    	event.preventDefault();
    	const {value} = event.target;
    	let custom_fields = Tinfo.custom_fields.map(item => ({...item}));
    	custom_fields[i].value = custom_fields[i].element == 'selecttags' ? value.toUpperCase() : value;
    	Tinfo.custom_fields = custom_fields;
		setTinfo(prevTinfo => ({...{}, ...Tinfo}));
    }
    let outFocusTfield = (event, i) => {
        event.preventDefault();
    	const {value} = event.target;
    	let custom_fields = Tinfo.custom_fields.map(item => ({...item}));
    	let custom_field = {...custom_fields[i]};
    	custom_field.value = value;
    	let cp_custom_fields = Tinfo.cp_custom_fields.map(item => ({...item}));
    	let cp_custom_field = {...cp_custom_fields[i]};
    	if (custom_field.value == cp_custom_field.value) {
    		return;
    	}
		ServiceTfield.instance.updateTfield(custom_field.id, custom_field).then(({ data }) => {
    		if (data.status) {
        		Tinfo.custom_fields = custom_fields;
        		Tinfo.cp_custom_field = custom_fields;
        		setTinfo(prevTinfo => ({...{}, ...Tinfo}));
        		Util.toast(data.success, 'success');
	        } else {
        		Tinfo.custom_fields = cp_custom_fields;
        		setTinfo(prevTinfo => ({...{}, ...Tinfo}));
	        	Util.toast(data.error, 'error');
	        }
	    })	
    }
    let setValueTagCustomField = (value, i) => {
    	let custom_fields = Tinfo.custom_fields.map(item => ({...item}));
    	let cp_custom_fields = [...custom_fields];
    	custom_fields[i].value = value;
    	Tinfo.custom_fields = custom_fields;
		setTinfo(prevTinfo => ({...{}, ...Tinfo}));
		ServiceTfield.instance.updateTfield(custom_fields[i].id, custom_fields[i]).then(({ data }) => {
    		if (data.status) {
        		Tinfo.custom_fields = custom_fields;
        		Tinfo.cp_custom_field = custom_fields;
        		setTinfo(prevTinfo => ({...{}, ...Tinfo}));
        		Util.toast(data.success, 'success');
	        } else {
        		Tinfo.custom_fields = cp_custom_fields;
        		setTinfo(prevTinfo => ({...{}, ...Tinfo}));
	        	Util.toast(data.error, 'error');
	        }
	    })
    }
    let changeTabLogs = async(tab) => {
		await setLoadedTasklog(false);
		await setActiveTabLog((prev) => { return tab; });
    	let _tinfo = {...Tinfo};
    	let _listObjPagings = Object.assign([], listObjPagings);
		let _findIndex = _.findIndex(_listObjPagings, {task_id: _tinfo.uid});
		let _objPaging;
		if (_findIndex >= 0) {
			_listObjPagings[_findIndex].type = tab;
			setListObjPagings(prev => ([...[], ..._listObjPagings]));
		} else {
			_objPaging = {task_id: _tinfo.uid, type: tab, rows: 25, offset: 0, total_rows: 0};
			_listObjPagings.push(_objPaging);
			setListObjPagings(prev => ([...[], ..._listObjPagings]));
		}
    	pagingTlogs(Tinfo.uid, [], tab, 25, 0, async function($status, $data){
			setTaskWorklogs(prevTaskWorklogs => ([...[], ...$data]));
        })
    }
    let overWriteTinfoRealtime = async(notify) => {
    	let _tinfo = notify.tinfo;
    	let __tinfo = Object.assign({}, Tinfo);
    	if (_tinfo.uid != __tinfo.uid) {
    		return;
    	}
    	let type_update = notify.type_update;
    	let haslog = notify.haslog;
    	let comStage = $this.wms_class.comStage;
		let headers = Util.getHeaders();
		if (Array.isArray(haslog) && haslog.length > 0 && (haslog[0]['group'] == activeTabLog || activeTabLog == 'showall')) {
    		let task_log = $this.wms_class.taskWorklogs;
    		let _task_log = [];
    		if (type_update == 'update_tinfo_comment_update' || type_update == 'update_tinfo_comment_delete') {
    			_task_log = Object.assign([], task_log);
    			let __findIndex = _.findIndex(_task_log, {id: haslog[0].id});
    			if (__findIndex > -1) {
    				if (type_update == 'update_tinfo_comment_update') {
    					_task_log[__findIndex].content = haslog[0].content;
    					_task_log[__findIndex].is_update = haslog[0].is_update;
    				} else {
    					_task_log.splice(__findIndex, 1);
    				}
    			}
    		} else {
    			_task_log = _.orderBy(_.uniqBy(_.concat(haslog, task_log), 'id'), ['id'], ['desc']);
    		}
    		let _listObjPagings = Object.assign([], listObjPagings);
			let _findIndex = _.findIndex(_listObjPagings, {task_id: __tinfo.uid});
			let _objPaging;
			if (_findIndex >= 0) {
				if (type_update != 'update_tinfo_comment_update' && type_update != 'update_tinfo_comment_delete') {
					_listObjPagings[_findIndex].total_rows += parseInt(haslog.length);
					_listObjPagings[_findIndex].offset += parseInt(haslog.length);
				} else if (type_update == 'update_tinfo_comment_delete') {
					_listObjPagings[_findIndex].total_rows -= parseInt(haslog.length);
					_listObjPagings[_findIndex].offset -= parseInt(haslog.length);
				}
				setListObjPagings(prev => ([...[], ..._listObjPagings]));
			} else {
				_objPaging = {task_id: __tinfo.uid, type: activeTabLog, rows: 25, offset: parseInt(haslog.length), total_rows: parseInt(haslog.length)};
				_listObjPagings.push(_objPaging);
				setListObjPagings(prev => ([...[], ..._listObjPagings]));
			}
			if (type_update == 'update_tinfo_comment_delete' && $this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.objData && notify.token === headers.headers.Authorization) {
    			// tạm tắt realtime delete comment tại nơi click action delete => xử lý trên action click
			} else {
				await setTaskWorklogs(prevTaskWorklogs => ([...[], ..._task_log]));
			}
    	}
    	if ($this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.objData && notify.token === headers.headers.Authorization) {
    		if (type_update === 'update_tinfo_task_time') {
		    	let onTracking = Util.calculateTime(_tinfo.tracking);
	    		__tinfo.isRunning = onTracking[0];
	    		__tinfo.tasktime = onTracking[1];
	    		await setTinfo(prevTinfo => ({...{}, ...__tinfo}));
    		}
    		return;
    	}
		let _taskTypes = _.filter($this.wms_class.comStage.state.lstConstants, function(o) {return o.mode == 'type';});
		let _activeType = _.find(_taskTypes, {identifier: _tinfo.type});
		let _taskResult = _.find($this.wms_class.comStage.state.lstConstants, {identifier: _tinfo.result});
		let activeStages = _.filter($this.wms_class.comStage.state.objData.columns, {status: 'active'});
		let _activeStage = _.find(activeStages, {step: _tinfo.step});
    	switch(type_update){
	    	case 'update_tinfo_described':
	    		__tinfo.described = _tinfo.described;
	    		setSampleEditorContent(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(__tinfo.described).contentBlocks)));
	    		break;
	    	case 'update_tinfo_status':
	    		__tinfo.status = _tinfo.status;
	    		if (__tinfo.status != comStage.state.objData.filter.status) {
				    return $this.header_self.toggleMTD(__tinfo.uid);
		    	}
	    		break;
	    	case 'update_tinfo_duedate':
	    		__tinfo.duedate = _tinfo.duedate;
	    		break;
	    	case 'update_tinfo_type':
	    		//
				break;
	    	case 'update_tinfo_assign':
	    		//
	    		break;
	    	case 'update_tinfo_result':
	    		//
	    		break;
	    	case 'update_tinfo_tags':
	    		__tinfo.tags = _tinfo.tags;
	    		let _tag_ids = _.map(_tinfo.tags, 'id');
				let _onTags = _.filter($this.wms_class.comStage.state.lstTags, function(o) {return _tag_ids.indexOf(o.id) > -1;});
				setOnTags(prevtags => ([...[], ..._onTags]));
	    		break;
	    	case 'update_tinfo_watchs':
	    		__tinfo.watches = _tinfo.watches;
	    		let _watch_ids = _.map(__tinfo.watches, 'uid');
				let _onWatches = _.filter($this.wms_class.comStage.state.lstMembers, function(o) {return o.uid != __tinfo.assign && _watch_ids.indexOf(o.uid) > -1;});
				setOnWatches(prevwatches => ([...[], ..._onWatches]));
	    		break;
	    	case 'update_tinfo_custom_fields':
	    		__tinfo.custom_fields = _tinfo.custom_fields;
	    		__tinfo.wflow_fields = _tinfo.wflow_fields;
	    		break;
	    	case 'update_tinfo_attachments':
	    		__tinfo.attachments = _tinfo.attachments;
	    		break;
    		case 'update_tinfo_step':
	    	case 'update_tinfo_checklists':
	    		__tinfo.checklists = _tinfo.checklists;
	    		__tinfo.cp_checklists = _tinfo.checklists;
	    		__tinfo.inprocess_checklists = _.filter(__tinfo.checklists, {result: 'inprocess'}).length;
				__tinfo.complete_checklists = _.filter(__tinfo.checklists, {result: 'complete'}).length;
				__tinfo.review_checklists = _.filter(__tinfo.checklists, function(o) { return o.result == 'review' || o.result == 'complete'; }).length;
				__tinfo.fail_checklists = _.filter(__tinfo.checklists, {result: 'failure'}).length;
	    		break;
	    	case 'update_tinfo_task_time':
	    		let onTracking = Util.calculateTime(_tinfo.tracking);
	    		__tinfo.isRunning = onTracking[0];
	    		__tinfo.tasktime = onTracking[1];
	    		break;
			default:
	    		break;
    	}
		if (__tinfo.name != _tinfo.name) {
			__tinfo.name = _tinfo.name;
		}
		if (__tinfo.content != _tinfo.content) {
			__tinfo.content = _tinfo.content;
		}
		if (__tinfo.step != _tinfo.step) {
			__tinfo.step = _tinfo.step;
			setActiveStage(_activeStage);
			let active_index = _.findIndex(activeStages, {step: _tinfo.step});
			setPrevStage(active_index > 0 ? activeStages[active_index - 1] : {});
			setNextStage(active_index < activeStages.length - 1 ? activeStages[active_index + 1] : {});
		}
		if (__tinfo.result != _tinfo.result) {
			__tinfo.result = _tinfo.result;
			setTaskResult(_taskResult);
		}
		if (__tinfo.type != _tinfo.type) {
			__tinfo.type = _tinfo.type;
			setActiveType(_activeType);
		}
		if (__tinfo.assign != _tinfo.assign) {
			__tinfo.assign = _tinfo.assign;
    		let _assign = _.find($this.wms_class.comStage.state.lstMembers, {uid: __tinfo.assign});
			setSelectedOption(_assign ? _assign : null);
		}
    	if (type_update != '') {
    		await setTinfo(prevTinfo => ({...{}, ...__tinfo}));
    	}
    }
    let toggleConfirm = () => {
        setCorfirm(!isConfirm);
    };
    let cloneTinfo = (clone = false) => {
        ServiceTinfo.instance.cloneTinfo(Tinfo.uid, {clone:clone}).then(({ data }) => {
            if (data.status) {
			    setCorfirm(!isConfirm);
			    Util.toast(data.success, 'success');
            } else {
            	Util.toast(data.error, 'error');
            }
        })
    };
  	return (
		[
		<Modal className={`my-0 h-100 display-task-1224`} key={`detail-tinfo`} isOpen={modal} toggle={$this.react.toggleMTD} {...$this} size={`xl`} scrollable={true}
			>
		    <ModalHeader toggle={$this.react.toggleMTD}
		    	className={`modal-maintask`}
		    	tag="div">
                {prevStage && prevStage.step && (<Button
					color="outline-primary"
					className={`py-0 none-960`}
					title={$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.prev_stage : 'Prev Stage'}
					onClick={() => changeStage(prevStage)}
				>
					<MdOutlineKeyboardDoubleArrowLeft size="30" className={`p-1`} />
				</Button>)}
				{nextStage && nextStage.step && (<Button
					color="outline-primary"
					className={`py-0 none-960`}
					title={$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.next_stage : 'Next Stage'}
					onClick={() => changeStage(nextStage)}
				>
					<MdOutlineKeyboardDoubleArrowRight size="30" className={`p-1`} />
				</Button>)}
				{$this.wms_class && $this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.objData && activeStage && (
				<UncontrolledDropdown>
				    <DropdownToggle color="skip" caret className={`btn d-flex align-items-center text-white h-100 none-480`}
				    style={{backgroundColor: activeStage.background}} >
				        {activeStage.name}
				    </DropdownToggle>
				    <DropdownMenu end flip className={`border-0 py-0 mt-2`}>
				    	{$this.wms_class.comStage.state.objData.columns && $this.wms_class.comStage.state.objData.columns.map((stage, i) => { 
                            return stage.step != activeStage.step ? <DropdownItem key={i} className={`d-flex align-items-center hover-opacity-5per text-white`}
                            style={{backgroundColor: stage.background}} onClick={() => changeStage(stage)}>
                            	{stage.name}
                        	</DropdownItem> : ''
                        }
                        )}
				    </DropdownMenu >
				</UncontrolledDropdown>)}
				{Tinfo && (Tinfo.result == 'inprocess' || Tinfo.result == 'review') && (
					<Button
						color="skip"
						className={`py-0 text-white none-768`}
						onClick={() => updateTask('result', 'complete')}
						style={{backgroundColor: Util.indexColorbyId($this.wms_class.comStage.state.lstColors, taskResult.bgrid)}}
					>
						{$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.complete : 'Complete'}
					</Button>)}
				{Tinfo && Tinfo.status == 'active' && (Tinfo.result == 'complete' || Tinfo.result == 'review' || Tinfo.result == 'cancel') && (
					<Button
						color="skip"
						className={`py-0 text-white none-768`}
						onClick={() => updateTask('status', 'deactive')}
						style={{backgroundColor: Util.indexColorbyId($this.wms_class.comStage.state.lstColors, taskResult.bgrid)}}
					>
						{$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.archive : 'Archive'}
					</Button>)}
				<div
                    className={`bg-light hover-effect-border text-muted px-0 me-auto`}
                    title={$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.assign : 'Assign Employee'} style={{minWidth: 'var(--pix-w-240)'}}
                >
                	<Select
                		value={selectedOption}
	                	options={members}
	                	components={{
					        Option: CustomSelectOption,
					        SingleValue: CustomSelectValue
					    }}
					    isClearable={selectedOption ? true : false}
					    onChange={onSelectedOption}
					    className={`cuson-selects-value`}
				    />
                </div>
                {Tinfo && Tinfo.tags && (
                <UncontrolledDropdown>
			    	<DropdownToggle color="light" className={`d-flex align-items-center hover-effect-none bg-transparent border-0 px-0`}>
			    		<div className={`d-flex align-self-start text-muted`} title={$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.tags : 'Tags List'}>
		                    <LiaTagsSolid size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1`}/>
		                    <h6 style={{fontSize: 'var(--fs-11)'}}>{Util.renderNumberPlus(Tinfo.tags.length)}</h6>
                		</div>
	                </DropdownToggle>
	                <DropdownMenu end flip className={`border-0 py-0`}>
		                <DropdownItem className={`d-flex align-items-center hover-effect-border p-0`} toggle={false}>
				            <div className={`bg-light hover-effect-border text-muted px-0`} style={{minWidth: 'var(--pix-w-240)'}}>
			                	<Select
			                		isMulti
			                		value={onTags}
				                	options={tags}
								    onChange={onSelectedTag}
								    isClearable={false}
								    styles={colourStyles}
								    className={`cuson-tags-value`}
							    />
			                </div>
				        </DropdownItem>
				    </DropdownMenu >
	            </UncontrolledDropdown>
                )}
				{Tinfo && Tinfo.watches && (
				<Dropdown isOpen={dropdownOpen} toggle={toggleWatches}>
			    	<DropdownToggle color="light" className={`d-flex align-items-center hover-effect-none bg-transparent border-0 px-0`}>
					    <div className={`d-flex align-self-start text-muted`} title={$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.watches : 'Watches List'}>
		                    <MdOutlineVisibility size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1`}/>
		                    <h6 style={{fontSize: 'var(--fs-11)'}}>{Util.renderNumberPlus(Tinfo.watches.length)}</h6>
	                	</div>
	                </DropdownToggle>
	                <DropdownMenu end flip className={`border-0 py-0`}>
		                <DropdownItem className={`d-flex align-items-center hover-effect-border p-0`} toggle={false}>
				            <div className={`bg-light hover-effect-border text-muted px-0`} style={{minWidth: 'var(--pix-w-320)'}}>
			                	<Select
			                		isMulti
			                		value={onWatches}
				                	options={watches}
				                	components={{
								        Option: CustomSelectOption,
								        MultiValueLabel: CustomSelectValue
								    }}
								    onChange={onSelectedWatch}
								    isClearable={false}
								    className={`cuson-watches-value`}
							    />
			                </div>
				        </DropdownItem>
				    </DropdownMenu >
	            </Dropdown>
            	)}
            	<div className="d-flex align-items-center hover-effect-none bg-transparent border-0 btn btn-light p-0">
            		<MdOutlinePushPin size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1`} title={$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.pintop : 'Pin Top'} onClick={() => updateTask('index', 1)}/>
            	</div>				
				<UncontrolledDropdown>
				    <DropdownToggle color="light" className={`d-flex align-items-center hover-effect-none bg-transparent border-0 px-0`}>
				        <MdOutlineMoreHoriz size="30" className={`bg-light hover-effect-border rounded-circle text-muted p-1`} title={$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.more : 'More...'}/>
				    </DropdownToggle>
				    {Tinfo && (Tinfo.status != 'expired' || $this.wms_class.comStage.state.objData.profiles.role == 'Owner') && (
			    	<DropdownMenu end flip className={`border-0`}>
				        <DropdownItem header>
				            {$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.manage : 'Manage Job'}
				        </DropdownItem >
				        {Tinfo && Tinfo.mode == 'core' && Tinfo.status == 'active' && (
			        	<DropdownItem className={`d-flex align-items-center hover-effect-border`} onClick={() => duplicateTask()}>
				            <PiCopySimpleBold className={`me-1`} /> {$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.duplicate : 'Duplicate'}
				        </DropdownItem>
				        )}
				        {Tinfo && Tinfo.status == 'active' && (
			        	<DropdownItem className={`d-flex align-items-center hover-effect-border`} onClick={() => updateTask('status', 'deactive')}>
				            <PiArchiveDuotone className={`me-1`} /> {$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.archive : 'Archive'}
				        </DropdownItem>)}
				        {Tinfo && Tinfo.status != 'active' && (
			        	<DropdownItem className={`d-flex align-items-center hover-effect-border`} onClick={() => updateTask('status', 'active')}>
				            <MdSettingsBackupRestore className={`me-1`} /> {$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.restore : 'Restore'}
				        </DropdownItem>)}
				        {Tinfo && (Tinfo.status == 'active' || Tinfo.status == 'deactive') && (
			        	<DropdownItem divider>
		        		</DropdownItem>)}
				        {Tinfo && Tinfo.status == 'active' && Tinfo.result != 'complete' && Tinfo.result != 'failure' && (
			        	<DropdownItem className={`d-flex align-items-center hover-effect-border`} onClick={() => updateTask('result', 'complete')}>
				            <MdDoneAll className={`me-1`} /> {$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.complete : 'Complete'}
				        </DropdownItem>)}
				        {Tinfo && Tinfo.status == 'active' && Tinfo.result != 'complete' && Tinfo.result != 'failure' && (
			        	<DropdownItem className={`d-flex align-items-center hover-effect-border`} onClick={() => updateTask('result', 'failure')}>
				            <MdRunningWithErrors className={`me-1`} /> {$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.failure : 'Failure'}
				        </DropdownItem>)}
				        {Tinfo && Tinfo.status == 'active' && Tinfo.result != 'complete' && Tinfo.result != 'failure' && Tinfo.result != 'cancel' && (
			        	<DropdownItem className={`d-flex align-items-center hover-effect-border`} onClick={() => updateTask('result', 'cancel')}>
				            <MdOutlineFreeCancellation className={`me-1`} /> {$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.cancel : 'Cancel'}
				        </DropdownItem>)}
				        {Tinfo && Tinfo.status == 'active' && (Tinfo.result == 'complete' || Tinfo.result == 'cancel') && (
			        	<DropdownItem className={`d-flex align-items-center hover-effect-border`} onClick={() => updateTask('result', 'inprocess')}>
				            <PiVinylRecordBold className={`me-1`} /> {$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.redo : 'Redo'}
				        </DropdownItem>)}
				        {Tinfo && ((Tinfo.status == 'active' && Tinfo.result != 'complete' && Tinfo.result != 'failure') || (Tinfo.status != 'deactive' && (Tinfo.result == 'complete' || Tinfo.result == 'failure' || Tinfo.result == 'cancel'))) && (
			        	<DropdownItem divider>
				        </DropdownItem>)}
				        {Tinfo && Tinfo.status != 'expired' && (
			        	<DropdownItem className={`text-danger text-center hover-effect-border`} onClick={() => deleteTask()}>
				            {$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.delete : 'Delete Task'}
				        </DropdownItem>)}
				    </DropdownMenu>)}
				</UncontrolledDropdown>
		    </ModalHeader>
		    <ModalBody id={"body-task-detail-" + Tinfo.uid}
		    	className={`modal-maintask work-scroller d-flex gap-3 mb-0`}>
			    <div
                    className={`maintask-left h-100`}
                    style={{width: 'var(--per-wi-66)'}}
                >
                	<Input
                		name="name"
                		style={{cursor: 'inherit'}}
                		className={`border-0 hover-effect-border fs-5 py-0 bg-light`}
                		defaultValue={Tinfo.name}
                        onBlur={(event) => outFocusTinfo(event)}
                		placeholder="Title & Name"
                		disabled={Tinfo.mode == 'link'}
  					/>
  					<Input
  						name="content"
                		style={{cursor: 'inherit'}}
                		className={`border-0 hover-effect-border fs-6 mt-1 py-0 bg-light text-black-50`}
                		defaultValue={Tinfo.content}
                		onBlur={(event) => outFocusTinfo(event)}
                		placeholder="Content"
                		disabled={Tinfo.mode == 'link'}
  					/>
  					<div className={`form-control border-1 hover-effect mt-1 text-muted bg-white cursor-text`}>
  						<Editor
						  	toolbarHidden
						  	wrapperClassName="wrapper-class p-0"
						  	editorClassName="editor-class described"
						  	toolbarClassName="toolbar-class"
						  	wrapperStyle={{}}
						  	editorStyle={{ minHeight: '85px', overflow: 'hidden'}}
						  	editorState={sampleEditorContent}
						  	onEditorStateChange={setSampleEditorContent}
						  	readOnly={onUpdateTask}
						  	onBlur={(event, editorState) => outFocusDescribed(event, editorState)}
						/>
  					</div>
  					{Tinfo.custom_fields && Tinfo.custom_fields.map((field, i) => {
                        return field.on_main == 'active' && (field.onaccess == 'active' || field.onaccess == 'deactive' && $this.wms_class.comStage.state.objData.profiles.accessfield == 'active') ? (
                        	<FormGroup key={i} className={`d-flex mt-3 justify-content-between align-items-center`}>
	                            <Label className={`w-25 mb-0 fw-bold`}>
	                                {field.name}:
	                            </Label>
	                            <CustomElement element={field.element} index={i} className={`hover-effect-border py-0 text-black-50`} name={'value'} value={field.value} onChange={onChangeTfield} onBlur={outFocusTfield} lstTags={$this.wms_class.comStage.state.lstTags} setValueTagCustomField={setValueTagCustomField}></CustomElement>
	                        </FormGroup>) : ''
                        }
                    )}
  					{/*attachment-task*/}
  					<AttachmentCom wms_class={$this.wms_class} setTinfo={setTinfo} Tinfo={Tinfo} handleAttachFile={handleAttachFile} onDeleteAttach={onDeleteAttach} savePathFile={savePathFile}/>
					{/*checklist-task*/}
  					<ChecklistsCom wms_class={$this.wms_class} Tinfo={Tinfo} addChecklist={addChecklist} approvalAll={approvalAll} handleCheck={handleCheck}
  						outFocusChecklist={outFocusChecklist} myRefs={myRefs} completeTlist={completeTlist} 
  						rejectTlist={rejectTlist} deleteTlist={deleteTlist} redoTlist={redoTlist} renewTlist={renewTlist} accessAddTlist={accessAddTlist}
  						updateTlist={updateTlist}
					/>
                	{/*conversation-task*/}
                	<ActivityCom wms_class={$this.wms_class} Tinfo={Tinfo} taskWorklogs={taskWorklogs} setTaskWorklogs={setTaskWorklogs} changeTabLogs={changeTabLogs} activeTabLog={activeTabLog} loadMoreTlogs={loadMoreTlogs} continueMore={continueMore} loadedTasklog={loadedTasklog}/>
                </div>
                <RightTDetail wms_class={$this.wms_class} Tinfo={Tinfo} taskTypes={taskTypes} activeType={activeType} changeType={changeType}
                	startDate={startDate} setDueDate={setDueDate} handleColor={handleColor} onChangeTfield={onChangeTfield} outFocusTfield={outFocusTfield}
                	setValueTagCustomField={setValueTagCustomField}
                />
		    </ModalBody>
		</Modal>,
		<Modal key={`confirm-tinfo`} isOpen={isConfirm} toggle={toggleConfirm} size={`lg`} backdrop={'static'} keyboard={false}>
        	<ModalHeader>
        		{$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.confirm : 'Confirm'}
    		</ModalHeader>
	        <ModalBody>
	          	{$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.warning_clone_task : 'Do you want to keep your created checklists and uploaded files?'}
	        </ModalBody>
	        <ModalFooter>
	          	<Button color="primary" onClick={() => cloneTinfo(true)}>
	            	{$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.confirm_yes : 'Yes'}
	          	</Button>{' '}
	          	<Button color="secondary" onClick={() => cloneTinfo(false)}>
	            	{$this.wms_class.comStage && $this.wms_class.comStage.state && $this.wms_class.comStage.state.Trans ? $this.wms_class.comStage.state.Trans.task.confirm_no : 'No'}
	          	</Button>
	        </ModalFooter>
      	</Modal>
		]
  );
};
export {colourStyles, CustomSelectOption, CustomSelectValue, TDetail, TrackingTime};